import { Component } from "@angular/core";
import { LoginService } from "../../../service/login.service";
import { UserService } from "../../../service/user.service";
import { MatDialog } from "@angular/material/dialog";
import { QuoteRequiredData } from "../../../models/quote.required.data";
import { DataLoader } from "src/app/service/data.loader";
import { PricingComponent } from "../../shared/pricing/pricing.component";
import { QmLicense } from "src/app/models/qm.license.models/qm.license";
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: "research-subscription",
  templateUrl: "../../shared/pricing/pricing.component.html",
  styleUrls: ["../../shared/pricing/pricing.component.scss"],
})
export class ResearchSubscriptionComponent extends PricingComponent {
  quoteRequiredData: QuoteRequiredData;
  userTopLicense: QmLicense;

  constructor(
    public userService: UserService,
    public loginService: LoginService,
    public sharedFunction: SharedFunctionService,
    public dialog: MatDialog,
    private dataLoader: DataLoader
  ) {
    super(userService, loginService, sharedFunction);
  }

  ngOnInit(): void {

    this.loginService.checkLoginStatus();
    this.quoteRequiredData = this.dataLoader.getRequiredData();
    this.availableQmLicenses = [];

    this.isLogin = this.loginService.isLogin(); // always check login status

    this.isPayMonthly = true; // default to display monthly fee    

    this.userTopLicense = new QmLicense();
    this.userTopLicense.Price = 0.00;
    this.userTopLicense.QmLicenseCode = "FREE";

    
    this.getAvailableLicenses();

  }


  
  getAvailableLicenses() {
    this.showDinoLoading();

    this.userService.getAvailableLicenses((response) => {
      if (response) {
        this.availableQmLicenses = response;
        // set PA license price
        this.getPaLicencePrice(response)
      }
      this.closeDinoLoading();
    });
  }    



  displayMyCurrentPlanBtn(qmLicenseCode: string): boolean {
    let paymentFrequency = this.isPayMonthly ? 12 : 1;
    let display : boolean = false;
    for(var license of this.availableQmLicenses){
      if(license.PaymentFrequency == paymentFrequency 
        && license.QmLicenseCode == qmLicenseCode
        && license.IsCurrentPlan == true){
          display = true;
          break;
      }
    }
    return display;
  }

  displayFreeTrialBtn(qmLicenseCode: string): boolean {
    let display : boolean = false;
    for(var license of this.availableQmLicenses){
      if(license.QmLicenseCode == qmLicenseCode && license.IsAbleToBuy == true){
          display = true;
          break;
      }
    }
    return display;
  }

  displayBuyNowBtn(qmLicenseCode: string): boolean {
    let display : boolean = false;
    let paymentFrequency = this.isPayMonthly ? 12 : 1;
    for(var license of this.availableQmLicenses){
      if(license.QmLicenseCode == qmLicenseCode 
        && license.PaymentFrequency == paymentFrequency 
        && license.IsAbleToBuy == true){
          display = true;
          break;
      }
    }
    return display;
  }

  displayInFreeTrial(qmLicenseCode: string): boolean {
    let display : boolean = false;
    for(var license of this.availableQmLicenses){
      if(license.QmLicenseCode == qmLicenseCode && license.IsInTrial == true){
          display = true;
          break;
      }
    }
    return display;
  }
}
