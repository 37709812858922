import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { KiwiMonsterClient } from 'src/app/models/kiwimonster/kiwimonster-client.model';
import { KiwiMonsterFund } from 'src/app/models/kiwimonster/kiwimonster-fund.model';
import { KiwiMonsterKiwiSaverService } from 'src/app/service/kiwimonster/kiwimonster-kiwisaver.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SysConfigService } from 'src/app/service/sys.config';

@Component({
  selector: 'kiwimonster-funds-dialog',
  templateUrl: './kiwimonster-funds-dialog.component.html',
  styleUrls: ['./kiwimonster-funds-dialog.component.scss']
})
export class KiwiMonsterFundsDialogComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['SchemeName', 'PastYearReturnNet', 'AverageFiveYearReturnNet', 'RiskRewardIndicatorCode', 'QmRiskProfileCode', 'FundTotalValue', 'NumberOfInvestors', 'QmCalcFeeInPercentage','FundStartDate'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource: MatTableDataSource<KiwiMonsterFund>;

  selectedFunds: KiwiMonsterFund[]=[];
  selectedFundType: string = '';
  isLoadingData: boolean = false;
  errorMessage: string = '';
  exceedsThreeFunds: boolean = false;
  
  currentFund: KiwiMonsterFund | null;
  fundToOpen: boolean = true;
  textFilterValue: string = '';
  constructor
    (
      public sharedFunction: SharedFunctionService,
      public dialogRef: MatDialogRef<KiwiMonsterFundsDialogComponent>,
      public sysConfig: SysConfigService,
      private kiwiMonsterKiwiSaverService: KiwiMonsterKiwiSaverService,
      public loginService:LoginService,
      @Inject(MAT_DIALOG_DATA) public data: { Funds: KiwiMonsterFund[], Client: KiwiMonsterClient; },
    ) { }

  ngOnInit(): void {
    this.currentFund = this.data.Client.Setting.CurrentSchemeFund;
    this.selectedFundType = this.data.Funds[0].QmRiskProfileCode;
    this.selectedFunds = this.data.Client.Setting.QmKSProspectiveProductFunds;
    if (this.selectedFunds.length === 3) {
      this.exceedsThreeFunds = true;
    }
    this.setInitSelectedFunds(this.selectedFunds, this.data.Funds);
    this.dataSource = new MatTableDataSource(this.data.Funds);
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    setTimeout(() => {
      this.kiwiMonsterKiwiSaverService.bindTableData(this.dataSource, this.paginator,this.sort,this.fundToOpen);
      this.fundToOpenFilter();
    });
  }

  closeSave(): void {
    this.loginService.doGoogleTracking("kiwiMonsterQuote-kiwisaver-selectProspectiveFundsDialog", "closeAndSave", "");
    this.dialogRef.close(this.selectedFunds);
  }
  
  toggleSelectedFund(fund: KiwiMonsterFund): void {
    if (fund.IsSelectedAsProspective) {
      this.selectedFunds.push(fund);
      if (this.selectedFunds.length === 3) {
        this.exceedsThreeFunds = true;
      }
    } else {
      this.exceedsThreeFunds = false;
      this.selectedFunds = this.selectedFunds.filter(f => f.Id !== fund.Id);
    }
  }

  textFilter(filterValue: string) {
    this.textFilterValue = filterValue;
    this.dataSource.filter = this.kiwiMonsterKiwiSaverService.getFilter(filterValue, this.fundToOpen);
  }
  
  fundToOpenFilter(): void {
    this.updateDisplayedColumns();
    this.dataSource.filter = this.kiwiMonsterKiwiSaverService.getFilter(this.textFilterValue, this.fundToOpen);
  }
  
  onFundTypeChange(): void {
    this.errorMessage = '';
    this.isLoadingData = true;
    this.kiwiMonsterKiwiSaverService.getFundsByFundTypeObservable(this.selectedFundType).subscribe((response) => {
      if (response && response.length > 0) {
        let filteredFunds = this.kiwiMonsterKiwiSaverService.removeCurrentFundFromData(this.data.Client.Setting.QmKSProductFundId, response);
        this.data.Funds = filteredFunds;
        this.setInitSelectedFunds(this.selectedFunds, this.data.Funds);
        this.dataSource = new MatTableDataSource(this.data.Funds);
        setTimeout(() => {
          this.kiwiMonsterKiwiSaverService.bindTableData(this.dataSource, this.paginator, this.sort, this.fundToOpen);
          this.textFilter(this.textFilterValue);
          this.fundToOpenFilter();
        });
      } else {
        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
      }
      this.isLoadingData = false;
    });
  }

  setInitSelectedFunds(selectedFunds: KiwiMonsterFund[], searchFunds: KiwiMonsterFund[]): void {
    if (selectedFunds.length > 0) {
      searchFunds.forEach(fund => {
        fund.IsSelectedAsProspective = selectedFunds.some(selectedFund => selectedFund.Id === fund.Id);
      });
    }
  }
  
  unselectedFund(fund: KiwiMonsterFund): void {
    this.exceedsThreeFunds = false;
    fund.IsSelectedAsProspective = false;
    this.selectedFunds = this.selectedFunds.filter(f => f.Id !== fund.Id);
    let dataFund = this.data.Funds.find(f => f.Id === fund.Id);
    if (dataFund) {
      dataFund.IsSelectedAsProspective = false;
    }
    this.loginService.doGoogleTracking("kiwiMonsterQuote-kiwisaver-selectProspectiveFundsDialog", "deleteSelectedFund", "");
  }
  
  updateDisplayedColumns(): void {
    this.displayedColumns = this.kiwiMonsterKiwiSaverService.getDisplayedColumns(this.fundToOpen);
  }
  
  updateFundType(fund: KiwiMonsterFund): string {
    return KiwiMonsterFund.updateFundType(fund);
  }
}



