import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HeadToHeadResultItem } from '../../models/head2head.models/head2head.result.item';
import { PolicyWordingItem, PolicyWordingListItem } from '../../models/qpr.models/policy.wording';
import { SysConfigService } from '../../service/sys.config';
import { QuoteStepCompareH2H } from '../../service/quote/quote.step.compare.h2h';
import { QuoteReportDialogComponent } from './quote-report-dialog.component';
import * as Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import { ResearchPolicyWordingV2Dialog } from '../research/policy/research-policy-wording-v2.dialog';
import { Provider } from 'src/app/models/provider';
import { ConfirmMessageDialogService } from '../shared/confirm-message-dialog.service';
import { QprCompany } from 'src/app/models/qpr.company';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
// Initialize exporting module.
Exporting(Highcharts);




@Component({
    // tslint:disable-next-line:component-selector
    selector: 'quote-compare-h2h',
    templateUrl: './quote-compare-h2h.component.html',
    styleUrls: ['./quote-compare-h2h.component.scss']
})
export class QuoteCompareHeadToHeadComponent implements OnInit {
    showPremiumTable: boolean = false;
    companyOptions: QprCompany[] = [];

    constructor(
        public quoteStepCompareH2H: QuoteStepCompareH2H,
        public dialog: MatDialog,
        public sysConfig: SysConfigService,
        public confirmDialog: ConfirmMessageDialogService,
        public sharedFunction: SharedFunctionService
    ) {

    }

    ngOnInit() {
        this.quoteStepCompareH2H.init(() => {
            this.companyOptions = this.quoteStepCompareH2H.qprRequiredData.Companies;
        });
    }


    clearResult() {
        this.quoteStepCompareH2H.hasResult = false;
    }

    doCompare() {
        this.showPremiumTable = false;
        this.quoteStepCompareH2H.compare(this.sysConfig, this.show10YearPremiumEstimateChart);
    }


    show10YearPremiumEstimateChart(
        company1PremiumList: Provider[], company2PremiumList: Provider[],
        quoteStepCompareH2H: QuoteStepCompareH2H) {
        // build data
        // check if result data all 0 then remove this provider
        let providerList = [];
        let yearList = [];
        let thisYear = new Date().getFullYear();
        let company1ValueList = [];
        let company2ValueList = [];
        let provider1HasValue = false;
        let provider2HasValue = false;
        let yearCounter = 0;

        for (let value of company1PremiumList) {
            if (value.TotalPremium > 0) {
                provider1HasValue = true;
            }
        }

        for (let value of company2PremiumList) {
            if (value.TotalPremium > 0) {
                provider2HasValue = true;
            }
        }

        yearCounter = Math.max(company1PremiumList.length, company2PremiumList.length);

        // remove unavailable data
        for (let v: number = 0; v < (yearCounter + 1); v++) {
            yearList.push('Year ' + (thisYear + v));
        }


        for (let v of company1PremiumList) {
            if (v.TotalPremium > 0) {
                company1ValueList.push(v.TotalPremium);
            }
        }


        for (let v of company2PremiumList) {
            if (v.TotalPremium > 0) {
                company2ValueList.push(v.TotalPremium);
            }
        }


        if (provider1HasValue === true) {
            providerList.push({
                name: quoteStepCompareH2H.selectedCompany1.Name,
                data: company1ValueList
            });
        }

        if (provider2HasValue === true) {
            providerList.push({
                name: quoteStepCompareH2H.selectedCompany2.Name,
                data: company2ValueList
            });
        }

        Highcharts.chart('10yearPremiumEstimateChart', {
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            chart: {
                type: 'line',
                events: {
                    render: function (chart) {

                        let target: any = chart.target;

                        let s: string = target.getSVG(
                            {
                                chart: {
                                    width: 1100,
                                    events: {
                                        render: function () {

                                        }
                                    }
                                },
                                xAxis: {
                                    labels: {
                                        useHTML: false,
                                        formatter: function () {
                                            return '';
                                        }
                                    }
                                }
                            }
                        );

                        quoteStepCompareH2H.currentQuote.ExtValue3 = s;
                        quoteStepCompareH2H.saveCurrentQuote(quoteStepCompareH2H.currentQuote);
                        quoteStepCompareH2H.showDownloadBtn = true;
                    }
                },

            },
            title: {
                text: '10 years premium estimate'
            },
            subtitle: {
                text: 'Source: Quotemonster'
            },
            xAxis: {
                categories: yearList,
                min: 0
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Premium ($)'
                }
            },
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true
                    },
                    enableMouseTracking: false
                }
            },
            series: providerList
        });
    }
    
    showQprItemDetail(value: HeadToHeadResultItem) {

        let policyWordingListItem = new PolicyWordingListItem();

        // for company 1
        let policyWordingItem1: PolicyWordingItem = new PolicyWordingItem();
        // policyWordingItem1.BenefitCode = value.BenefitCode;
        policyWordingItem1.DefaultCompanyCodeList = [];
        policyWordingItem1.DefaultCompanyCodeList.push(this.quoteStepCompareH2H.selectedCompany1.CompanyCode);
        policyWordingItem1.ProductCode = this.quoteStepCompareH2H.headToHeadResult.LeftQprCode;
        policyWordingItem1.ProductCodeExt = this.quoteStepCompareH2H.headToHeadResult.LeftQprCodeExt;
        policyWordingItem1.QprItem = value.ItemName;

        policyWordingListItem.List.push(policyWordingItem1);



        // for company 2
        let policyWordingItem2: PolicyWordingItem = new PolicyWordingItem();
        // policyWordingItem2.BenefitCode = value.BenefitCode;
        policyWordingItem2.DefaultCompanyCodeList = [];
        policyWordingItem2.DefaultCompanyCodeList.push(this.quoteStepCompareH2H.selectedCompany2.CompanyCode);
        policyWordingItem2.ProductCode = this.quoteStepCompareH2H.headToHeadResult.RightQprCode;
        policyWordingItem2.ProductCodeExt = this.quoteStepCompareH2H.headToHeadResult.RightQprCodeExt;
        policyWordingItem2.QprItem = value.ItemName;

        policyWordingListItem.List.push(policyWordingItem2);



        const dialogRef = this.dialog.open(ResearchPolicyWordingV2Dialog, {
            data: {PolicyWordingListItem: policyWordingListItem, IsBusiness: false},
            minWidth: '80%',
            panelClass: "policy-wordingV2-panel",
            autoFocus: false,
            restoreFocus: false
        });
    }


    printHeadToHeadReport(reportType: number) {
        if (this.quoteStepCompareH2H.currentQuote.AppId > 0) {

            this.quoteStepCompareH2H.selectedBenefit.HeadToHeadCompanies = [];
            this.quoteStepCompareH2H.selectedBenefit.HeadToHeadCompanies.push(this.quoteStepCompareH2H.selectedCompany1.Id.toString());
            this.quoteStepCompareH2H.selectedBenefit.HeadToHeadCompanies.push(this.quoteStepCompareH2H.selectedCompany2.Id.toString());
          
            // check if is serious trauma
            let isSeriousTrauma: number = this.quoteStepCompareH2H.isSeriousTraumaBenefit(this.quoteStepCompareH2H.selectedBenefit) ? 1 : 0;

            this.quoteStepCompareH2H.showDinoLoading();
            this.quoteStepCompareH2H.getHeadToHeadReport(
                this.quoteStepCompareH2H.currentQuote.AppId,
                this.quoteStepCompareH2H.selectedBenefit.HeadToHeadCompanies.toString(),
                this.quoteStepCompareH2H.selectedBenefit.ClientIndex,
                this.quoteStepCompareH2H.selectedBenefit.BenefitIndex,
                this.quoteStepCompareH2H.currentQuote.ExtValue3, // chart SVG data
                reportType,
                isSeriousTrauma,
                (response) => {
                    if (response) {
                        this.dialog.open(QuoteReportDialogComponent, {
                          data: response,
                          maxHeight: "90%",
                          maxWidth: "1140px",
                          width: "80vw",
                          disableClose: true,
                          autoFocus: false,
                          restoreFocus: false
                        });

                        this.quoteStepCompareH2H.closeDinoLoading();
                    }

                }
            );
        }
    }
}



