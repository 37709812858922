import { Benefit } from './benefit';
import { PeopleEntity } from './people';
import { Provider } from './provider';

export class PriceHistories {
  Age: number;
  Gender: number;
  IsSmoker: boolean;
  ProviderId: number | null;
  ProviderName: string | null;
  ProviderIds: number[] | null;
  BenefitId: number;
  HistoryDatas: HistoryData[];

  constructor (age: number, gender: number, isSmoker: boolean, benefitId: number, providerIds: number[] ) {
    this.Age = age;
    this.Gender = gender;
    this.IsSmoker = isSmoker;
    this.ProviderIds = providerIds;
    this.BenefitId = benefitId;
  }
}

export class HistoryData {
  Year: number;
  AverageChangeFactor: number | null;
}

export class AvailableBenefit {
  ID: number;
  BenefitClientName: string;
  Client: PeopleEntity;
  Benefit: Benefit;
  ProviderList: AvailableProvider[];
  ProviderIds: number[];

  constructor (id: number, name: string, client: PeopleEntity, benefit: Benefit) {
    this.ID = id;
    this.BenefitClientName = name;
    this.Client = client;
    this.Benefit = benefit;
    this.ProviderList = [];
  }
  
  static getProviderIds(providerList:AvailableProvider[]): number[]{
    let providerIds = [];
    providerList.forEach(p => {
      providerIds.push(p.ID);
    })
    return providerIds;
  }
}

export class AvailableProvider {
  ID: number;
  DisplayName: string;
  Provider: Provider;
  LinkedProvider: Provider | null;
  
  constructor (provider: Provider, linkedProvider: Provider | null) {
    this.ID = provider.ProviderId;
    this.DisplayName = provider.ProviderName;
    this.Provider = provider;
    this.LinkedProvider = linkedProvider;
  }
}