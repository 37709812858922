import { Injectable } from '@angular/core';
import { ApiAddressService } from '../api.address.service';
import { ApiService } from '../api.service';
import { ComponentBaseClass } from '../base';
import { KiwiMonsterQuote } from 'src/app/models/kiwimonster/kiwimonster-quote.model';
import { KiwiMonsterScheme } from 'src/app/models/kiwimonster/kiwimonster-scheme.model';
import { KiwiMonsterFund } from 'src/app/models/kiwimonster/kiwimonster-fund.model';
import { KiwiMonsterClient } from 'src/app/models/kiwimonster/kiwimonster-client.model';
import { ReportResult, ReportResultGroup } from 'src/app/models/report/report.result';

@Injectable({
  providedIn: 'root',
})

export class KiwiMonsterService extends ComponentBaseClass {
  
  public StepClient = { value: 1, rote: '/kiwimonster/quote/client' };
  public StepKiwiSaver = { value: 2, rote: '/kiwimonster/quote/kiwisaver' };
  public StepCompare = { value: 3, rote: '/kiwimonster/quote/compare' };
  public StepReport = { value: 4, rote: '/kiwimonster/quote/report' };

  constructor (
    private apiAddressService: ApiAddressService,
    private apiService: ApiService,
  ) {
    super();
  }

  // local storage
  setCurrentKMQuote(currentKMQuote: KiwiMonsterQuote): void {
    window.sessionStorage.setItem('currentKMQuote', JSON.stringify(currentKMQuote));
  }

  getCurrentKMQuote(): KiwiMonsterQuote {
    return JSON.parse(window.sessionStorage.getItem('currentKMQuote'));
  }

  removeCurrentKMQuote(): void {
    sessionStorage.removeItem("currentKMQuote");
  }

  // api
  getKiwiMonsterQuotes(callback: (data: KiwiMonsterQuote[]) => void) {
    this.apiService.callApi<KiwiMonsterQuote[]>(
      '', this.apiAddressService.getKiwiMonsterQuotesUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  saveKiwiMonsterQuote(kiwiMonsterQuote: KiwiMonsterQuote, callback: (data: KiwiMonsterQuote) => void) {
    this.apiService.callApi<KiwiMonsterQuote>(
      kiwiMonsterQuote, this.apiAddressService.createKiwiMonsterQuoteUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  getExistingKiwiMonsterQuote(qmKiwiMonsterQuoteId: number, callback: (data: KiwiMonsterQuote) => void) {
    this.apiService.callApi<KiwiMonsterQuote>(
      '', this.apiAddressService.getExistingKiwiMonsterQuoteUrl(qmKiwiMonsterQuoteId),
      (response) => {
        callback(response);
      }
    );
  }
  
  deleteKiwiMonsterQuote(qmKiwiMonsterQuoteId: number, callback: (data: KiwiMonsterQuote[]) => void) {
    this.apiService.callApi<KiwiMonsterQuote[]>(
      '', this.apiAddressService.deleteKiwiMonsterQuoteUrl(qmKiwiMonsterQuoteId),
      (response) => {
        callback(response);
      }
    );
  }
    
    
  getKiwiMonsterSchemes(callback: (data: KiwiMonsterScheme[]) => void) {
    this.apiService.callApi<KiwiMonsterScheme[]>(
      '', this.apiAddressService.getKiwiMonsterSchemesUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  getKiwiMonsterFundsByScheme(schemeNumber: string, callback: (data: KiwiMonsterFund[]) => void) {
    this.apiService.callApi<KiwiMonsterFund[]>(
      '', this.apiAddressService.getKiwiMonsterFundsBySchemeUrl(schemeNumber),
      (response) => {
        callback(response);
      }
    );
  }

  getKiwiMonsterFundsByRisk(riskLow: number, rickHigh: number, callback: (data: KiwiMonsterFund[]) => void) {
    this.apiService.callApi<KiwiMonsterFund[]>(
      '', this.apiAddressService.getKiwiMonsterFundsByRiskUrl(riskLow, rickHigh),
      (response) => {
        callback(response);
      }
    );
  }
  
  getKiwiMonsterFundsByFundId(qmKSProspectiveProductFundId: number, callback: (data: KiwiMonsterFund[]) => void) {
    this.apiService.callApi<KiwiMonsterFund[]>(
      '', this.apiAddressService.getKiwiMonsterFundsByFundIdUrl(qmKSProspectiveProductFundId),
      (response) => {
        callback(response);
      }
    );
  }
    
  getKiwiMonsterFundsByFundType(fundType: string, callback: (data: KiwiMonsterFund[]) => void) {
    this.apiService.callApi<KiwiMonsterFund[]>(
      '', this.apiAddressService.getKiwiMonsterFundsByFundTypeUrl(fundType),
      (response) => {
        callback(response);
      }
    );
  }

  getKiwiMonsterCompareFund(qmKiwiMonsterQuoteId: number, currentKMQuote: KiwiMonsterQuote, callback: (data: KiwiMonsterClient[]) => void) {
    this.apiService.callApi<{ Clients: KiwiMonsterClient[] }>(
      currentKMQuote, this.apiAddressService.getKiwiMonsterCompareFundUrl(qmKiwiMonsterQuoteId),
      (response) => {
        callback(response.Clients);
      }
    );
  }
  
  getKiwiMonsterResearchReport(qmKiwiMonsterQuoteId: number, callback: (data: ReportResult) => void) {
    this.apiService.callApi<ReportResult>(
      { AppId: qmKiwiMonsterQuoteId }, this.apiAddressService.getKiwiMonsterResearchReportUrl(),
      (response) => {
        callback(response);
      }
    );
  }
  
  getKiwiMonsterReportList(quoteId: number, callback: (data: ReportResultGroup[]) => void) {
    this.apiService.callApi<ReportResultGroup[]>(
      '', this.apiAddressService.getKiwiMonsterReportListUrl(quoteId),
      (response) => {
        callback(response);
      }
    );
  }
}