<ul class="list-group">
  <li class="list-group-item active">
    <h4 class='float-left mr-3'>Default Workspaces</h4>
    <div class='float-right'>
      <button mat-raised-button color="qm-pumpkin" class='mr-2'
              (click)='updatePlatformSetting(platformSetting);loginService.doGoogleTracking("platform-setting", "change working platform setting", "");'>Save</button>
      <div class='d-inline-block mt-2 mt-sm-0'>
        <recent-quote-component (navigateRoot)='saveAndBackToQuote($event)'>
        </recent-quote-component>
      </div>
    </div>
  </li>
  <li class='list-group-item'>
    <div class='row'>
      <!-- Description -->
      <div class='col-12'>
          <ul class='pl-3'>
            <li>
              <h5>QuoteMonster - Personal:</h5>
            </li>
            <li>
              <h5>QuoteMonster - Business:</h5>
            </li>
            <li>
            <h5>Kiwimonster:</h5> 
            </li>
          </ul>
      </div>

      <!-- PersonalQuoteMonster -->
      <div class='col-12 my-5 col-xl-4'>
        <div class="card platform-setting-card mx-auto h-100" [ngClass]="{'border-success': platformSetting === 1}"
             (click)='platformSetting = 1'>
          <div class="card-header text-center d-flex justify-content-center align-items-center"
               [ngClass]="{'text-white bg-success': platformSetting === 1}">
            <span class='font-weight-bolder'>
              {{sharedFunction.getUiMessageByCode('PlatformSettingComponent-INFO-PersonalQuoteMonsterTitle')}}
            </span>
          </div>
          <div class='p-2'>
            <img src='/assets/images/workspaces/workspaces-personal.png?v=2' />
          </div>
        </div>
      </div>
      <!-- BusinessQuoteMonster -->
      <div class='col-12 my-5 col-xl-4'>
        <!-- not AM user -->
        <ng-container *ngIf="!loginService.checkUserAccessRole(2)">
          <div class="card platform-setting-card mx-auto h-100 not-allowed">
            <div class="card-header text-center d-flex justify-content-center align-items-center">
              <span class='font-weight-bolder'>
                {{sharedFunction.getUiMessageByCode('PlatformSettingComponent-INFO-BusinessQuoteMonsterTitle')}}
              </span>
            </div>
            <div class='p-2'>
              <img src='/assets/images/workspaces/workspaces-business.png?v=2' />
            </div>
          </div>
          <div class='text-center pt-2'>
            <a [routerLink]="['/research/subscription/plan']"
               (click)='loginService.doGoogleTracking("platform-setting", "go to pricing plan", "");'>
              Upgrade now!
            </a>
          </div>
        </ng-container>
        <!-- AM user -->
        <ng-container *ngIf="loginService.checkUserAccessRole(2)">
          <div class="card platform-setting-card mx-auto h-100"
               (click)='platformSetting = 2'
               [ngClass]="{'border-success': platformSetting === 2}">
            <div class="card-header text-center d-flex justify-content-center align-items-center"
                 [ngClass]="{'text-white bg-success': platformSetting === 2}">
              <span class='font-weight-bolder'>
                {{sharedFunction.getUiMessageByCode('PlatformSettingComponent-INFO-BusinessQuoteMonsterTitle')}}
              </span>
            </div>
            <div class='p-2'>
              <img src='/assets/images/workspaces/workspaces-business.png?v=2' />
            </div>
          </div>
        </ng-container>
      </div>
      <!-- KiwiMonster -->
      <div class='col-12 my-5 col-xl-4'>
        <div class="card platform-setting-card mx-auto  h-100"
             (click)='platformSetting = 3'
             [ngClass]="{'border-success': platformSetting === 3}">
          <div class="card-header text-center d-flex justify-content-center align-items-center"
               [ngClass]="{'text-white bg-success': platformSetting === 3}">
            <span class='font-weight-bolder'>
              {{sharedFunction.getUiMessageByCode('PlatformSettingComponent-INFO-KiwiMonsterTitle')}}
            </span>
          </div>
          <div class='p-2'>
            <img src='/assets/images/workspaces/workspaces-kiwimonster.png?v=2'/>
          </div>
        </div>
      </div>
    </div>
  </li>
</ul>