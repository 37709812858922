import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { KiwiMonsterFund } from 'src/app/models/kiwimonster/kiwimonster-fund.model';
import { PreferredFund } from 'src/app/models/kiwimonster/kiwimonster-preferred-fund.model';
import { KiwiMonsterKiwiSaverService } from 'src/app/service/kiwimonster/kiwimonster-kiwisaver.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SysConfigService } from 'src/app/service/sys.config';

@Component({
  selector: 'app-kiwimonster-fund-selection-dialog',
  templateUrl: './kiwimonster-fund-selection-dialog.component.html',
  styleUrls: ['./kiwimonster-fund-selection-dialog.component.scss']
})
export class KiwiMonsterFundSelectionDialogComponent implements OnInit {
  displayedColumns: string[] = ['schemeName', 'PastYearReturnNet', 'AverageFiveYearReturnNet', 'RiskRewardIndicatorCode', 'QmRiskProfileCode', 'FundTotalValue', 'NumberOfInvestors', 'QmCalcFeeInPercentage', 'FundStartDate'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource: MatTableDataSource<KiwiMonsterFund>;
  isLoadingData: boolean = false;
  errorMessage: string = '';

  fundToOpen: boolean = true;
  textFilterValue: string = '';
  selectedFundType: string = '';
  selectedFund: KiwiMonsterFund = null;
  allFunds: KiwiMonsterFund[] = [];
  setToNull: boolean = false;
  constructor
    (
      public sharedFunction: SharedFunctionService,
      public dialogRef: MatDialogRef<KiwiMonsterFundSelectionDialogComponent>,
      public sysConfig: SysConfigService,
      private kiwiMonsterKiwiSaverService: KiwiMonsterKiwiSaverService,
      public loginService: LoginService,
      @Inject(MAT_DIALOG_DATA) public data: { CurrentPreferredFund: PreferredFund, OtherSelectedIds: number[]; },
    ) { }

  ngOnInit(): void {
    this.selectedFundType = this.data.CurrentPreferredFund.FundBaseTypeCode;
    this.loadData();
  }

  loadData(): void {
    this.errorMessage = '';
    this.isLoadingData = true;
    this.kiwiMonsterKiwiSaverService.getFundsByFundTypeObservable(this.selectedFundType).subscribe((response) => {
      if (response && response.length > 0) {
        this.allFunds = response;
        this.dataSource = new MatTableDataSource(response);
        setTimeout(() => {
          this.bindTableData();
          this.textFilter(this.textFilterValue);
          this.fundToOpenFilter();
          this.selectedFund = this.initSelectedFund(response);
        });
      } else {
        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
      }
      this.isLoadingData = false;
    });
  }

  // TODO: duplicate code from KiwiMonsterFundsDialogComponent
  bindTableData(): void {
    this.setPaginator();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // filter value
    this.dataSource.filterPredicate = (
      data: KiwiMonsterFund,
      filter: string
    ): boolean => {
      let [textFilter, fundToOpenFilter] = filter.split('::');
      let fundToOpen = fundToOpenFilter === 'true';

      let matchesTextFilter =
        data.SchemeName?.toLowerCase().trim().indexOf(textFilter) >= 0 ||
        data.FundName?.toLowerCase().trim().indexOf(textFilter) >= 0;

      let matchesFundToOpenFilter = !fundToOpen || data.FundToOpen === 1;

      return matchesTextFilter && matchesFundToOpenFilter;
    };
  }
  // TODO: duplicate code from KiwiMonsterFundsDialogComponent
  setPaginator(): void {
    this.paginator._intl.itemsPerPageLabel = "KiwiSaver Fund Per Page";
    this.paginator.pageSize = 10;
    this.paginator.pageSizeOptions = [10, 25, 100];
    this.paginator.showFirstLastButtons = true;
    this.paginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0) {
        return `Page 1 / 1`;
      }
      const amountPages = Math.ceil(length / pageSize);
      return `Page ${ page + 1 } / ${ amountPages }`;
    };

    this.paginator._formFieldAppearance = "outline";
  }
  // TODO: duplicate code from KiwiMonsterFundsDialogComponent
  textFilter(filterValue: string) {
    filterValue = filterValue.toLowerCase().trim();
    this.textFilterValue = filterValue;
    let fundToOpen = this.fundToOpen ? 'true' : 'false';
    this.dataSource.filter = `${ filterValue }::${ fundToOpen }`;
  }
  // TODO: duplicate code from KiwiMonsterFundsDialogComponent
  fundToOpenFilter(): void {
    this.updateDisplayedColumns();
    let currentTextFilter = this.dataSource.filter.split('::')[0] || '';
    let fundToOpen = this.fundToOpen ? 'true' : 'false';
    this.dataSource.filter = `${ currentTextFilter }::${ fundToOpen }`;
  }
  // TODO: duplicate code from KiwiMonsterFundsDialogComponent
  updateDisplayedColumns(): void {
    if (this.fundToOpen) {
      this.displayedColumns = [
        'schemeName',
        'PastYearReturnNet',
        'AverageFiveYearReturnNet',
        'RiskRewardIndicatorCode',
        'QmRiskProfileCode',
        'FundTotalValue',
        'NumberOfInvestors',
        'QmCalcFeeInPercentage',
        'FundStartDate'
      ];
    } else {
      this.displayedColumns = [
        'schemeName',
        'PastYearReturnNet',
        'AverageFiveYearReturnNet',
        'RiskRewardIndicatorCode',
        'QmRiskProfileCode',
        'FundTotalValue',
        'NumberOfInvestors',
        'QmCalcFeeInPercentage',
        'FundStartDate',
        'FundStatus'
      ];
    }
  }
  // TODO: duplicate code from KiwiMonsterFundsDialogComponent
  updateFundType(fund: KiwiMonsterFund): string {
    return KiwiMonsterFund.updateFundType(fund);
  }

  checkState(event, el) {
    if (this.isOtherSelectedFunds(el.value)) {
      return; // Do nothing if it's disabled
    }

    event.preventDefault();
    if (this.selectedFund && this.selectedFund === el.value) {
      el.checked = false;
      this.selectedFund = null;
      this.setToNull = true;
    } else {
      this.selectedFund = el.value;
      el.checked = true;
      this.setToNull = false;
    }
  }

  saveAndClose(): void {
    this.dialogRef.close(this.selectedFund);
  }

  initSelectedFund(responseFunds: KiwiMonsterFund[]): KiwiMonsterFund {
    if (this.selectedFund?.Id) {
      return this.selectedFund;
    }

    if (this.setToNull) {
      return null;
    }

    let fund: KiwiMonsterFund = new KiwiMonsterFund();
    if (this.data.CurrentPreferredFund.QmOfferSchemeFundId > 0) {
      if (responseFunds.find(f => f.Id === this.data.CurrentPreferredFund.QmOfferSchemeFundId)) {
        fund = responseFunds.find(f => f.Id === this.data.CurrentPreferredFund.QmOfferSchemeFundId);
      } else {
        fund.Id = this.data.CurrentPreferredFund.QmOfferSchemeFundId;
        fund.SchemeName = this.data.CurrentPreferredFund.SchemeName;
        fund.FundName = this.data.CurrentPreferredFund.FundName;
      }
    }
    return fund;
  }

  isOtherSelectedFunds(fund: KiwiMonsterFund): boolean {
    return this.data.OtherSelectedIds.includes(fund.Id) ? true : false;
  }

  onFundTypeChange(): void {
    this.loadData();
  }
  
  unselectedFund(): void {
    this.selectedFund = null;
    this.setToNull = true;
  }

}
