import { Component, OnInit } from '@angular/core';
import { QmLicense } from 'src/app/models/qm.license.models/qm.license';
import { ComponentBaseClass } from 'src/app/service/base';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: "pricing",
  templateUrl: "./pricing.component.html",
  styleUrls: ["./pricing.component.scss"],
})
export class PricingComponent extends ComponentBaseClass implements OnInit {
  isPayMonthly: boolean = true;
  isLogin: boolean = false;
  availableQmLicenses: QmLicense[];
  monthlyPaPrice: number;
  annualPaPrice: number;
  isKiwiMonsterDomain: boolean;

  constructor(
    public userService: UserService,
    public loginService: LoginService,
    public sharedFunction: SharedFunctionService
  ) {
    super();
  }

  ngOnInit(): void {
    this.isLogin = false; // always false because that's page for public

    this.isPayMonthly = true; // default to display monthly fee

    this.getDefaultSubscriptionFees();
    this.isKiwiMonsterDomain = this.sharedFunction.isKiwimonsterDomain();
  }

  getDefaultSubscriptionFees() {
    this.showDinoLoading();
    this.userService.getAvailableLicensesForPublic((response) => {
      if (response) {
        this.availableQmLicenses = response;
        this.getPaLicencePrice(this.availableQmLicenses);
      }

      this.closeDinoLoading();
    });
  }

  getLicense(qmLicenseCode: string): QmLicense {
    let qmLicense: QmLicense = null;

    let frequency: number = this.isPayMonthly ? 12 : 1;

    if (qmLicenseCode === "AMFREE" || qmLicenseCode === "QMFREE") {
      frequency = 0;
    }

    if (this.availableQmLicenses && this.availableQmLicenses.length > 0) {
      for (let license of this.availableQmLicenses) {
        if (qmLicenseCode === license.QmLicenseCode 
            && frequency === license.PaymentFrequency
            && (license.IsAbleToBuy == true || this.isLogin == false)
            ) {
          qmLicense = license;
          break;
        }
      }
    }

    // if do not have any license then set it to free license
    if (qmLicense === null) {
      qmLicense = new QmLicense();
      qmLicense.Price = 0.00;
      qmLicense.QmLicenseCode = "FREE";
    }

    return qmLicense;
  }

  displayMyCurrentPlanBtn(qmLicenseCode: string): boolean {
    return false;
  }

  displayFreeTrialBtn(qmLicenseCode: string): boolean {
    return false;
  }

  displayBuyNowBtn(qmLicenseCode: string): boolean {
    return false;
  }

  displayInFreeTrial(qmLicenseCode: string): boolean {
    return false;
  }

  displayPricingChangeMessage(): boolean {
    // only display alert message before 15 June 2023.
    const cutOffDate = new Date('2023-06-15');
    const today = new Date();
    return today.getTime() < cutOffDate.getTime(); 
  }
    
  getPaLicencePrice(allLicense: QmLicense[]) {
    this.monthlyPaPrice = allLicense.filter(item => item.QmLicenseCode === 'PA' && item.PaymentFrequency ===12)[0].Price
    this.annualPaPrice = allLicense.filter(item => item.QmLicenseCode === 'PA' && item.PaymentFrequency ===1)[0].Price
  }
}
