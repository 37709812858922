<ul class="list-group">
  <li class="list-group-item active">
    <div class='d-flex justify-content-between'>
      <h4>Preferred Funds</h4>
      <div class='d-flex'>
        <div class='mr-4'>
          <ng-template *ngTemplateOutlet="saveButton"></ng-template>
        </div>
        <recent-quote-component [isKiwimonster]='true'></recent-quote-component>
      </div>
    </div>
  </li>

  <li class='list-group-item'>
    <h4>Preferred funds selection for diversified funds by risk category.</h4>
    <p>These settings are persistent, but you can always override them in the fund selections you make on each case.</p>
  </li>

  <li class="list-group-item">
    <!-- preferred funds table -->
    <div class="table-responsive">
      <table class="table table-bordered table-info">
        <thead>
          <tr>
            <th class='fixed-width-200px'>Preferred Providers</th>
            <th class='text-center'>First Preference</th>
            <th class='text-center'>Second Preference</th>
            <th class='text-center'>Third Preference</th>
          </tr>
        </thead>
        <tbody class='bg-white'>
          <tr *ngFor="let category of categories">
            <td class='align-middle'>{{category.Name}}</td>
            <td *ngFor="let index of [1, 2, 3]" class='text-center align-middle' [style.width.%]="100 / 3">
              <button mat-button
                      class='text-wrap text-break line-height-md py-2'
                      [ngClass]="{'text-danger': !isSameWithOriginal(category.Value.toString(), index)}"
                      (click)='openSelectDialog(category.Value.toString(), index)'>
                {{ getSpecificFundName(category.Value.toString(), index) }}
              </button>
              <!-- <div class='text-left qm-text-sm text-muted line-height-sm'
                   *ngIf="!isSameWithOriginal(category.Value.toString(), index)"><small>Original:
                  {{getOriginalFundName(category.Value.toString(), index)}}</small></div> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class='text-right'>
      <ng-template *ngTemplateOutlet="saveButton"></ng-template>
    </div>
  </li>
</ul>

<ng-template #saveButton>
  <button mat-flat-button color="qm-pumpkin" (click)="save()">Save</button>
</ng-template>
