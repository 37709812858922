import { Injectable } from '@angular/core';
import { LoginService } from './login.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class FreshdeskService {

  userEmail: string;
  userName: string;
  constructor (
    private loginService: LoginService,
    private userService: UserService,
  ) { }

  doPrefillNameEmail(): void {
    if (this.loginService.isLogin()) {
      this.userEmail = this.loginService.getMainUserName();
      if (!this.userName) {
        this.userService.getUserDetail((response) => {
          this.userName = `${ response.FirstName } ${ response.LastName }`;
          this.prefillNameEmail();
        });
      } else {
        this.prefillNameEmail();
      }

    }
  }

  doPrefillSubject(routerName: string): void {
    if (this.loginService.isLogin()) {
      this.prefillSubject(routerName);
    } else {
      this.prefillSubject('Quotemonster Hello');
    }
  }

  prefillNameEmail(): void {
    // Ensure Freshdesk widget is initialized
    if (window['FreshworksWidget']) {
      // Pre-fill user details
      window['FreshworksWidget']('identify', 'ticketForm', {
        name: this.userName,
        email: this.userEmail,
      });
    }
  }

  prefillSubject(subject: string): void {
    // Ensure Freshdesk widget is initialized
    if (window['FreshworksWidget']) {
      // Pre-fill subject
      window['FreshworksWidget']('prefill', 'ticketForm', {
        subject: subject,
      });
    }
  }
}
