<ul class="list-group">
  <li class="list-group-item active">
    <h5 class="mb-0">Price Change History</h5>
  </li>
  <li class="list-group-item">
    <div class='row align-items-md-center'>

      <!-- benefit select -->
      <div class='col-12'>
        <mat-form-field>
          <mat-select placeholder="Select a benefit" [(ngModel)]="selectedBenefit" required
                      [compareWith]='sharedFunction.compareWithId'
                      (selectionChange)='getHistoryData(); loginService.doGoogleTracking("price-change-history", "get history data", "");'>
            <mat-option *ngFor="let benefit of availableBenefits" [value]='benefit'>
              {{ benefit.BenefitClientName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- error -->
      <div class="col-12 alert alert-danger mt-5 text-center" role="alert" *ngIf="errorMessage">
        {{errorMessage}}
      </div>

      <!-- chart container start-->
      <div class='col-12 mt-4' id="priceChangeHistoryChartContainer" *ngIf="!errorMessage">
        <div>
        </div>
      </div>
      <!-- chart container end-->

      <!-- data table -->
      <div class="col-12 mt-4 text-center" *ngIf="!errorMessage">
        <button mat-flat-button color="qm-blue"
                (click)='showTable=!showTable;loginService.doGoogleTracking("price-change-history", "display data table", "");'>
          {{showTable?'Hide':'Display'}} Table for Price Average Changes
        </button>
        <div class='table-responsive mt-3' *ngIf="showTable">
          <table class='table table-bordered table-hover'>
            <thead class="thead-light">
              <tr>
                <th class='slanted-cell'>
                  <div class='text-right'>Years</div>
                  <div class='text-left'>Providers</div>
                </th>
                <ng-container *ngFor="let year of tableData[0].year">
                  <th class='align-middle'>{{year}}</th>
                </ng-container>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor='let data of tableData'>
                <td><span class='qm-text-lg' [style.color]="data.color">●</span> {{data.provider}}</td>
                <td *ngFor="let value of data.data" class='text-right' [ngClass]="{'text-danger': value < 0, 'text-black-50': value === null}">
                  {{value===null?"N/A":(value *100).toFixed(1) + "%"}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class='mx-5 mt-4 highcharts-description' *ngIf="!errorMessage">
        <p>
          This data highlights underlying changes to new business premiums if <span
                class='text-capitalize'>{{selectedBenefit.Client.FirstName}} {{selectedBenefit.Client.LastName}}</span> was
          aged {{selectedBenefit.Client.Age}} in {{yearBefore10Years}}, and assuming that they are a
          {{sharedFunction.getDisplayName('GenderList', selectedBenefit.Client.Gender)}} and
          {{sharedFunction.getDisplayName('SmokerList', selectedBenefit.Client.Smoker)}}.
        </p>
        <ul class='qm-text-sm ml-n4'>
          <li class='list-style-disc'><strong>Past premium changes do not predict future changes.</strong></li>
          <li class='list-style-disc'>Insurers compete to minimize premium fluctuations.</li>
          <li class='list-style-disc'>These premium changes exclude indexation and age adjustments.</li>
          <li class='list-style-disc'>This applies only to Stepped premiums, not Level term premiums.</li>
          <li class='list-style-disc'>Health premiums are based on a single adult life.</li>
          <li class='list-style-disc'><strong>Premium changes might include enhancements or modifications to benefit.</strong>
          </li>
        </ul>
      </div>
    </div>
  </li>
</ul>