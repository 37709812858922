import { Component, OnInit } from '@angular/core';
import { QuoteRequiredData } from 'src/app/models/quote.required.data';
import { DataLoader } from 'src/app/service/data.loader';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    title: string = 'home';

    isLoadingData: boolean = false;

    showSpecialHomeTakeOver: boolean = false;

    timestamp: number = 123456789;

    providerLogoList: any[];

    // Use "constructor"s only for dependency injection
    constructor(
      public dataLoader: DataLoader,
      public sharedFunction: SharedFunctionService
    ) {

    }

    // Here you want to handle anything with @Input()'s @Output()'s
    // Data retrieval / etc - this is when the Component is "ready" and wired up
    ngOnInit() {
        this.setSpecialHomePageTakeover();
        this.timestamp = new Date().getTime();


        let v: QuoteRequiredData = this.dataLoader.getRequiredData();
        this.providerLogoList = v.ProviderLogoList;

    }


    setSpecialHomePageTakeover() {
        let now = new Date();
        const v: QuoteRequiredData = this.dataLoader.getRequiredData();
        if (v.SpecialHomeTakeOverDate) {
            let specialHomeTakeOverLiveDate = new Date(v.SpecialHomeTakeOverDate);

            if (now >= specialHomeTakeOverLiveDate && !v.ShowThreeBannerOnHome) {
                this.showSpecialHomeTakeOver = true;
            }
        }

    }
}
