import { Component, OnInit } from '@angular/core';
import { Provider } from 'src/app/models/provider';
import { QprCompany } from 'src/app/models/qpr.company';
import { BusinessQuoteH2HService } from 'src/app/service/business/quote/business.quote.h2h.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SysConfigService } from 'src/app/service/sys.config';
import * as Highcharts from 'highcharts';
import { HeadToHeadResultItem } from 'src/app/models/head2head.models/head2head.result.item';
import { PolicyWordingItem, PolicyWordingListItem } from 'src/app/models/qpr.models/policy.wording';
import { ResearchPolicyWordingV2Dialog } from 'src/app/components/research/policy/research-policy-wording-v2.dialog';
import { MatDialog } from '@angular/material/dialog';
import { QuoteReportDialogComponent } from 'src/app/components/quote/quote-report-dialog.component';
import { ReportRequest } from 'src/app/models/report.request';

@Component({
    selector: 'business-quote-h2h',
    templateUrl: './business-quote-h2h.component.html',
    styleUrls: ['./business-quote-h2h.component.scss']
})
export class BusinessQuoteH2hComponent implements OnInit {
    showPremiumTable: boolean = false;
    companyOptions: QprCompany[] = [];
    constructor (
        public sharedFunction: SharedFunctionService,
        public businessService: BusinessQuoteH2HService,
        public loginService: LoginService,
        public sysConfig: SysConfigService,
        public dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.businessService.onInit(() => {
            this.companyOptions = this.businessService.qprRequiredData.Companies;
        });
    }

    clearResult() {
        this.businessService.hasResult = false;
    }

    doCompare() {
        this.showPremiumTable = false;
        this.businessService.compare(this.businessService.currentSavedBusinessRecord, this.businessService.currentSavedBusinessQuote, this.sysConfig, this.show10YearPremiumEstimateChart);
    }

    show10YearPremiumEstimateChart(
        company1PremiumList: Provider[], company2PremiumList: Provider[],
        businessQuoteH2HService: BusinessQuoteH2HService) {
        // build data
        // check if result data all 0 then remove this provider
        let providerList = [];
        let yearList = [];
        let thisYear = new Date().getFullYear();
        let company1ValueList = [];
        let company2ValueList = [];
        let provider1HasValue = false;
        let provider2HasValue = false;
        let yearCounter = 0;

        for (let value of company1PremiumList) {
            if (value.TotalPremium > 0) {
                provider1HasValue = true;
            }
        }

        for (let value of company2PremiumList) {
            if (value.TotalPremium > 0) {
                provider2HasValue = true;
            }
        }

        yearCounter = Math.max(company1PremiumList.length, company2PremiumList.length);

        // remove unavailable data
        for (let v: number = 0; v < (yearCounter + 1); v++) {
            yearList.push('Year ' + (thisYear + v));
        }


        for (let v of company1PremiumList) {
            if (v.TotalPremium > 0) {
                company1ValueList.push(v.TotalPremium);
            }
        }


        for (let v of company2PremiumList) {
            if (v.TotalPremium > 0) {
                company2ValueList.push(v.TotalPremium);
            }
        }


        if (provider1HasValue === true) {
            providerList.push({
                name: businessQuoteH2HService.selectedCompany1.Name,
                data: company1ValueList
            });
        }

        if (provider2HasValue === true) {
            providerList.push({
                name: businessQuoteH2HService.selectedCompany2.Name,
                data: company2ValueList
            });
        }

        Highcharts.chart('10yearPremiumEstimateChart', {
            exporting: {
                enabled: false
            },
            credits: {
                enabled: false
            },
            chart: {
                type: 'line',
                events: {
                    render: function (chart) {

                        let target: any = chart.target;

                        let s: string = target.getSVG(
                            {
                                chart: {
                                    width: 1100,
                                    events: {
                                        render: function () {

                                        }
                                    }
                                },
                                xAxis: {
                                    labels: {
                                        useHTML: false,
                                        formatter: function () {
                                            return '';
                                        }
                                    }
                                }
                            }
                        );

                        businessQuoteH2HService.currentSavedBusinessQuote.ExtValue3 = s;
                        businessQuoteH2HService.saveCurrentBusinessQuote(businessQuoteH2HService.currentSavedBusinessQuote);
                        businessQuoteH2HService.showDownloadBtn = true;
                    }
                },

            },
            title: {
                text: '10 years premium estimate'
            },
            subtitle: {
                text: 'Source: Quotemonster'
            },
            xAxis: {
                categories: yearList,
                min: 0
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Premium ($)'
                }
            },
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true
                    },
                    enableMouseTracking: false
                }
            },
            series: providerList
        });
    }

    showQprItemDetail(value: HeadToHeadResultItem) {

        let policyWordingListItem = new PolicyWordingListItem();

        // for company 1
        let policyWordingItem1: PolicyWordingItem = new PolicyWordingItem();
        // policyWordingItem1.BenefitCode = value.BenefitCode;
        policyWordingItem1.DefaultCompanyCodeList = [];
        policyWordingItem1.DefaultCompanyCodeList.push(this.businessService.selectedCompany1.CompanyCode);
        policyWordingItem1.ProductCode = this.businessService.headToHeadResult.LeftQprCode;
        policyWordingItem1.ProductCodeExt = this.businessService.headToHeadResult.LeftQprCodeExt;
        policyWordingItem1.QprItem = value.ItemName;

        policyWordingListItem.List.push(policyWordingItem1);



        // for company 2
        let policyWordingItem2: PolicyWordingItem = new PolicyWordingItem();
        // policyWordingItem2.BenefitCode = value.BenefitCode;
        policyWordingItem2.DefaultCompanyCodeList = [];
        policyWordingItem2.DefaultCompanyCodeList.push(this.businessService.selectedCompany2.CompanyCode);
        policyWordingItem2.ProductCode = this.businessService.headToHeadResult.RightQprCode;
        policyWordingItem2.ProductCodeExt = this.businessService.headToHeadResult.RightQprCodeExt;
        policyWordingItem2.QprItem = value.ItemName;

        policyWordingListItem.List.push(policyWordingItem2);



        const dialogRef = this.dialog.open(ResearchPolicyWordingV2Dialog, {
            data: { PolicyWordingListItem: policyWordingListItem, IsBusiness: true },
            minWidth: '80%',
            panelClass: "policy-wordingV2-panel",
            autoFocus: false,
            restoreFocus: false
        });
    }


    printHeadToHeadReport() {
        if (this.businessService.currentSavedBusinessQuote.AppId > 0) {

            this.businessService.selectedBenefit.HeadToHeadCompanies = [];
            this.businessService.selectedBenefit.HeadToHeadCompanies.push(this.businessService.selectedCompany1.Id.toString());
            this.businessService.selectedBenefit.HeadToHeadCompanies.push(this.businessService.selectedCompany2.Id.toString());

            this.businessService.showDinoLoading();
            let reportRequest: ReportRequest = new ReportRequest();
            reportRequest.BusinessId = this.businessService.currentSavedBusinessRecord.Id,
                reportRequest.AppId = this.businessService.currentSavedBusinessQuote.AppId,
                reportRequest.ProviderQprIds = this.businessService.selectedBenefit.HeadToHeadCompanies.toString(),
                reportRequest.ClientIndex = this.businessService.selectedBenefit.ClientIndex,
                reportRequest.BenefitIndex = this.businessService.selectedBenefit.BenefitIndex,
                reportRequest.ChartData = this.businessService.currentSavedBusinessQuote.ExtValue3,

                this.businessService.getBusinessHeadToHeadReport(reportRequest, (response) => {
                    if (response) {
                        this.dialog.open(QuoteReportDialogComponent, {
                            data: response,
                            maxHeight: "90%",
                            maxWidth: "1140px",
                            width: "80vw",
                            disableClose: true,
                            autoFocus: false,
                            restoreFocus: false
                        });

                        this.businessService.closeDinoLoading();
                    }
                });
        }
    }

}
