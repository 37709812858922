<!-- side menu turn on -->
<div class="pt-5 side-menu-section">

  <ng-container *ngIf="currentPlatformSetting===1">
    <ng-template *ngTemplateOutlet="classicQuoteBtn"></ng-template>
    <ng-template *ngTemplateOutlet="quoteWizardBtn"></ng-template>
    <ng-template *ngTemplateOutlet="standaloneH2HBtn"></ng-template>
  </ng-container>

  <ng-container *ngIf="currentPlatformSetting===2">
    <ng-template *ngTemplateOutlet="businessQuoteBtn"></ng-template>
    <ng-template *ngTemplateOutlet="standaloneH2HBtn"></ng-template>
  </ng-container>

  <ng-container *ngIf="currentPlatformSetting===3">
    <ng-template *ngTemplateOutlet="kiwiMonsterQuoteBtn"></ng-template>
  </ng-container>


  <ng-container *ngFor="let userMenuGroup of updateUserMenuGroups()">
    <div class="px-1">
      <div class="w-100 px-3 qm-text-lg text-left text-black font-weight-bold">
        <span class="material-icons mr-2 align-text-bottom" aria-hidden="false" aria-label="setting icon">
          {{userMenuGroup.GroupIcon}}</span>
        <span>{{userMenuGroup.GroupName}}</span>
      </div>
      <mat-divider></mat-divider>
      <mat-nav-list class="pb-2">
        <ng-container *ngFor="let menuItem of userMenuGroup.Items">
          <a *ngIf="menuItem.ItemActionLink.toString().indexOf('https')>=0;else routerLink" mat-list-item
             class="qm-text-sm text-secondary rounded my-1" href={{menuItem.ItemActionLink}} target="_blank"
             (click)='$event.target.offsetParent.blur();loginService.doGoogleTracking("side-menu", menuItem.TrackingName, "");'>
            {{menuItem.ItemName}}
          </a>
          <ng-template #routerLink>
            <a mat-list-item class="qm-text-sm text-secondary rounded my-1"
               [routerLinkActive]="['link-active']"
               [routerLink]="menuItem.ItemActionLink"
               (click)='loginService.doGoogleTracking("side-menu", menuItem.TrackingName, "");'>
              {{menuItem.ItemName}}
            </a>
          </ng-template>
        </ng-container>
      </mat-nav-list>
    </div>
  </ng-container>
</div>


<!-- classic quote button: Free & QM & AM user -->
<ng-template #classicQuoteBtn>
  <div class="px-3 mb-3">
    <button type="button" title="Classic Quote"
            (click)='startPersonalQuote()'
            mat-flat-button
            class="w-100 py-2 px-3 qm-text-lg qm-bg-pumpkin text-left text-white font-weight-bold side-menu-accent-btn">
      <ng-container *ngIf="currentPlatformSetting===1">
        <span aria-hidden="false" aria-label="add icon" class="material-icons mr-2">add</span>
        <span>{{sharedFunction.getUiMessageByCode('SideMenu-INFO-StartClassicQuoteBtn')}}</span>
      </ng-container>
      <ng-container *ngIf="currentPlatformSetting!==1">
        <span aria-hidden="false" aria-label="add icon" class="material-icons mr-2">person</span>
        <span>{{sharedFunction.getUiMessageByCode('SideMenu-INFO-PersonalInsuranceBtn')}}</span>
      </ng-container>
    </button>
  </div>
</ng-template>

<!-- business insurance: AM user -->
<ng-template #businessQuoteBtn>
  <div class="px-3 mb-3" *ngIf="loginService.checkUserAccessRole(accessRole.USER_ROLE_AM);">
    <button type="button" title="Business Insurance"
            (click)='startBusinessInsurance()'
            mat-flat-button
            class="w-100 py-2 px-3 qm-text-lg qm-bg-pumpkin text-left text-white font-weight-bold side-menu-accent-btn">
      <ng-container *ngIf="currentPlatformSetting===2">
        <span aria-hidden="false" aria-label="add icon" class="material-icons mr-2">add</span>
        <span>{{sharedFunction.getUiMessageByCode('SideMenu-INFO-AddBusinessInsuranceBtn')}}</span>
      </ng-container>
      <ng-container *ngIf="currentPlatformSetting!==2">
        <span aria-hidden="false" aria-label="cases icon" class="material-icons mr-2">cases</span>
        <span>{{sharedFunction.getUiMessageByCode('SideMenu-INFO-BusinessInsuranceBtn')}}</span>
      </ng-container>
    </button>
  </div>
</ng-template>

<!-- quote wizard button: QM & AM user -->
<ng-template #quoteWizardBtn>
  <div class="px-3 mb-3" *ngIf='loginService.checkUserAccessRole(accessRole.USER_ROLE_QM);'>
    <button type="button" title="Quote Wizard"
            (click)='startQuoteWizard();loginService.doGoogleTracking("side-menu", "new quote wizard", "");'
            mat-flat-button
            class="w-100 py-2 px-3 qm-text-lg qm-bg-pumpkin text-left text-white font-weight-bold side-menu-accent-btn">
      <span aria-hidden="false" aria-label="auto awesome icon" class="material-icons mr-2">auto_awesome</span>
      <span>{{sharedFunction.getUiMessageByCode('SideMenu-INFO-StartQuoteWizardBtn')}}</span>
    </button>
  </div>
</ng-template>

<!-- kiwiMonster: free user -->
<ng-template #kiwiMonsterQuoteBtn>
  <div class="px-3 mb-3">
    <button type="button" title="Kiwimonster" [disabled]='!isKiwiMonsterAvailable'
            (click)='startKiwiMonster()'
            mat-flat-button
            class="w-100 py-2 px-3 qm-text-lg qm-bg-pumpkin text-left text-white font-weight-bold side-menu-accent-btn">
      <ng-container *ngIf="currentPlatformSetting===3">
        <span aria-hidden="false" aria-label="add icon" class="material-icons mr-2">add</span>
        <span>{{sharedFunction.getUiMessageByCode('SideMenu-INFO-AddKiwiMonsterBtn')}}</span>
      </ng-container>
      <ng-container *ngIf="currentPlatformSetting!==3">
        <span aria-hidden="false" aria-label="wallet icon" class="material-icons mr-2">account_balance_wallet</span>
        <span>{{sharedFunction.getUiMessageByCode('SideMenu-INFO-KiwiMonsterBtn')}}</span>
      </ng-container>
    </button>
  </div>
</ng-template>

<!-- standalone h2h button: QM & AM user -->
<ng-template #standaloneH2HBtn>
  <div class="px-3 mb-3" *ngIf='loginService.checkUserAccessRole(accessRole.USER_ROLE_QM);'>
    <button type="button" title="Standalone H2H"
            (click)='startStandaloneH2H();loginService.doGoogleTracking("side-menu", "go to standalone h2h", "");'
            mat-flat-button
            class="w-100 py-2 px-3 qm-text-lg qm-bg-pumpkin text-left text-white font-weight-bold side-menu-accent-btn">
      <span aria-hidden="false" aria-label="search icon" class="material-icons mr-2">search</span>
      <span>{{sharedFunction.getUiMessageByCode('SideMenu-INFO-StartStandaloneH2HBtn')}}</span>
      <span class='beta-label'>Beta</span>
    </button>
  </div>
</ng-template>