import { Component, OnInit, ViewChild, isDevMode } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordValidation, EmailMatchValidation, ForUIPasswordValidation } from '../../tools/form.validation';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from '../../service/login.service';
import { DataLoader } from '../../service/data.loader';
import { QuoteRequiredData } from '../../models/quote.required.data';
import { BaseUserModel, NewUserAccountRequest } from '../../models/user';
import { UserDetail } from '../../models/user.detail';
import { ComponentBaseClass } from '../../service/base';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SysConfigService } from 'src/app/service/sys.config';
import { QuoteDataObj } from 'src/app/models/quote.data.obj';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  // tslint:disable-next-line:component-selector
  selector: "signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
export class SignupComponent
  extends ComponentBaseClass
  implements OnInit
{
  messageSignup: string;
  dobMonths = [];
  dobYears = [];
  quoteRequiredData: QuoteRequiredData;

  browserFingerprint: string = "";

  // for UI use only
  @ViewChild("stepper") stepper;

  s1FormGroup: FormGroup;

  s2FormGroup: FormGroup;
  s2d1FormGroup: FormGroup;
  s3FormGroup: FormGroup;
  s4FormGroup: FormGroup;
  s5FormGroup: FormGroup;
  s6FormGroup: FormGroup;
  s7FormGroup: FormGroup;
  schoolInfoFormGroup: FormGroup;

  errorMessage: string;
  hidePassword:boolean = true;
  hideConfirmPassword:boolean = true;

  isInitialState: boolean = true;
  passwordSecurity: any
    
  userGroupListOptions: Observable<QuoteDataObj[]>;
  regionListOptions: Observable<QuoteDataObj[]>;
  isStudentSignup: boolean = false;
  isDevMode: boolean = false;
  // Use "constructor"s only for dependency injection
  constructor(
    public loginService: LoginService,
    private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    private dataLoader: DataLoader,
    public sharedFunction: SharedFunctionService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private systemConfig: SysConfigService
  ) {
    super();
    this.dobMonths = dataLoader.getMonths();
    this.dobYears = dataLoader.getDoBYears();
  }

  // Here you want to handle anything with @Input()'s @Output()'s
  // Data retrieval / etc - this is when the Component is "ready" and wired up
  ngOnInit() {
    this.init();
  }

  init() {
    this.checkIsStudentSignup();
    this.quoteRequiredData = this.dataLoader.getRequiredData();
    
    this.buildForm();

    this.passwordSecurity = ForUIPasswordValidation.CheckPasswordSecurity(null).passwordSecurity;
      
    this.getSearchOptions();
    this.isDevMode = isDevMode();
  }


  buildForm(): void {
    this.s1FormGroup = this.formBuilder.group({
      AdviserType: ["", Validators.required],
    });

    this.s2FormGroup = this.formBuilder.group({
      FSPNumberV2: ["", Validators.required],
    });

    this.s2d1FormGroup = this.formBuilder.group({
      FAPName: ["", Validators.required],
      FSPNumberForFAP: ["", Validators.required],
    });

    this.s3FormGroup = this.formBuilder.group(
      {
        LoginEmail: [
          null,
          Validators.compose([Validators.required, Validators.email]),
        ],
        ConfirmEmail: [
          null,
          Validators.compose([Validators.required, Validators.email]),
        ],
        Password: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(32),
            Validators.pattern(this.sharedFunction.getUiMessageByCode("Share-INFO-PasswordValidatorsPattern")),
          ]),
        ],
        ConfirmPassword: [
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(32),
            Validators.pattern(this.sharedFunction.getUiMessageByCode("Share-INFO-PasswordValidatorsPattern")),
          ]),
        ],
      },
      {
        validators: [
          PasswordValidation.MatchPassword,
          EmailMatchValidation.EmailMatch,
        ],
      }
    );

    this.s4FormGroup = this.formBuilder.group({
      FirstName: [null, Validators.required],
      LastName: [null, Validators.required],
      //DobMonth: [null, Validators.required],
      //DobYear: [null, Validators.required],
    });

    this.s5FormGroup = this.formBuilder.group({
      //Group: [null, Validators.required],
      //ProfessionalAssoc: [null, Validators.required],
      //GroupSearchInput:[null],
      DisputeResolutionService: [null, Validators.required],
    });

    this.s6FormGroup = this.formBuilder.group({
      Title: [null],
      BusinessName: [null],
      MobilePhone: [null, Validators.required],
      AddressLine1: [null, Validators.required],
      AddressLine2: [null],
      AddressCity: [null, Validators.required],
      AddressPostCode: [null, Validators.required],
      Region: [null, Validators.required],
      RegionSearchInput:[null]
    });

    this.s7FormGroup = this.formBuilder.group({
      ReferenceCode: [null],
      AgreeTerms: [null, Validators.requiredTrue],
      validate: "",
    });
      
    this.schoolInfoFormGroup = this.formBuilder.group({
      School: [null, Validators.required],
    });
  }

  doSignUp(): void {
    if (!this.isStudentSignup) {
        
        let userDetail = new UserDetail();
        let userLogin = new BaseUserModel();

        userLogin.UserName = this.s3FormGroup.value.LoginEmail;
        userLogin.Password = this.s3FormGroup.value.Password;

        // fsp detail
        userDetail.AdviserTypeIdV2 = this.s1FormGroup.value.AdviserType.Value;
        userDetail.AdviserTypeValueV2 = this.s2FormGroup.value.FSPNumberV2;
        userDetail.FAPName = this.s2d1FormGroup.value.FAPName;
        userDetail.FSPNumberForFAP = this.s2d1FormGroup.value.FSPNumberForFAP;

        // personal detail
        userDetail.FirstName = this.s4FormGroup.value.FirstName;
        userDetail.LastName = this.s4FormGroup.value.LastName;
        

        userDetail.GroupId = 13; // change group to none //this.quoteRequiredData.UserGroupList[0].Value;// this.s5FormGroup.value.Group;
        userDetail.DisputesResolutionServiceId =  this.s5FormGroup.value.DisputeResolutionService;
        //userDetail.ProfessionalAssId = this.s5FormGroup.value.ProfessionalAssoc;
        userDetail.Title = this.s6FormGroup.value.Title;
        userDetail.BusinessName = this.s6FormGroup.value.BusinessName;
        userDetail.Phone = this.s6FormGroup.value.MobilePhone;
        userDetail.Address1 = this.s6FormGroup.value.AddressLine1;
        userDetail.Address2 = this.s6FormGroup.value.AddressLine2;
        userDetail.City = this.s6FormGroup.value.AddressCity;
        userDetail.PostCode = this.s6FormGroup.value.AddressPostCode;
        userDetail.RegionId = this.s6FormGroup.value.Region;
        userDetail.ReferCode = this.s7FormGroup.value.ReferenceCode;

        this.showDinoLoading();
        // do create user
        let newUserAccountRequest = new NewUserAccountRequest();
        newUserAccountRequest.StandardUser = userLogin;
        newUserAccountRequest.StandardUserDetail = userDetail;

        this.messageSignup = "";

        // console.log('newUserAccountRequest=', newUserAccountRequest);
        this.loginService.doCreateUser(newUserAccountRequest, (response) => {
        if (response) {
            if (response.Messages && response.Messages.length > 0) {
            for (let m of response.Messages) {
                this.messageSignup += m.Message + "<br />";
            }
            } else {
            // back to login
            this.router.navigate(["/login", { id: userLogin.UserName }]);
            }
        }

        this.closeDinoLoading();
        });
    }
  }

  checkFspNumberBeforeNext(
    formGroup: FormGroup,
    fspNumber: string,
    fspRequired: boolean = true
  ) {
    this.errorMessage = "";

    if (!fspRequired) {
      formGroup.setErrors({ notUnique: null });
      formGroup.updateValueAndValidity();
      this.stepper.next();
      return;
    }

    if (fspNumber) {
      this.showDinoLoading();
      this.loginService.doCheckFspDetail(fspNumber, (response) => {
        if (response) {
          if (response.IsValidated) {
            formGroup.setErrors({ notUnique: null });
            formGroup.updateValueAndValidity();
            this.stepper.next();
          } else {
            this.errorMessage = response.Message;

            formGroup.setErrors({ notUnique: true });
          }
        } else {
          this.errorMessage = this.sharedFunction.getUiMessageByCode("SignUp-ERROR-FSPSYS");
        }

        this.closeDinoLoading();
      });
    } else {
      this.errorMessage = this.sharedFunction.getUiMessageByCode("SignUp-ERROR-FSPEmpty");
    }
  }

  checkUserEmailBeforeNext(formGroup: FormGroup): void {
    this.errorMessage = "";
    let email = this.s3FormGroup.value.LoginEmail;
    let confirmEmail = this.s3FormGroup.value.ConfirmEmail;
    if (email && email === confirmEmail) {
      this.showDinoLoading();
      this.loginService.doCheckEmail(email, (response) => {
        if (response && response.MessageCode === 200) {
          formGroup.setErrors({ notUnique: null });
          formGroup.updateValueAndValidity();
          this.stepper.next();
        } else {
          this.errorMessage = response.Message;
          formGroup.setErrors({ notUnique: true });
        }
        this.closeDinoLoading();
      });
    }
  }

  clearError(formGroup: FormGroup): void {
    this.errorMessage = "";
    formGroup.setErrors({ notUnique: null });
    formGroup.updateValueAndValidity();
  }

  onPasswordValueChanges(password:string):void{
    this.isInitialState = false;
    this.passwordSecurity = ForUIPasswordValidation.CheckPasswordSecurity(password).passwordSecurity;
  }
    
  getSearchOptions() {
    // this.userGroupListOptions = this.s5FormGroup.get('GroupSearchInput').valueChanges.pipe(
    //     startWith(''),
    //     map((value) => {
    //         return this.sharedFunction.mapSelectOptions(value, this.quoteRequiredData.UserGroupList, 'Name');
    //     })
    // );

    this.regionListOptions = this.s6FormGroup.get('RegionSearchInput').valueChanges.pipe(
        startWith(''),
        map((value) => {
            return this.sharedFunction.mapSelectOptions(value, this.quoteRequiredData.RegionList, 'Name');
        })
    );
  }
    
  checkIsStudentSignup() {
    let path: string = this.route.snapshot.routeConfig.path.toLowerCase();
    if (path.indexOf('student') >= 0) {
        this.isStudentSignup = true;
    } else {
        this.isStudentSignup = false;
    }
  }
}
