import { Injectable } from "@angular/core";
import { SavedBusinessRecord } from "src/app/models/business/saved.business.record";
import { ApiService } from '../api.service';
import { ApiAddressService } from '../api.address.service';
import { GeneralResponseMessage } from 'src/app/models/messages/general.response.message';
import { SharedFunctionService } from '../shared.function.service';
import { ComponentBaseClass } from '../base';
import { BusinessQuoteNcsRecord } from 'src/app/models/business/business-quote-ncs-record.model';
import { NeedAnalysisService } from '../need.analysis.service';
import { QuoteResult } from 'src/app/models/quote.result';
import { BusinessCurrentQuote } from 'src/app/models/business/business-current-quote.model';
import { UserSavedQuote } from 'src/app/models/user.saved.quote';
import { QuoteReport } from 'src/app/models/quote.report';
import { ReportRequest } from 'src/app/models/report.request';
import { DataLoader } from '../data.loader';
import { HeadToHeadResult } from 'src/app/models/head2head.models/head2head.result';
import { TenYearsPremium } from 'src/app/models/head2head.models/ten.years.premium';
import { PolicyWordingRequest } from 'src/app/models/qpr.models/policy.wording.request';
import { QprCompany } from 'src/app/models/qpr.company';
import { BusinessPolicyWordingReportRequest } from 'src/app/models/business/business-policy-wording-report-request.model';
import { QprRequiredData } from 'src/app/models/qpr.models/qpr.required.data';
import { ConfirmMessageDialogService } from 'src/app/components/shared/confirm-message-dialog.service';
import { LoginService } from '../login.service';
import { UserUseInternalGroupThemeSetting } from 'src/app/models/use.soa.setting.models/user.use.internal.group.theme.setting';
import { SOASection } from 'src/app/models/soa.v2/soa.section';
import { SOAReplacementItem } from 'src/app/models/soa.v2/soa.replacement.item';
import { Router } from '@angular/router';
import { UserQmSettingModel } from 'src/app/models/user.qm.setting';
import { MatDialog } from '@angular/material/dialog';
import { BusinessEmailReportRequest } from 'src/app/models/report/email.report.request';
import { NZBNData } from 'src/app/models/business/business-nzbn-data.model';
import { NZBNItemDetail } from 'src/app/models/business/business-nzbn-detail.model';



@Injectable({
  providedIn: 'root',
})
export class BusinessService extends ComponentBaseClass {


    public StepDetail = { value: 1, rote: '/business/detail' };
    public StepPeople = { value: 2, rote: '/business/people' };
    public StepFinancialPosition = { value: 3, rote: '/business/nc/financial-position' };
    public StepFinancialPerformance = { value: 4, rote: '/business/nc/financial-performance' };
    public StepNcNature = { value: 5, rote: '/business/nc/nature' };
    public StepNcObjectives = { value: 6, rote: '/business/nc/objectives' };
    public StepNcScopeAndPriorities = { value: 7, rote: '/business/nc/scope-priorities' };
    public StepExistingCovers = { value: 8, rote: '/business/nc/existing-covers' };    
    public StepNcCalculations = { value: 9, rote: '/business/nc/calculations' };
    

    public StepBenefits = { value: 101, rote: '/business/quote/people-benefit-detail' };
    public StepResearch = { value: 102, rote: '/business/quote/research' };
    public StepReport = { value: 103, rote: '/business/quote/report' };

    public StepQuoteCharts = { value: 10201, rote: '/business/quote/research' };
    public StepQuoteDocument = { value: 10202, rote: '/business/quote/research' };
    public StepQuoteResearch = { value: 10203, rote: '/business/quote/research' };
    public StepQuoteH2H = { value: 10204, rote: '/business/quote/research' };
    
    public businessSettingsUrl:string[] = ['/business/setting/quote','/business/setting/soa']

    private currentBusinessInsuranceRecordKey: string = 'currentBusinessInsuranceRecord';
    private currentBusinessInsuranceQuoteKey: string = 'currentBusinessInsuranceQuote';
    
    constructor (
        public apiAddressService: ApiAddressService,
        public apiService: ApiService,
        public loginService: LoginService,
        public sharedFunction: SharedFunctionService,
        public router: Router,
        public dataLoader: DataLoader,
        public dialog: MatDialog,
        public confirmDialog: ConfirmMessageDialogService,
        public needAnalysisService: NeedAnalysisService,
    ) {
        super();
    }

    // api
    getSavedBusinessRecords(callback: (data: SavedBusinessRecord[]) => void){
        this.apiService.callApi<SavedBusinessRecord[]>(
            '', this.apiAddressService.getSavedBusinessRecordsUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    // api
    getSavedBusinessDetail(id: number, callback: (data: SavedBusinessRecord) => void){
        this.apiService.callApi<SavedBusinessRecord>(
            '', this.apiAddressService.getSavedBusinessDetailUrl(id),
            (response) => {
                callback(response);
            }
        );
    }
    
    // api
    createUpdateBusinessRecord(savedBusinessRecord: SavedBusinessRecord, callback: (data: SavedBusinessRecord) => void){
        this.apiService.callApi<SavedBusinessRecord>(
            savedBusinessRecord, this.apiAddressService.createUpdateBusinessRecordUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    // api
    deleteSavedBusinessRecord(businessRecordId:number, callback: (data: GeneralResponseMessage) => void) {
        this.apiService.callApi<GeneralResponseMessage>(
            '', this.apiAddressService.deleteSavedBusinessRecordUrl(businessRecordId),
            (response) => {
                callback(response);
            }
        );
    }

    // api
    getBusinessQuotesAndNcs(businessId: number, callback: (data: BusinessQuoteNcsRecord[]) => void){
        this.apiService.callApi<BusinessQuoteNcsRecord[]>(
            '', this.apiAddressService.getBusinessQuotesAndNcsUrl(businessId),
            (response) => {
                callback(response);
            }
        );
    }
    
    // api
    quoteForBusiness(businessQuote: BusinessCurrentQuote, callback: (data: QuoteResult) => void){
        this.apiService.callApi<QuoteResult>(
            businessQuote, this.apiAddressService.quoteForBusinessUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    // api
    quoteForBusinessQuoteDetail(businessId: number, quoteId: number, callback: (data: UserSavedQuote) => void){
        this.apiService.callApi<UserSavedQuote>(
            '', this.apiAddressService.quoteForBusinessQuoteDetailUrl(businessId,quoteId),
            (response) => {
                callback(response);
            }
        );
    }
    
    // api
    getBusinessQuoteResultReport(reportRequest: ReportRequest, callback: (data: QuoteReport) => void){
        this.apiService.callApi<QuoteReport>(
            reportRequest, this.apiAddressService.getBusinessQuoteResultReportUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    // api
    getBusinessHeadToHeadResult(businessId: number, quoteId: number, pCodes: string, cIndex: number, bIndex: number,
        callback: (data: HeadToHeadResult) => void) {
        this.apiService.callApi<HeadToHeadResult>(
            '', this.apiAddressService.getBusinessHeadToHeadResultUrl(businessId, quoteId, pCodes, cIndex, bIndex),
            (response) => {
                callback(response);
            }
        );
    }
    
    // api
    getBusiness10YearPremiumEstimate(
        businessId: number, quoteId: number, cpCodes: string, cIndex: number, bIndex: number,
        callback: (data: TenYearsPremium[]) => void) {
        this.apiService.callApi<TenYearsPremium[]>(
            '', this.apiAddressService.getBusiness10YearPremiumEstimateUrl(businessId, quoteId, cpCodes, cIndex, bIndex),
            (response) => {
                callback(response);
            }
        );
    }
    
    // api
    getBusinessHeadToHeadReport(reportRequest: ReportRequest, callback: (data: QuoteReport) => void){
        this.apiService.callApi<QuoteReport>(
            reportRequest, this.apiAddressService.getBusinessHeadToHeadReportUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    // api
    getBusinessQuoteQprItemDetailList(request: PolicyWordingRequest[], callback: (data: QprCompany[]) => void) {
        this.apiService.callApi<QprCompany[]>(
            request, this.apiAddressService.getBusinessQuoteQprItemDetailListUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    // api
    getBusinessMixProductPolicyWordingReport(request: BusinessPolicyWordingReportRequest, callback: (data: QuoteReport) => void) {
        this.apiService.callApi<QuoteReport>(
            request, this.apiAddressService.getBusinessMixProductPolicyWordingReportUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    // api
    getBusinessQprRequiredData(callback: (data: QprRequiredData) => void) {
        this.apiService.callApi<QprRequiredData>(
        '', this.apiAddressService.getBusinessQprRequiredDataUrl(),
        (response) => {
            callback(response);
        }
        );
    }
    
    // api
    getBusinessResearch(businessId: number, quoteId: number, pCodes: string, cIndex: number, bIndex: number, orderById: number, callback: (data: any[]) => void) {
        this.apiService.callApi<any[]>(
            '', this.apiAddressService.getBusinessResearchUrl(businessId, quoteId, pCodes, cIndex, bIndex, orderById),
            (response) => {
                callback(response);
            }
        );
    }
    
    getBusinessFullReport(reportRequest: ReportRequest, callback: (data: QuoteReport) => void) {
        this.apiService.callApi<QuoteReport>(
            reportRequest, this.apiAddressService.getBusinessFullReportUrl(),
            (response) => { callback(response); }
        );
    }
    
    getBusinessSoaReport(reportRequest: ReportRequest, callback: (data: QuoteReport) => void) {
        this.apiService.callApi<QuoteReport>(
            reportRequest, this.apiAddressService.getBusinessSoaReportUrl(),
            (response) => { callback(response); }
        );
    }

    emailBusinessReports(requests: ReportRequest[], email: string, emailOption: number, quoteId: number, businessId:number, needAnalysisId:number, callback: (data: boolean) => void) {
        let request: BusinessEmailReportRequest = new BusinessEmailReportRequest();
        request.requests = requests;
        request.email = email;
        request.quoteId = quoteId;
        request.emailOption = emailOption;
        request.businessId = businessId;
        request.needAnalysisId = needAnalysisId;


        this.apiService.callApi<boolean>(
            request, this.apiAddressService.getBusinessEmailReports(),
            (response) => { callback(response); }
        );
    }

    // api: reset provider settings
    resetBusinessProviderSettings(callback: (data: GeneralResponseMessage) => void) {
        this.apiService.callApi<GeneralResponseMessage>(
            '', this.apiAddressService.resetBusinessProviderSettingsUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    // api: get provider settings
    getBusinessProviderSettings(callback: (data: any) => void) {
        this.apiService.callApi<any>(
            '', this.apiAddressService.getBusinessProviderSettingsUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    // api: change provider IsSelected or not
    turnOffBusinessProviderSetting(providerId: number, turnOffProvider: boolean, callback: (data: GeneralResponseMessage) => void) {
        this.apiService.callApi<GeneralResponseMessage>(
            '', this.apiAddressService.turnOffBusinessProviderSettingUrl(providerId, turnOffProvider),
            (response) => {
                callback(response);
            }
        );
    }
    
    // api: change provider default product
    changeBusinessProviderDefaultProduct(providerId: number, benefitId: number, productId: number, callback: (data: GeneralResponseMessage) => void) {
        this.apiService.callApi<GeneralResponseMessage>(
            '', this.apiAddressService.changeBusinessProviderDefaultProductUrl(providerId, benefitId, productId),
            (response) => {
                callback(response);
            }
        );
    }
    
    // api: soa setting
    getBusinessUserIsUsingGroupSoa(callback: (data: UserUseInternalGroupThemeSetting) => void) {
        this.apiService.callApi<UserUseInternalGroupThemeSetting>(
            '', this.apiAddressService.getBusinessUserIsUsingGroupSoa(),
            (response) => {
                callback(response);
            }
        );
    }
    
    // api: soa setting
    getBusinessUserSOASections(callback: (data: SOASection[]) => void) {
        this.apiService.callApi<SOASection[]>(
            '', this.apiAddressService.getBusinessUserSOASections(),
            (response) => {
                callback(response);
            }
        );
    }
    
    // api: soa setting
    getBusinessSOAReplacementItems(defSectionId: number | null, callback: (data: SOAReplacementItem[]) => void) {
        this.apiService.callApi<SOAReplacementItem[]>(
            '', this.apiAddressService.getBusinessSOAReplacementItems(defSectionId),
            (response) => {
                callback(response);
            }
        );
    }
    
    // api: soa setting
    updateBusinessUserSOASectionsOrder(soaSections: SOASection[], callback: (data: SOASection[]) => void) {
        this.apiService.callApi<SOASection[]>(
            soaSections, this.apiAddressService.updateBusinessUserSOASectionsOrder(),
            (response) => {
                callback(response);
            }
        );
    }
    
    // api: soa setting
    resetBusinessUserSOASections(callback: (data: SOASection[]) => void) {
        this.apiService.callApi<SOASection[]>(
            '', this.apiAddressService.resetBusinessUserSOASections(),
            (response) => {
                callback(response);
            }
        );
    }
    
    // api: soa setting
    updateBusinessUserIsUsingGroupSoa(useOwnTheme: boolean, callback: (data: UserUseInternalGroupThemeSetting) => void) {
        this.apiService.callApi<UserUseInternalGroupThemeSetting>(
            '', this.apiAddressService.updateBusinessUserIsUsingGroupSoa(useOwnTheme),
            (response) => {
                callback(response);
            }
        )
    }

    // api: soa setting
    getBusinessUserSOASection(userOwnSectionId, defSectionId, callback: (data: SOASection) => void) {
        this.apiService.callApi<SOASection>(
            '', this.apiAddressService.getBusinessUserSOASection(userOwnSectionId, defSectionId),
            (response) => {
                callback(response);
            }
        );
    }
    
    // api: soa setting
    createOrUpdateBusinessUserSOASection(soaSection: SOASection, callback: (data: SOASection) => void) {
        this.apiService.callApi<SOASection>(
            soaSection, this.apiAddressService.createOrUpdateBusinessUserSOASection(),
            (response) => {
                callback(response);
            }
        );
    }

    // api: soa setting    
    removeBusinessSoaCoverPageImage(callback: (data: GeneralResponseMessage) => void) {
        this.apiService.callApi<GeneralResponseMessage>(
            '', this.apiAddressService.removeBusinessSoaReportCoverPageImageUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    getUserQmSetting(callback: (data: UserQmSettingModel) => void) {
        this.apiService.callApi<UserQmSettingModel>(
            '', this.apiAddressService.getUserQmSettingUrl(),
            (response) => {
                callback(response);
            }
        );
    }
    
    // api: NZBN
    getNZBNSearchResults(businessName: string, callback: (data: NZBNData) => void) {
        this.apiService.callApi<NZBNData>(
            '', this.apiAddressService.getNZBNSearchResultsUrl(businessName),
            (response) => {
                callback(response);
            }
        );
    }
    
    getNZBNDetails(nzbn: string, callback: (data: NZBNItemDetail) => void) {
        this.apiService.callApi<NZBNItemDetail>(
            '', this.apiAddressService.getNZBNDetailsUrl(nzbn),
            (response) => {
                callback(response);
            }
        );
    }
  
    getNZBNAiSummary(companyName: string, website: string, callback: (data: GeneralResponseMessage) => void) {
        this.apiService.callApi<GeneralResponseMessage>(
            '', this.apiAddressService.getNZBNAiSummaryUrl(companyName, website),
            (response) => {
                callback(response);
            }
        );
    }
    
    // session storage: CurrentBusinessRecord
    getCurrentBusinessRecord() : SavedBusinessRecord{        
        let data:SavedBusinessRecord | null = null;
        // 1. get from local storage
        let jsonData =  sessionStorage.getItem(this.currentBusinessInsuranceRecordKey);
        if(jsonData != null && jsonData != ''){
            data = JSON.parse(jsonData);
        }            
        // 2. if do not have anydata then return a new object
        if(jsonData == null) data = new SavedBusinessRecord();
        return data;
    }

    // session storage: CurrentBusinessRecord
    saveCurrentBusinessRecord(data: SavedBusinessRecord){
        sessionStorage.setItem(this.currentBusinessInsuranceRecordKey, JSON.stringify(data));
    }
    
    // session storage: CurrentBusinessRecord
    removeCurrentBusinessRecord(){
        sessionStorage.removeItem(this.currentBusinessInsuranceRecordKey);
    }
    
    // session storage: CurrentBusinessQuote
    getCurrentBusinessQuote() : BusinessCurrentQuote{        
        let data:BusinessCurrentQuote | null = null;
        // 1. get from local storage
        let jsonData =  sessionStorage.getItem(this.currentBusinessInsuranceQuoteKey);
        if(jsonData != null && jsonData != ''){
            data = JSON.parse(jsonData);
        }            
        // 2. if do not have anydata then return a new object
        if(jsonData == null) data = new BusinessCurrentQuote();
        return data;
    }

    // session storage: CurrentBusinessQuote
    saveCurrentBusinessQuote(data: BusinessCurrentQuote){
        sessionStorage.setItem(this.currentBusinessInsuranceQuoteKey, JSON.stringify(data));
    }
    
    // session storage: CurrentBusinessQuote
    removeCurrentBusinessQuote(){
        sessionStorage.removeItem(this.currentBusinessInsuranceQuoteKey);
    }

}