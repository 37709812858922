<div mat-dialog-title class="qm-bg-catskillWhite px-4 py-3 mx-n4 mt-n4">
  <h4 class="my-2">Prospective KiwiSaver Fund</h4>
</div>
<div mat-dialog-content>
  <!-- current fund -->
  <ng-container *ngIf="currentFund && currentFund.Id">
    <h5>Current KiwiSaver Fund</h5>
    <div class='table-responsive'>
      <table class="table table-bordered">
        <tr class='qm-bg-disable'>
          <th class='align-middle text-left qm-text-sm'>
            Scheme & Fund Name
          </th>
          <th class='align-middle text-left qm-text-sm'>
            Past Year Return Net (%)
          </th>
          <th class='align-middle text-left qm-text-sm'>
            Average Five Year Return Net (%)
          </th>
          <th class='align-middle text-left qm-text-sm'>
            Risk Reward Indicator
          </th>
          <th class='align-middle text-left qm-text-sm'>
            Fund Type
          </th>
          <th class='align-middle text-left qm-text-sm'>
            Fund Total Value
          </th>
          <th class='align-middle text-left qm-text-sm'>
            Number of Investors
          </th>
          <th class='align-middle text-left qm-text-sm'>
            Average Fee (%)
          </th>
          <th class='align-middle text-left qm-text-sm'>
            Fund Start Date
          </th>
        </tr>
  
        <tbody>
          <td class='align-middle qm-text-sm'>{{currentFund.SchemeName}} - {{currentFund.FundName}}</td>
          <td class='align-middle text-right qm-text-sm'>{{currentFund.PastYearReturnNet| number:'1.2-4'}}%</td>
          <td class='align-middle text-right qm-text-sm'>{{currentFund.AverageFiveYearReturnNet| number:'1.2-4'}}%</td>
          <td class='align-middle text-right qm-text-sm'>{{currentFund.RiskRewardIndicatorCode}}</td>
          <td class='align-middle text-right qm-text-sm'>
            {{updateFundType(currentFund)?updateFundType(currentFund):sharedFunction.getDisplayName("KiwiMonsterFundStatusInRetirementList",currentFund.QmRiskProfileCode)}}</td>
          <td class='align-middle text-right qm-text-sm'>${{currentFund.FundTotalValue | number: '1.0-0'}}</td>
          <td class='align-middle text-right qm-text-sm'>{{currentFund.NumberOfInvestors | number: '1.0-0'}}</td>
          <td class='align-middle text-right qm-text-sm'>{{currentFund.QmCalcFeeInPercentage| number:'1.2-4'}}%</td>
          <td class='align-middle text-center qm-text-sm'>{{currentFund.FundStartDate | date:'dd/MM/y'}}</td>
        </tbody>
      </table>
    </div>
  </ng-container>

    
  <div class='d-flex justify-content-between mb-3'>
    <!-- fundType search -->
    <div class='d-flex'>
      <h5 class='mb-0 line-height-36px'>Compare</h5>
      <button mat-button [disableRipple]="true" class='text-pumpkin qm-text-lg font-weight-bolder mx-1'
              [matMenuTriggerFor]="fundTypes">
        {{sharedFunction.getDisplayName("KiwiMonsterFundStatusInRetirementList",selectedFundType)}}
        <span class="material-icons">arrow_drop_down</span>
      </button>
      <mat-menu #fundTypes="matMenu">
        <ng-container *ngFor="let fundType of sysConfig.requiredData.KiwiMonsterFundStatusInRetirementList">
          <button mat-menu-item (click)='selectedFundType = fundType.Value; onFundTypeChange()'>{{fundType.Name}}</button>
        </ng-container>
      </mat-menu>

      <h5 class='mb-0 line-height-36px'>Funds</h5>
    </div>

    <!-- fund to open -->
    <div class='line-height-36px'>
      <mat-checkbox class='mb-0' [(ngModel)]="fundToOpen" (change)='fundToOpenFilter()'>
        Open Funds Only
      </mat-checkbox>
    </div>
  </div>

  <!-- search -->
  <div class='input-group mb-3'>
    <div class="input-group-prepend">
      <span class="input-group-text">
        <span class="material-icons" aria-hidden="false" aria-label="search icon">search</span>
      </span>
    </div>
    <input class="form-control" placeholder="Search" aria-label="Search"
           (keyup)="textFilter($event.target.value)" appAutoSelect>
  </div>

  <!-- result table -->
  <div class='table-responsive prospective-funds-table' *ngIf="!isLoadingData && !errorMessage">
    <table mat-table [dataSource]="dataSource" matSort class="table table-striped table-bordered table-info">
      <!-- Scheme Name Column -->
      <ng-container matColumnDef="schemeName">
        <th *matHeaderCellDef class='align-middle text-left'>Scheme & Fund Name</th>
        <td *matCellDef="let fund">
          <mat-checkbox class='w-100' [(ngModel)]="fund.IsSelectedAsProspective"
                        [disabled]='exceedsThreeFunds && !fund.IsSelectedAsProspective'
                        (change)='toggleSelectedFund(fund)'>
            {{fund.SchemeName}} - {{fund.FundName}}
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Past Year Return Column -->
      <ng-container matColumnDef="PastYearReturnNet">
        <th *matHeaderCellDef mat-sort-header class='align-middle text-left'>Past Year Return Net (%)</th>
        <td *matCellDef="let fund" class='text-right'>{{ fund.PastYearReturnNet === 0 ? 'N/A' : (fund.PastYearReturnNet | number:'1.2-4') + '%' }}</td>
      </ng-container>

      <!-- Average Five Year Return Column -->
      <ng-container matColumnDef="AverageFiveYearReturnNet">
        <th *matHeaderCellDef mat-sort-header class='align-middle text-left'>Average Five Year Return Net (%)</th>
        <td *matCellDef="let fund" class='text-right'>{{ fund.AverageFiveYearReturnNet === 0 ? 'N/A' : (fund.AverageFiveYearReturnNet | number:'1.2-4') + '%' }}</td>
      </ng-container>

      <!-- Risk Reward Indicator Column -->
      <ng-container matColumnDef="RiskRewardIndicatorCode">
        <th *matHeaderCellDef mat-sort-header class='align-middle text-left'>Risk Reward Indicator</th>
        <td *matCellDef="let fund" class='text-right'>{{fund.RiskRewardIndicatorCode}}</td>
      </ng-container>

      <!-- Fund Type Column -->
      <ng-container matColumnDef="QmRiskProfileCode">
        <th *matHeaderCellDef mat-sort-header class='align-middle text-left'>Fund Type</th>
        <td *matCellDef="let fund" class='text-center'>
          {{updateFundType(fund)?updateFundType(fund):sharedFunction.getDisplayName("KiwiMonsterFundStatusInRetirementList",fund.QmRiskProfileCode)}}
        </td>
      </ng-container>

      <!-- Fund Total Value Column -->
      <ng-container matColumnDef="FundTotalValue">
        <th *matHeaderCellDef mat-sort-header class='align-middle text-left'>Fund Total Value</th>
        <td *matCellDef="let fund" class='text-right'>${{fund.FundTotalValue | number: '1.0-0'}}</td>
      </ng-container>

      <!-- Number of Investors Column -->
      <ng-container matColumnDef="NumberOfInvestors">
        <th *matHeaderCellDef mat-sort-header class='align-middle text-left'>Number of Investors</th>
        <td *matCellDef="let fund" class='text-right'>{{fund.NumberOfInvestors | number: '1.0-0'}}</td>
      </ng-container>

      <!-- Average Fee Column -->
      <ng-container matColumnDef="QmCalcFeeInPercentage">
        <th *matHeaderCellDef mat-sort-header class='align-middle text-left'>Average Fee (%)</th>
        <td *matCellDef="let fund" class='text-right'>{{fund.QmCalcFeeInPercentage | number:'1.2-4'}}%</td>
      </ng-container>

      <!-- Fund Start Date Column -->
      <ng-container matColumnDef="FundStartDate">
        <th *matHeaderCellDef mat-sort-header class='align-middle text-left'>Fund Start Date</th>
        <td *matCellDef="let fund" class='text-center'>{{fund.FundStartDate | date:'dd/MM/y'}}</td>
      </ng-container>

      <!-- Fund Status Column -->
      <ng-container matColumnDef="FundStatus">
        <th *matHeaderCellDef mat-sort-header class='align-middle text-left'>Fund Status</th>
        <td *matCellDef="let fund">{{fund.FundStatus}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class='table-info'></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div *ngIf="dataSource.filteredData.length === 0" class="alert alert-danger text-center">
      {{sharedFunction.getUiMessageByCode('Share-ERROR-NoResults')}}
    </div>

    <!-- paginator -->
    <mat-paginator></mat-paginator>
  </div>

  <!-- loading data -->
  <div class='col text-center min-height-600px' *ngIf="isLoadingData">
    <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
  </div>

  <!-- error message -->
  <div class='alert alert-danger text-center' *ngIf="errorMessage">
    {{errorMessage}}
  </div>
</div>

<!-- action buttons -->
<div mat-dialog-actions class='row'>
  <div class='col-8'>
    <div *ngIf="exceedsThreeFunds">
      <h6 class='text-danger'>
        <span class="material-icons align-top">error</span>&nbsp;&nbsp;
        {{sharedFunction.getUiMessageByCode('KiwiMonsterFundsDialog-ERROR-ExceedsThreeFunds')}}
      </h6>
    </div>
    <ng-container *ngIf="selectedFunds.length > 0">
      <h6 class='mb-0 text-left text-pumpkin'> Selected: </h6>
      <h6 class='mb-0 text-left text-pumpkin qm-text' *ngFor="let pFund of selectedFunds">
        {{pFund.SchemeName}} - {{pFund.FundName}} <button mat-icon-button class='qm-icon-btn-xsm scale-80 align-middle'
                (click)='unselectedFund(pFund)'
                title='Delete' aria-label="delete" type="button">
          <span class="material-icons">delete_forever</span>
        </button>
      </h6>
    </ng-container>
  </div>
  <div class='col-4 text-right'>
    <button mat-flat-button color="qm-blue" (click)='closeSave()'>Save & Close</button>
  </div>
</div>