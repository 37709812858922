<div class="pricing-page">
  <div class="container px-0 py-block" [ngClass]="{'pt-0': isLogin}">
    <!-- header section -->
    <div class="header-section mb-5 text-center">
      <h1 class="mb-3">Pricing plans to suit you</h1>
      <h4 class="mb-5 description-text mx-auto">Every adviser user needs their own licence to get the most out of the
        Researchmonster and Advicemonster services.</h4>
    </div>
    <mat-tab-group class='pricing-mat-tab-group' animationDuration="0ms" mat-align-tabs="center" [disableRipple]="true"
                   [selectedIndex]="isKiwiMonsterDomain ? 0 : 1" appRealignInkBar>
      <mat-tab>
        <ng-template mat-tab-label>
          <img class='h-60px' src="../../../../assets/images/qm-logos/Kiwimonster-Logo-Color.png"
               alt="kiwimonster">
        </ng-template>
        <ng-template *ngTemplateOutlet="kiwimonsterPricing"></ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <img class='h-60px' src="../../../../assets/images/qm-logos/Quotemonster-Logo-Colour.png"
               alt="quotemonster">
        </ng-template>
        <ng-template *ngTemplateOutlet="quotemonsterPricing"></ng-template>
        <ng-template *ngTemplateOutlet="groupDiscount"></ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>

<!-- template -->
<ng-template #quotemonsterPricing>
  <div class='text-center my-5'>
    <button color="qm-lightGray" class="p-1 border rounded-pill qm-bg-lightGray switch-btn" type="button"
            (click)="isPayMonthly=!isPayMonthly">
      <span class="pl-4 pt-2 float-left" [ngClass]="isPayMonthly?'text-white':'text-darkGray'">Monthly</span>
      <span class="pr-4 pt-2 float-right" [ngClass]="isPayMonthly?'text-darkGray':'text-white'">Annual</span>
      <div class="h-100 rounded-pill qm-bg-darkGray selected-bg" [ngClass]="{'ml-auto': !isPayMonthly}"></div>
    </button>
  </div>

  <div class="alert alert-danger" role="alert" *ngIf="displayPricingChangeMessage()">
    <h5 class='text-danger'>Important: Upcoming Pricing Changes</h5>
    Please note that pricing for our services will be changing from 15 June. You can find more on the changes at this <a
       target='_blank' href="https://blog.quotemonster.co.nz/2023/05/09/price-changes-june-2023/">link</a>.
  </div>

  <!-- plans section -->
  <div class="row align-items-stretch justify-content-center py-5">
    <!-- Quotemonster -->
    <div class="col-12 col-md-8 col-lg-4 mb-5 mb-lg-0">
      <div class="d-flex flex-column h-100">
        <div class="px-4 pt-5 pb-4 text-center qm-bg-lightGray plan-option-header">
          <img class="mb-4" width="205" height="27" src="../../../assets/images/pricing/Quotemonster@2x.png?v=2"
               alt="Quotemonster">
          <h2 class="text-blue mb-2 plan-option-header-price">Free<sup
                 class="text-black qm-text-sm plan-option-header-sup">(1)</sup></h2>
          <h5 class="mb-4 d-none d-lg-block"><br></h5>
          <p class="mb-0 qm-text-sm qm-black font-weight-bold">
            A free pricing schedule that provides a comparison
            of the Major Life Insurance companies
          </p>
        </div>
        <div class="flex-grow-1 d-flex flex-column justify-content-between px-4 pt-4 pb-5 qm-bg-whiteLilac">
          <mat-list class="text-left mb-5">
            <p class="d-none d-lg-block mb-0 pb-2 qm-text-mid text-blue border-bottom plan-option-sub-text">
              <br><br>
            </p>
            <mat-list-item>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="checked icon">
                <span class="pl-2 qm-text-mid">Market Pricing Schedule</span>
              </div>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="">
                <span class="pl-2 qm-text-mid">Archive Policy Documents</span>
              </div>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="checked icon">
                <span class="pl-2 qm-text-mid">Pricing for Banks</span>
              </div>
            </mat-list-item>
          </mat-list>

          <a *ngIf="!isLogin" mat-flat-button color="qm-pumpkin" class='qm-flat-btn-lg' [routerLink]="['/login']"
             (click)='loginService.doGoogleTracking("pricing", "go to login", "");'>
            Get Started
          </a>
          <a mat-flat-button color='qm-black' class='mt-2 qm-flat-btn-lg w-100'
             [routerLink]="['/research/subscription/existing-licenses']"
             (click)='loginService.doGoogleTracking("pricing", "goExistingLicense", "");'
             *ngIf="displayMyCurrentPlanBtn('FREE')">
            My Plan
          </a>
        </div>
      </div>
    </div>
    <!-- Quotemonster end-->

    <!-- Researchmonster -->
    <div class="col-12 col-md-8 col-lg-4 mb-5 mb-lg-0">
      <div class="d-flex flex-column h-100">
        <div class="px-4 pt-5 pb-4 text-center qm-bg-lightGray plan-option-header">
          <img class="mb-4" width="282" height="27" src="../../../assets/images/pricing/Researchmonster@2x.png?v=2"
               alt="Researchmonster">
          <ng-container *ngIf='getLicense("QM").Price > 0; else noQMPrice'>
            <h2 class="text-blue mb-2 text-nowrap plan-option-header-price">
              {{getLicense("QM").Price | currency:'NZD':'symbol-narrow':'1.2-2'}}
              <sup *ngIf='isPayMonthly' class="text-black qm-text-sm plan-option-header-sup">(2)</sup>
              <sup *ngIf='!isPayMonthly' class="text-black qm-text-sm plan-option-header-sup">(2)</sup>
            </h2>
            <h5 class="mb-4" *ngIf='isPayMonthly'>+GST per month</h5>
            <h5 class="mb-4" *ngIf='!isPayMonthly'>+GST per year</h5>
          </ng-container>
          <ng-template #noQMPrice>
            <h2 class="text-blue mb-2 d-none d-lg-block">&nbsp;</h2>
            <h5 class="mb-4 d-none d-lg-block">&nbsp;</h5>
          </ng-template>
          <p class="mb-0 qm-text-sm qm-black font-weight-bold">
            An in-depth comparison report that provides market
            pricing and
            research from Quality Product Research Limited
          </p>
        </div>
        <div class="flex-grow-1 d-flex flex-column justify-content-between px-4 pt-4 pb-5 qm-bg-whiteLilac">
          <mat-list class="text-left mb-5">
            <p class="mb-0 pb-2 qm-text-mid text-blue border-bottom plan-option-sub-text">
              Everything from Quote Monster plus:
            </p>
            <mat-list-item>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="checked icon">
                <span class="pl-2 qm-text-mid">Market Pricing Graph</span>
              </div>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="checked icon">
                <span class="pl-2 qm-text-mid">Star Ratings</span>
              </div>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="checked icon">
                <span class="pl-2 qm-text-mid">Personalised Research
                  Report</span>
              </div>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="checked icon">
                <span class="pl-2 qm-text-mid">Head to Head Research
                  Report</span>
              </div>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="checked icon">
                <span class="pl-2 qm-text-mid">Policy Wording per item</span>
              </div>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="checked icon">
                <span class="pl-2 qm-text-mid">10 Year Pricing
                  Projection</span>
              </div>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="checked icon">
                <span class="pl-2 qm-text-mid">Underwriting Requirements (non
                  medical)</span>
              </div>
            </mat-list-item>
          </mat-list>
          <ng-container *ngIf="!isLogin">
            <a mat-flat-button color="qm-black" class='qm-flat-btn-lg' [routerLink]="['/signup']"
               (click)='loginService.doGoogleTracking("pricing", "go to sign up", "");'>Sign Up Now</a>
          </ng-container>
          <ng-container *ngIf="isLogin">
            <div>
              <a *ngIf='displayBuyNowBtn("QM")' mat-flat-button color="qm-pumpkin" class='qm-flat-btn-lg w-100'
                 [routerLink]="['/research/subscription/order', getLicense('QM').Id]"
                 (click)='loginService.doGoogleTracking("pricing", "go to order payment", "");'>
                Buy Now
              </a>

              <a mat-flat-button color='qm-black' class='mt-2 qm-flat-btn-lg w-100'
                 [routerLink]="['/research/subscription/existing-licenses']"
                 (click)='loginService.doGoogleTracking("pricing", "goExistingLicense", "");'
                 *ngIf="displayMyCurrentPlanBtn('QM')">
                My Plan
              </a>

              <a mat-flat-button color='qm-black' class='mt-2 qm-flat-btn-lg w-100'
                 [routerLink]="['/research/subscription/existing-licenses']"
                 (click)='loginService.doGoogleTracking("pricing", "goExistingLicense", "");'
                 *ngIf="displayInFreeTrial('QMFREE')">
                In Trial
              </a>

              <ng-container *ngIf='displayFreeTrialBtn("QMFREE")'>
                <a mat-flat-button color="qm-blue" class="mt-2 qm-flat-btn-lg w-100 text-wrap"
                   [routerLink]="['/research/subscription/order', getLicense('QMFREE').Id]"
                   (click)='loginService.doGoogleTracking("pricing", "go to order payment", "");'>
                  {{getLicense('QMFREE').Name}}
                  <span class='d-block qm-text-xsm'>
                    ({{getLicense('QMFREE').Description}})
                  </span>
                </a>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- Researchmonster end-->

    <!-- Advicemonster -->
    <div class="col-12 col-md-8 col-lg-4 mb-5 mb-lg-0">
      <div class="d-flex flex-column h-100">
        <div class="px-4 pt-5 pb-4 text-center qm-bg-lightGray plan-option-header">
          <img class="mb-4" width="246" height="27" src="../../../assets/images/pricing/Advicemonster@2x.png"
               alt="Advicemonster">
          <ng-container *ngIf='getLicense("AM").Price > 0; else noAMPrice'>
            <h2 class="text-blue mb-2 text-nowrap plan-option-header-price">
              {{getLicense("AM").Price | currency:'NZD':'symbol-narrow':'1.2-2'}}
              <sup *ngIf='isPayMonthly' class="text-black qm-text-sm plan-option-header-sup">(2)</sup>
              <sup *ngIf='!isPayMonthly' class="text-black qm-text-sm plan-option-header-sup">(2)</sup>
            </h2>
            <h5 class="mb-4" *ngIf='isPayMonthly'>+GST per month</h5>
            <h5 class="mb-4" *ngIf='!isPayMonthly'>+GST per year</h5>
          </ng-container>
          <ng-template #noAMPrice>
            <h2 class="text-blue mb-2 d-none d-lg-block">&nbsp;</h2>
            <h5 class="mb-4 d-none d-lg-block">&nbsp;</h5>
          </ng-template>
          <p class="mb-0 qm-text-sm qm-black font-weight-bold">
            Provides access to the Needs Analysis tools and
            produces a full
            Statement of Advice in Microsoft word
          </p>
        </div>
        <div class="flex-grow-1 d-flex flex-column justify-content-between px-4 pt-4 pb-5 qm-bg-whiteLilac">
          <mat-list class="text-left mb-5">
            <p class="mb-0 pb-2 qm-text-mid text-blue border-bottom plan-option-sub-text">
              Everything from Research Monster plus:
            </p>
            <mat-list-item>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="checked icon">
                <span class="pl-2 qm-text-mid">Needs Analysis Tools</span>
              </div>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="checked icon">
                <span class="pl-2 qm-text-mid">Automation within a framework to save you time</span>
              </div>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="checked icon">
                <span class="pl-2 qm-text-mid">High levels of personalisation to each individual client</span>
              </div>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="checked icon">
                <span class="pl-2 qm-text-mid">A new level in configuration so your SOA looks like you produced it - not
                  identical
                  to hundreds of other advisers</span>
              </div>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="checked icon">
                <span class="pl-2 qm-text-mid">Integration with your quote and research
                </span>
              </div>
            </mat-list-item>
          </mat-list>
          <div class='mb-2'>
            <p class='font-italic qm-text-xsm mb-8px'>
              This <a class='text-blue' href="https://youtu.be/zPYxac6sJ_M" target="_blank" title='video'>video</a>,
              shows you how easy it is to use by one of our Advisers.
            </p>
            <p class='font-italic qm-text-xsm mb-8px'>
              If you want to discuss further, or would like access to a free trial, please let us know.
            </p>
            <p class='font-italic qm-text-xsm mb-8px'>
              If you want a sample SOA, email us at <a class='text-blue'
                 href='mailto:info@quotemonster.co.nz'>info@quotemonster.co.nz</a>
            </p>
          </div>


          <ng-container *ngIf="!isLogin">
            <a mat-flat-button color="qm-black" class='qm-flat-btn-lg' [routerLink]="['/signup']"
               (click)='loginService.doGoogleTracking("pricing", "go to sign up", "");'>Sign Up Now</a>
          </ng-container>
          <ng-container *ngIf="isLogin">
            <div>
              <a *ngIf='displayBuyNowBtn("AM")' mat-flat-button color="qm-pumpkin" class='qm-flat-btn-lg w-100'
                 [routerLink]="['/research/subscription/order', getLicense('AM').Id]"
                 (click)='loginService.doGoogleTracking("pricing", "go to order payment", "");'>
                Buy Now
              </a>

              <a mat-flat-button color='qm-black' class='mt-2 qm-flat-btn-lg w-100'
                 [routerLink]="['/research/subscription/existing-licenses']"
                 (click)='loginService.doGoogleTracking("pricing", "goExistingLicense", "");'
                 *ngIf="displayMyCurrentPlanBtn('AM')">
                My Plan
              </a>

              <a mat-flat-button color='qm-black' class='mt-2 qm-flat-btn-lg w-100'
                 [routerLink]="['/research/subscription/existing-licenses']"
                 (click)='loginService.doGoogleTracking("pricing", "goExistingLicense", "");'
                 *ngIf="displayInFreeTrial('AMFREE')">
                In Trial
              </a>

              <ng-container *ngIf='displayFreeTrialBtn("AMFREE")'>
                <a mat-flat-button color="qm-blue" class="mt-2 qm-flat-btn-lg w-100 text-wrap"
                   [routerLink]="['/research/subscription/order', getLicense('AMFREE').Id]"
                   (click)='loginService.doGoogleTracking("pricing", "go to order payment", "");'>
                  {{getLicense('AMFREE').Name}}
                  <span class='d-block qm-text-xsm'>
                    ({{getLicense('AMFREE').Description}})
                  </span>
                </a>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- Advicemonster end -->
  </div>
  <!-- plans section end -->
</ng-template>

<ng-template #kiwimonsterPricing>
  <!-- plans section -->
  <div class="row align-items-stretch justify-content-center py-5">
    <!-- kiwimonster -->
    <div class="col-12 col-md-8 col-lg-4 mb-5 mb-lg-0 mx-5">
      <div class="d-flex flex-column h-100">
        <div class="px-4 pt-5 pb-4 text-center qm-bg-lightGray km-plan-option-header">
          <div class='km-plan-option-title'>
            <img class="mb-4" width='164' height="27"
                 src="../../../assets/images/pricing/Kiwimonster-Text-Color.png?v=2"
                 alt="kiwimonster">
          </div>
          <h2 class="text-blue mb-2 plan-option-header-price">zero, zip, zilch, nada</h2>
          <!-- <h2 class="text-blue mb-2 plan-option-header-price">zero, zip, zilch, nada<sup
                 class="text-black qm-text-sm km-plan-option-header-sup">(1)</sup></h2> -->
        </div>
        <div class="flex-grow-1 d-flex flex-column justify-content-between px-4 pt-4 pb-5 qm-bg-whiteLilac">
          <mat-list class="text-left mb-5">
            <p class="d-none d-lg-block mb-0 pb-2 qm-text-mid text-blue border-bottom plan-option-sub-text">
              <br><br>
            </p>
            <mat-list-item>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="checked icon">
                <span class="pl-2 qm-text-mid">Determine client's Risk Profile</span>
              </div>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="">
                <span class="pl-2 qm-text-mid">Find the right KiwiSaver fund</span>
              </div>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="checked icon">
                <span class="pl-2 qm-text-mid">Estimate KiwiSaver balance</span>
              </div>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="checked icon">
                <span class="pl-2 qm-text-mid">Estimate available income at retirement</span>
              </div>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="checked icon">
                <span class="pl-2 qm-text-mid">Compare a fund with up to three other funds</span>
              </div>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="checked icon">
                <span class="pl-2 qm-text-mid">Compare fees</span>
              </div>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="checked icon">
                <span class="pl-2 qm-text-mid">Compare returns - updated daily</span>
              </div>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="checked icon">
                <span class="pl-2 qm-text-mid">Avoid funds with up to 16 different ESG exclusion items</span>
              </div>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="checked icon">
                <span class="pl-2 qm-text-mid">Access to Fund details & information</span>
              </div>
            </mat-list-item>
          </mat-list>
          <a *ngIf="!isLogin" mat-flat-button color="qm-pumpkin" class='qm-flat-btn-lg' [routerLink]="['/login']"
             (click)='loginService.doGoogleTracking("pricing", "go to login", "");'>
            Get Started
          </a>
        </div>
      </div>
    </div>
    <!-- kiwimonster end-->

    <!-- kiwimonster with research -->
    <div class="col-12 col-md-8 col-lg-4 mb-5 mb-lg-0 mx-5">
      <div class="d-flex flex-column h-100">
        <div class="px-4 pt-5 pb-4 text-center qm-bg-lightGray km-plan-option-header">
          <div class='km-plan-option-title'>
            <img width='164' height="27" src="../../../assets/images/pricing/Kiwimonster-Text-Color.png?v=2"
                 alt="kiwimonster research">
            <div class='mt-2 mb-4'>
              <span>with</span>
              <h3>Research</h3>
            </div>
          </div>

          <h2 class="text-blue mb-2 text-nowrap plan-option-header-price">
            Coming soon
          </h2>
          <!-- <h2 class="text-blue mb-2 text-nowrap plan-option-header-price">
            Coming soon<sup
                 class="text-black qm-text-sm plan-option-header-sup">(2)</sup>
          </h2> -->
        </div>
        <div class="flex-grow-1 d-flex flex-column justify-content-between px-4 pt-4 pb-5 qm-bg-whiteLilac">
          <mat-list class="text-left mb-5">
            <p class="mb-0 pb-2 qm-text-mid text-blue border-bottom plan-option-sub-text">
              Everything from Kiwimonster, plus:
            </p>
            <mat-list-item>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="checked icon">
                <span class="pl-2 qm-text-mid">In depth Research - with options you can control</span>
              </div>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="checked icon">
                <span class="pl-2 qm-text-mid">Personalised Research Report</span>
              </div>
              <div mat-line class="py-2 border-bottom">
                <img class="align-baseline" width="18" height="14" src="../../../assets/images/pricing/check@2x.png?v=2"
                     alt="checked icon">
                <span class="pl-2 qm-text-mid">Optional - produces a KiwiSaver Statement of Advice</span>
                <ul class='custom-list'>
                  <li class='font-italic'>Output into Microsoft Word</li>
                  <li class='font-italic'>Personalised to your client</li>
                  <li class='font-italic'>Integration with your research</li>
                  <li class='font-italic'>Use Artificial Intelligence (AI) to help draft content</li>
                </ul>
              </div>
            </mat-list-item>
          </mat-list>
          <div>
            <p class='font-italic qm-text-xsm'>
              If you want to discuss further, or would like access to a free trial, please let us know.
            </p>
            <ng-container *ngIf="!isLogin">
              <a mat-flat-button color="qm-black" class='qm-flat-btn-lg w-100' [routerLink]="['/signup']"
                 (click)='loginService.doGoogleTracking("pricing", "go to sign up", "");'>Sign Up Now</a>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <!-- kiwimonster with research end-->
  </div>
  <!-- plans section end -->
</ng-template>

<ng-template #groupDiscount>
  <!-- group discount section -->
  <div class="pt-5 group-discount-section">
    <div class="row mb-5 align-items-stretch">
      <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-0 mb-5 mb-lg-0">
        <div class="px-4 py-5 qm-bg-catskillWhite h-100">
          <h2 class="group-discount-heading">Research Group Discounts</h2>
          <p class="qm-text-sm">To be invoiced from one source</p>
          <ul class="list-group list-group-flush">
            <li
                class="list-group-item d-flex justify-content-between align-items-center qm-bg-none px-0 border-bottom-0">
              <h4 class="mb-0 mr-2">5+ users</h4>
              <h4 class="mb-0 text-blue">10% Discount</h4>
            </li>
            <li
                class="list-group-item d-flex justify-content-between align-items-center qm-bg-none px-0 border-bottom-0">
              <h4 class="mb-0 mr-2">15+ users</h4>
              <h4 class="mb-0 text-blue">20% Discount</h4>
            </li>
            <li
                class="list-group-item d-flex justify-content-between align-items-center qm-bg-none px-0 border-bottom-0">
              <h4 class="mb-0 mr-2">50+ users</h4>
              <h4 class="mb-0 text-blue">25% Discount</h4>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-center qm-bg-none px-0">
              <h4 class="mb-0 mr-2">100+ users</h4>
              <h4 class="mb-0 text-blue">30% Discount</h4>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-0">
        <div class="px-4 py-5 qm-bg-catskillWhite h-100">
          <h2 class="group-discount-heading">Admin or Compliance Licence <sup class="qm-text-mid">(3)</sup></h2>
          <p class="d-none d-md-block qm-text-sm">&nbsp;</p>
          <ul class="list-group list-group-flush">
            <li class="list-group-item qm-bg-none px-0 py-4 border-bottom-0">
              <h2 class="mb-0">{{monthlyPaPrice | currency:'NZD':'symbol-narrow':'1.0-0'}}<span
                      class="ml-2 qm-text-lg font-weight-bolder">Monthly (+GST)</span></h2>
            </li>
            <li class="list-group-item qm-bg-none px-0 py-4 border-bottom-0">
              <h2 class="mb-0">{{annualPaPrice | currency:'NZD':'symbol-narrow':'1.0-0'}}<span
                      class="ml-2 qm-text-lg font-weight-bolder">Annually (+GST)</span></h2>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <ol type="1" class="mb-4 pl-4 font-italic">
      <li>Free service for all registered users.</li>
      <li>Non-refundable.</li>
      <li>The Admin or Compliance login is additional to your Researchmonster or Advicemonster Subscription and allows
        your administrator to login to your account using their own username and password.
        They can then create or alter your quotes but are unable to amend your subscription details, user or security
        settings.
        Please note only one login is allowed per account.
      </li>
    </ol>

    <a mat-stroked-button color='qm-black' href='/TermsOfUse.html' rel="noopener" target='_blank'
       (click)='loginService.doGoogleTracking("pricing", "go to TermsOfUse", "");'>
      Full Terms of Use
    </a>
  </div>
  <!-- group discount section end -->
</ng-template>