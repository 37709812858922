import { Component, OnDestroy, OnInit, QueryList, ViewChildren, isDevMode } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { KiwiMonsterClient } from 'src/app/models/kiwimonster/kiwimonster-client.model';
import { KiwiMonsterQuote } from 'src/app/models/kiwimonster/kiwimonster-quote.model';
import { KiwiMonsterFund } from 'src/app/models/kiwimonster/kiwimonster-fund.model';
import { KiwiMonsterScheme } from 'src/app/models/kiwimonster/kiwimonster-scheme.model';
import { KiwiMonsterKiwiSaverService } from 'src/app/service/kiwimonster/kiwimonster-kiwisaver.service';
import { KiwiMonsterService } from 'src/app/service/kiwimonster/kiwimonster.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SysConfigService } from 'src/app/service/sys.config';
import { MatDialog } from '@angular/material/dialog';
import { KiwiMonsterFundsDialogComponent } from '../kiwimonster-funds-dialog/kiwimonster-funds-dialog.component';
import { KiwiMonsterExcludeFundsDialogComponent } from './kiwimonster-exclude-funds-dialog/kiwimonster-exclude-funds-dialog.component';
import { SelectItemStruct } from 'src/app/models/select.item';

@Component({
  selector: 'kiwimonster-kiwisaver',
  templateUrl: './kiwimonster-kiwisaver.component.html',
  styleUrls: ['./kiwimonster-kiwisaver.component.scss']
})
export class KiwiMonsterKiwiSaverComponent implements OnInit, OnDestroy {
  @ViewChildren('kiwiSaverSettingForm') kiwiSaverSettingForms: QueryList<NgForm>;
  errorMessages: string[] = [];

  currentKMQuote: KiwiMonsterQuote;
  currentClientIndex: number = 0;
  currentScheme: KiwiMonsterScheme;
  schemeList: KiwiMonsterScheme[] = [];
  currentFund: KiwiMonsterFund;
  fundList: KiwiMonsterFund[] = [];
  isGoNext: boolean = false;
  desiredRetirementAgeList: number[] = [];
  isDevMode: boolean = false;
  constructor (
    public loginService: LoginService,
    public sharedFunction: SharedFunctionService,
    public sysConfig: SysConfigService,
    private router: Router,
    private kiwiMonsterService: KiwiMonsterService,
    private kiwiMonsterKiwiSaverService: KiwiMonsterKiwiSaverService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.isDevMode = isDevMode();
    this.currentKMQuote = this.kiwiMonsterService.getCurrentKMQuote();
    this.currentKMQuote.CurrentStep = this.kiwiMonsterService.StepKiwiSaver;

    this.kiwiMonsterKiwiSaverService.createInitSettingObject(this.currentKMQuote.Clients);
    this.desiredRetirementAgeList = this.sharedFunction.generateNumberList(65, 120);

    this.kiwiMonsterKiwiSaverService.showDinoLoading();
    // 1. call getSchemes api
    this.kiwiMonsterKiwiSaverService.getSchemesObservable().subscribe((s) => {
      // 2. set schemeList
      this.schemeList = s;
      // 3. set client's fund by schemeName
      this.kiwiMonsterKiwiSaverService.setInitCurrentFundObservable(this.currentKMQuote.Clients).subscribe((c) => {
        this.kiwiMonsterService.setCurrentKMQuote(this.currentKMQuote);
        this.kiwiMonsterKiwiSaverService.closeDinoLoading();
      });
    });
  }


  ngOnDestroy() {
  }

  goNext(): void {
    this.isGoNext = true;
    if (this.isValidData()) {
      this.kiwiMonsterKiwiSaverService.showDinoLoading();
      // save more questions
      this.currentKMQuote.Clients.forEach(client => this.kiwiMonsterKiwiSaverService.saveMoreQuestions(client));
      // for old quote if have QmKSProspectiveProductFundId set to null
      this.currentKMQuote.Clients.forEach(client => client.Setting.QmKSProspectiveProductFundId = null);
      // call save api
      this.kiwiMonsterService.saveKiwiMonsterQuote(this.currentKMQuote, (response) => {
        this.kiwiMonsterKiwiSaverService.updateClientSettingId(this.currentKMQuote.Clients, response.Clients);
        this.kiwiMonsterService.setCurrentKMQuote(this.currentKMQuote);
        // no closeDinoLoading here as it will be closed in next component (after loading compare results)
        this.router.navigate(['/kiwimonster/quote/compare']);
        this.loginService.doGoogleTracking("kiwiMonsterQuote-kiwisaver", "goNext", "");
      });
    } else {
      this.addErrorMessages();
    }
    this.isGoNext = false;
  }

  onSaveAndClose(): void {
    if (this.isValidData()) {
      this.kiwiMonsterKiwiSaverService.showDinoLoading();
      // save more questions
      this.currentKMQuote.Clients.forEach(client => this.kiwiMonsterKiwiSaverService.saveMoreQuestions(client));
      // call save api
      this.kiwiMonsterService.saveKiwiMonsterQuote(this.currentKMQuote, (response) => {
        this.router.navigate(['/kiwimonster/dashboard']);
        this.loginService.doGoogleTracking("kiwiMonsterQuote-kiwisaver", "saveAndClose", "");
      });
    } else {
      // this.addErrorMessages();
      this.router.navigate(['/kiwimonster/dashboard']);
      this.loginService.doGoogleTracking("kiwiMonsterQuote-kiwisaver", "saveAndClose", "");
    }
  }

  goBack(): void {
    if (this.isValidData()) {
      this.kiwiMonsterKiwiSaverService.showDinoLoading();
      // save more questions
      this.currentKMQuote.Clients.forEach(client => this.kiwiMonsterKiwiSaverService.saveMoreQuestions(client));
      // call save api
      this.kiwiMonsterService.saveKiwiMonsterQuote(this.currentKMQuote, (response) => {
        this.kiwiMonsterKiwiSaverService.updateClientSettingId(this.currentKMQuote.Clients, response.Clients);
        this.kiwiMonsterService.setCurrentKMQuote(this.currentKMQuote);

        this.kiwiMonsterKiwiSaverService.closeDinoLoading();
        this.router.navigate(['/kiwimonster/quote/client']);
        this.loginService.doGoogleTracking("kiwiMonsterQuote-kiwisaver", "goBack", "");
      });
    } else {
      this.router.navigate(['/kiwimonster/quote/client']);
      this.loginService.doGoogleTracking("kiwiMonsterQuote-kiwisaver", "goBack", "");
    }
  }

  isValidData(): boolean {
    // Check if any form is invalid
    for (let form of this.kiwiSaverSettingForms) {
      if (form.invalid) {
        return false;
      }
    }

    for (let client of this.currentKMQuote.Clients) {
      if (client.Setting.AreYouNZTaxResident) {
        // Check currentScheme and currentFund
        if (client.Setting.DoYouHaveKiwiSaver && (!client.Setting.QmKSSchemeNumber || !client.Setting.CurrentSchemeFund.Id)) {
          return false;
        }
        // if goNext, check for has a prospective or not
        if (client.Setting.QmKSProspectiveProductFunds.length === 0 && this.isGoNext) {
          return false;
        }
        // check I contributes
        if (client.Setting.ContributeInPercentage === null || client.Setting.ContributeInPercentage < 0) {
          return false;
        }
        // check employer contributes
        if (client.Setting.EmployerContributesPer === null || client.Setting.EmployerContributesPer < 0) {
          return false;
        }
        
        // check YearlyGrossEarnings
        if (!client.Setting.YearlyGrossEarnings && !client.Setting.KiwiSaverBalance && !client.Setting.WeeklyTopUp) {
          return false;
        }
      }
    }
    
    // If all forms are valid and each client has selected a fund return true
    return true;
  }

  addErrorMessages(): void {
    this.errorMessages = [];

    this.kiwiSaverSettingForms.forEach((form, index) => {
      form.control.markAllAsTouched();
    });

    this.currentKMQuote.Clients.forEach((c, index) => {
      if (c.Setting.AreYouNZTaxResident) {
        // Check for currentScheme and currentFund, if goNext, check if has a prospective or not
        if (c.Setting.QmKSProspectiveProductFunds.length === 0 && this.isGoNext) {
          this.errorMessages.push(`Please select prospective funds for client ${ index + 1 }.`);
        }

        if (c.Setting.DoYouHaveKiwiSaver && !c.Setting.QmKSSchemeNumber) {
          this.errorMessages.push(`Please select a current scheme for client ${ index + 1 }.`);
        }

        if (c.Setting.DoYouHaveKiwiSaver && !c.Setting.CurrentSchemeFund.Id) {
          this.errorMessages.push(`Please select a current fund for client ${ index + 1 }.`);
        }
        
        if (c.Setting.ContributeInPercentage === null || c.Setting.ContributeInPercentage < 0) {
          this.errorMessages.push(`Please enter I Contribute (%) for client ${ index + 1 }.`);
        }

        if (c.Setting.EmployerContributesPer === null || c.Setting.EmployerContributesPer < 0) {
          this.errorMessages.push(`Please enter My Employer Contribute (%) for client ${ index + 1 }.`);
        }
        
        if (!c.Setting.YearlyGrossEarnings && !c.Setting.KiwiSaverBalance && !c.Setting.WeeklyTopUp) {
          this.errorMessages.push(`Client ${ index + 1 }: Please enter a value for at least one of the following: Yearly Gross Earnings, KiwiSaver Balance, or Weekly Top-ups, before proceeding to the next step.`);
        }
      }
    });
  }

  onValueChange(): void {
    this.errorMessages = [];
  }

  getClientTabName(client: KiwiMonsterClient): string {
    return KiwiMonsterClient.getClientFullName(client);
  }

  onCurrentSchemeValueChange(client: KiwiMonsterClient): void {
    this.kiwiMonsterKiwiSaverService.showDinoLoading();
    this.kiwiMonsterKiwiSaverService.getFundsBySchemeNumberObservable(client.Setting.QmKSSchemeNumber).subscribe((response) => {
      client.Setting.CurrentSchemeFunds = response;
      client.Setting.CurrentSchemeFund = response[0];
      this.onCurrentFundValueChange(client);
      this.kiwiMonsterKiwiSaverService.closeDinoLoading();
    });
  }

  onCurrentFundValueChange(client: KiwiMonsterClient): void {
    client.Setting.QmKSProductFundId = client.Setting.CurrentSchemeFund.Id;
    this.kiwiMonsterKiwiSaverService.removeSelectedFund(client);
    
  }

  doSelectProspectiveFunds(client: KiwiMonsterClient): void {
    this.errorMessages = [];
    client.Setting.HasFundSearchResults = true;

    let searchFundCode: string = this.kiwiMonsterKiwiSaverService.getProspectiveFundType(client.Setting.ProspectiveFundGoal, client.Setting.ProspectiveFundDuration, client.Setting.ProspectiveFundRisk);
    
    if (searchFundCode) {
      this.kiwiMonsterKiwiSaverService.showDinoLoading();
      // call api
      this.kiwiMonsterKiwiSaverService.getFundsByFundTypeObservable(searchFundCode).subscribe((response) => {
        if (response && response.length > 0) {
          let filteredFunds = this.kiwiMonsterKiwiSaverService.removeCurrentFundFromData(client.Setting.QmKSProductFundId, response);
          this.openProspectiveFundsDialog(filteredFunds, client);
        } else {
          client.Setting.HasFundSearchResults = false;
        }
        this.kiwiMonsterKiwiSaverService.closeDinoLoading();
      });
    }

  }


  openProspectiveFundsDialog(funds: KiwiMonsterFund[], client: KiwiMonsterClient): void {
    const dialogRef = this.dialog.open(KiwiMonsterFundsDialogComponent, {
      data: {Funds: funds, Client: client},
      width: '80%',
      disableClose: true,
      restoreFocus: false,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((response: KiwiMonsterFund[]) => {
      client.Setting.QmKSProspectiveProductFunds = response;
    });
  }
    
  calculatePIR(client: KiwiMonsterClient): void {
    client.Setting.PIRRate = this.kiwiMonsterKiwiSaverService.getPIRFromYearlyGrossEarnings(client.Setting.YearlyGrossEarnings);
  }
  
  setCurrentSchemeFund(doYouHaveKiwiSaver: boolean, client: KiwiMonsterClient): void{
    if (!doYouHaveKiwiSaver) {
      client.Setting.CurrentSchemeFund = null;
      client.Setting.QmKSProductFundId = null;
      client.Setting.QmKSSchemeNumber = null;
    }
  }
  
  openExcludeFundsDialog(client: KiwiMonsterClient): void {
   const dialogRef = this.dialog.open(KiwiMonsterExcludeFundsDialogComponent, {
      data: client,
      width: '800px',
      disableClose: true,
      restoreFocus: false,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((response: SelectItemStruct[]) => {
      client.Setting.MoreQuestions = response;
    });
  }
  
  getSearchFundTypeName(client): string {
    let searchFundTypeName: string = '';
    
    let searchFundCode = this.kiwiMonsterKiwiSaverService.getProspectiveFundType(client.Setting.ProspectiveFundGoal, client.Setting.ProspectiveFundDuration, client.Setting.ProspectiveFundRisk)
    
    searchFundTypeName = this.sharedFunction.getDisplayName("KiwiMonsterFundStatusInRetirementList",searchFundCode)
    return searchFundTypeName
  }
  
  limitDecimalPlaces(client: KiwiMonsterClient, value: string): void {
    if (value?.includes('.')) {
      client.Setting.EmployerContributesPer = parseFloat(parseFloat(value).toFixed(1));
    }
  }
  
  resetEmptyValue(client: KiwiMonsterClient, propertyName: string): void {
    if (client.Setting[propertyName] === null) {
      client.Setting[propertyName] = 0;
    }
  }
}
