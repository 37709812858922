import { Component, OnInit, isDevMode, AfterViewChecked, AfterViewInit } from '@angular/core';
import { DataLoader } from './service/data.loader';
import { LoginService } from './service/login.service';
import { ResizeService } from './service/screen.resize.service';
import { QuoteRequiredData } from './models/quote.required.data';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from './service/user.service';
import { ApiService } from './service/api.service';
import { Notice } from './models/notice';
import { SharedFunctionService } from './service/shared.function.service';
import { OauthService } from './service/third-party-companies/oauth.service';


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewChecked, AfterViewInit {
  title = "app";
  currentYear: number;
  loginedUserFullName: string;
  loadingData: boolean = true;

  isMobileDevice: boolean = false;

  isDevMode: boolean = false;
  copyrightYear: string = "";
  routerName: string = "";


  showSpecialHomeTakeOver: boolean = false;
  specialHomeTakeOverImageUrl: string =
    "https://quotemonster.co.nz/qmadb/nib/20191219/NIB0770A-Adviser-Facing-Home-Page-Takeover-vf.jpg";
  specialHomeTakeOverLinkUrl: string = "https://quotemonster.co.nz";
  specialHomeTakeOverTrackingName: string = "";
  specialHomeTakeOverLiveDate: Date = new Date("2099-01-01");
  specialHomeTakeOverImages: any[];
  mainBodyContainerWidth: string;


  quoteEngineVersion: string = '';
  qprDbVersion: string = '';

  systemNoticesList: Notice[] = [];
  hideSystemNotices: boolean = false;
  adBlocker: boolean = false;

  outsourcePASUrl: string = "";

  isOauth: boolean = false;
  
  hideRedirectMessage: boolean = false;
  constructor(
    private dataLoader: DataLoader,
    public loginService: LoginService,
    private screenResizeService: ResizeService,
    private router: Router,
    public dialog: MatDialog,
    private userService: UserService,
    private apiService: ApiService,
    private sharedFunction: SharedFunctionService,
    private publicAuthorizationService: OauthService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.loginService.trackScreenView(event.urlAfterRedirects);

        // router name like: 1 layer: home, 2 layer: public-about-overview
        this.routerName = event.urlAfterRedirects.slice(1).toLowerCase().replace(/\//g, '-');
        this.isOauth = this.publicAuthorizationService.isOauth(this.routerName);
        // send data to google recapapi
        (<any>window).googleRecaptchaApiCaller(this.routerName, null);

      }
    });
  }

  ngOnInit(): void {

    this.currentYear = new Date().getFullYear();
    // load user token
    this.loginService.initUserToken();

    // load system notices
    this.getSystemNoticesList();

    // load required data from server and local file.
    this.dataLoader.loadRequiredData(this);

    this.screenResizeService.onResize$.subscribe((size) => {
      this.reDrawScreen(size);
    });

    this.isDevMode = isDevMode();

    // set copyright information
    this.copyrightYear = new Date().getFullYear().toString();

    // load top qm license
    if (this.loginService.isLogin()) {
      this.userService.getLicense((response) => {
        this.apiService.setUserLicense(response);
      });
    }

    this.routerName = '';


    // remove all old click listener
    document.removeEventListener('click', () => { });
    // register click Listener
    document.addEventListener('click', (event) => {
      if ((<any>(event.target)).href) {
        // do tracking for all link
        if ((<any>(event.target)).href.indexOf('http') >= 0) {
          let trackingName = (<any>(event.target)).classList.value.replace('btn btn-link ', '');
          this.loginService.doGoogleTracking('link', trackingName, (<any>(event.target)).href);
        }
      } else if ((<any>(event.target)).closest('a')) {
         // do tracking for all link
        if ((<any>(event.target)).closest('a').href.indexOf('http') >= 0) {
          let trackingName = (<any>(event.target)).closest('a').classList.value.replace('btn btn-link ', '');
          this.loginService.doGoogleTracking('link', trackingName, (<any>(event.target)).closest('a').href);
        }
      }
    });
  }

  reDrawScreen(size: any) {
    // check device rotation.
    if (size.innerHeight > size.innerWidth) {
      this.isMobileDevice = true;
    }
  }

  onLoadingEnd() {
    this.loadingData = false;
    this.setSpecialHomePageTakeover();
    const quoteRequiredData: QuoteRequiredData = this.dataLoader.getRequiredData();
    this.quoteEngineVersion = quoteRequiredData.QeVersion;
    this.qprDbVersion = quoteRequiredData.QprVersion;
    this.outsourcePASUrl = quoteRequiredData.OutsourceProviderAssuranceStatement;
  }

  setSpecialHomePageTakeover() {
    let now = new Date();
    const v: QuoteRequiredData = this.dataLoader.getRequiredData();
    if (v.SpecialHomeTakeOverDate) {
      this.specialHomeTakeOverLiveDate = new Date(v.SpecialHomeTakeOverDate);

      if (now >= this.specialHomeTakeOverLiveDate) {
        this.showSpecialHomeTakeOver = true;
        this.specialHomeTakeOverImageUrl = v.SpecialHomeTakeOverImageUrl;
        this.specialHomeTakeOverLinkUrl = v.SpecialHomeTakeOverLinkUrl;
        this.specialHomeTakeOverTrackingName =
          v.SpecialHomeTakeOverTrackingName;
        this.specialHomeTakeOverImages = v.SpecialHomeTakeOverImages;
        this.mainBodyContainerWidth = v.MainBodyContainerWidth;
      }
    }
  }

  ngAfterViewChecked(): void {
    if (this.showSpecialHomeTakeOver === true) {
      if (this.specialHomeTakeOverImageUrl) {
        let ele = document.getElementById("homeTakeoverContainer");
        if (ele && ele.style) {
          ele.style.backgroundImage =
            "url(" + this.specialHomeTakeOverImageUrl + ")";
        }
      } else if (
        this.specialHomeTakeOverImages &&
        this.specialHomeTakeOverImages.length > 0
      ) {
        for (let ads of this.specialHomeTakeOverImages) {
          if (ads.Position === "BACKGROUND" && ads.ImageUrl) {
            let ele = document.getElementById("homeTakeoverContainer");
            if (ele && ele.style) {
              ele.style.backgroundImage = "url(" + ads.ImageUrl + ")";
              ele.style.width = ads.Width;
              ele.style.height = ads.Height;
            }
          } else if (ads.Position === "TOP" && ads.ImageUrl) {
            let ele = document.getElementById(
              "specialHomeTakeOverContainerTop"
            );
            if (ele && ele.style) {
              ele.style.backgroundImage = "url(" + ads.ImageUrl + ")";
              ele.style.backgroundRepeat = "no-repeat";
              ele.style.width = ads.Width;
              ele.style.height = ads.Height;
            }
          } else if (ads.Position === "LEFT" && ads.ImageUrl) {
            let ele = document.getElementById(
              "specialHomeTakeOverContainerLeft"
            );
            if (ele && ele.style) {
              ele.style.backgroundImage = "url(" + ads.ImageUrl + ")";
              ele.style.backgroundRepeat = "no-repeat";
              ele.style.width = ads.Width;
              ele.style.height = ads.Height;
            }
          } else if (ads.Position === "RIGHT" && ads.ImageUrl) {
            let ele = document.getElementById(
              "specialHomeTakeOverContainerRight"
            );
            if (ele && ele.style) {
              ele.style.backgroundImage = "url(" + ads.ImageUrl + ")";
              ele.style.backgroundRepeat = "no-repeat";
              ele.style.width = ads.Width;
              ele.style.height = ads.Height;
            }
          } else if (ads.Position === "BOTTOM" && ads.ImageUrl) {
            let ele = document.getElementById(
              "specialHomeTakeOverContainerBottom"
            );
            if (ele && ele.style) {
              ele.style.backgroundImage = "url(" + ads.ImageUrl + ")";
              ele.style.backgroundRepeat = "no-repeat";
              ele.style.width = ads.Width;
              ele.style.height = ads.Height;
            }
          }
        }

        // set min container body size
        if (this.mainBodyContainerWidth) {
          let ele = document.getElementById(
            "specialHomeTakeOverMainBodyContainer"
          );
          if (ele && ele.style) {
            ele.style.width = this.mainBodyContainerWidth;
          }
        }
      }
    }
  }

  ngAfterViewInit(): void {
    // if (isDevMode()) {
    // check ad blocker
    let adBlockerCheckerElement = document.getElementById("wrapfabtest");

    // let adblockerWarningShown: string = window.sessionStorage.getItem(
    //   "adblockerWarningShown"
    // );

    if (
      adBlockerCheckerElement &&
      adBlockerCheckerElement.offsetHeight <= 0
    ) {
      this.adBlocker = true;
      // if (
      //   adblockerWarningShown === null ||
      //   adblockerWarningShown === "" ||
      //   adblockerWarningShown === undefined
      // ) {

      //   let messageData = {
      //     Title: "Adblock Detected!",
      //     Message:
      //       "If you enjoy our service, please disable your ad blocker to get the best experience from our website.",
      //     NeedYesBtn: true,
      //     IsYesBtn: false,
      //     NeedNoBtn: false,
      //     YesBtnName: "Close",
      //   };

      //   const dialogRef = this.dialog.open(GeneralMessageDialogComponent, {
      //     data: messageData,
      //     disableClose: true,
      //     autoFocus: false,
      //     restoreFocus: false
      //   });

      //   // save data to session let sys know it.
      //   window.sessionStorage.setItem("adblockerWarningShown", "YES");
      // }
    }
    // }
  }

  getSystemNoticesList(): void {
    this.userService.getSystemNotices((response) => {
      if (response) {
        this.systemNoticesList = response;
      }
    });
  }
}
