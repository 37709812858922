import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { PeopleEntity } from '../models/people';
import { Provider } from '../models/provider';
import { QuoteRequiredData } from '../models/quote.required.data';
import { UserDeviceModel } from '../models/two.factor.login.models/user.device.model';
import { VerificationMethodModel } from '../models/two.factor.login.models/verification.method.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as dayjs from 'dayjs'
import { KeyValue, ViewportScroller } from '@angular/common';
import { GeneralMessageDialogSetting } from '../models/general-message-dialog-setting';
import { ConfirmMessageDialogService } from '../components/shared/confirm-message-dialog.service';
import { DevTools } from 'src/app/service/dev.tools';
import { ApiAddressService } from './api.address.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { BenefitGroup } from '../models/benefit.group';
import { Benefit } from '../models/benefit';
import { SelectItemStruct } from '../models/select.item';
import { CustomSnackbarComponent } from '../components/shared/custom-snackbar/custom-snackbar.component';

@Injectable({
    providedIn: 'root',
})
export class SharedFunctionService {


    hasTopAlert: boolean = false;
    quoteRequiredData: QuoteRequiredData;
    devTool: DevTools = new DevTools();

    constructor(
        private router: Router,
        public snackBar: MatSnackBar,
        public confirmDialog: ConfirmMessageDialogService,
        private apiAddressService: ApiAddressService,
        private http: HttpClient,
        private viewportScroller: ViewportScroller
    ) {
        this.hasTopAlert = false;
    }

    public progressStep = {
        QuoteStep1: { value: 1, rote: '/quote/client' },
        QuoteStep2: { value: 2, rote: '/quote/personal/benefit' },
        QuoteStep3: { value: 3, rote: '/quote/compare' },
        QuoteStepCheckMonster: { value: 5, rote: '/quote/checkmonster' },

        QuoteStep3Chart: { value: 11, rote: '/quote/compare' },
        QuoteStep3Document: { value: 12, rote: '/quote/compare' },
        QuoteStep3PriceChangeHistory: { value: 17, rote: '/quote/compare' },
        QuoteStep3Research: { value: 13, rote: '/quote/compare' },
        QuoteStep3Checkmonster: { value: 14, rote: '/quote/compare' },
        QuoteStep3H2H: { value: 15, rote: '/quote/compare' },
        QuoteStep3Underwriting: { value: 16, rote: '/quote/compare' },

        QuoteStep4: { value: 4, rote: '/quote/report' },

        NeedAnalysisNature: { value: 5.1, rote: '/needanalysis/nature' },
        NeedAnalysisObjectives: { value: 5.2, rote: '/needanalysis/objectives' },
        NeedAnalysisScope: { value: 5.3, rote: '/needanalysis/scope' },
        NeedAnalysisExisting: { value: 5.4, rote: '/needanalysis/existing' },
        NeedAnalysisFinancial: { value: 5.5, rote: '/needanalysis/financial' },
        NeedAnalysisCalculations: { value: 5.6, rote: '/needanalysis/calculations' },
        NeedAnalysisCalculationsNotePad: { value: 5.7, rote: '/needanalysis/recommendationnotepad' },
        
        QuoteWizardStep1: { value: 6.1, rote: '/quote-wizard/client-overview' },
        QuoteWizardStep2: { value: 6.2, rote: '/quote-wizard/client-details' },
        QuoteWizardStep3: { value: 6.3, rote: '/quote-wizard/suggestion' },
        QuoteWizardStep4: { value: 6.4, rote: '/quote-wizard/personal/benefit' },
        QuoteWizardStep5: { value: 6.5, rote: '/quote-wizard/compare' },
        QuoteWizardStepCheckMonster: { value: 6.13, rote: '/quote-wizard/checkmonster' },
        QuoteWizardStep6: { value: 6.6, rote: '/quote-wizard/report' },
        
        QuoteWizardStep5Chart: { value: 6.7, rote: '/quote-wizard/compare' },
        QuoteWizardStep5Document: { value: 6.8, rote: '/quote-wizard/compare' },
        QuoteWizardStep5PriceChangeHistory: { value: 6.13, rote: '/quote-wizard/compare' },
        QuoteWizardStep5Research: { value: 6.9, rote: '/quote-wizard/compare' },
        QuoteWizardStep5Checkmonster: { value: 6.10, rote: '/quote-wizard/compare' },
        QuoteWizardStep5H2H: { value: 6.11, rote: '/quote-wizard/compare' },
        QuoteWizardStep5Underwriting: { value: 6.12, rote: '/quote-wizard/compare' },
        
        PolicyBasedQuoteStep1: { value: 7.1, rote: '/policy-based/upload-file' },
        PolicyBasedQuoteStep2: { value: 7.2, rote: '/policy-based/summary' },
        PolicyBasedQuoteStep3: { value: 7.3, rote: '/policy-based/client' },
        PolicyBasedQuoteStep4: { value: 7.4, rote: '/policy-based/personal/benefit' },
        PolicyBasedQuoteStepCheckMonster: { value: 7.14, rote: '/policy-based/checkmonster' },
        PolicyBasedQuoteStep5: { value: 7.5, rote: '/policy-based/compare' },
        PolicyBasedQuoteStep6: { value: 7.6, rote: '/policy-based/report' },
        
        PolicyBasedQuoteStep5Chart: { value: 7.7, rote: '/policy-based/compare' },
        PolicyBasedQuoteStep5Document: { value: 7.8, rote: '/policy-based/compare' },
        PolicyBasedQuoteStep5PriceChangeHistory: { value: 7.13, rote: '/policy-based/compare' },
        PolicyBasedQuoteStep5Research: { value: 7.9, rote: '/policy-based/compare' },
        PolicyBasedQuoteStep5Checkmonster: { value: 7.12, rote: '/policy-based/compare' },
        PolicyBasedQuoteStep5H2H: { value: 7.10, rote: '/policy-based/compare' },
        PolicyBasedQuoteStep5Underwriting: { value: 7.11, rote: '/policy-based/compare' },
    };

    isNullOrEmptyString(value: string): boolean {
        let v = false;

        if (value === null) {
            v = true;
        } else if (value === undefined) {
            v = true;
        } else if (value === '') {
            v = true;
        } else if (value === 'undefined') {
            v = true;
        }

        return v;
    }


    isNullOrEmptyList(value: any[]) {
        let v = false;

        if (value === null) {
            v = true;
        } else if (value === undefined) {
            v = true;
        } else if (value.length <= 0) {
            v = true;
        }

        return v;
    }


    getNumberValue(value: any, defaultValue: number): number {
        let returnValue: number = defaultValue;
 
        if (value != null && !isNaN(value)) {
            returnValue = Number(value);
        }

        return returnValue;
    }

    public handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure

            // TODO: better job of transforming error for user consumption
            
            let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
            messageSetting.Title = 'Alert';
            messageSetting.NeedYesBtn = false;

            if (error.status === 401 && !this.hasTopAlert) {
                this.hasTopAlert = true;
                messageSetting.Message = this.getUiMessageByCode('Share-WARNING-NewDeviceReLogin');
                this.confirmDialog.confirm(messageSetting);
                // do log out
                // 1. clear all session data
                // -- window.sessionStorage.clear();
                // reload page
                // -- window.location.assign(window.location.origin);
            } else if (error.status === 402) {
                messageSetting.Message = this.getUiMessageByCode('Share-WARNING-AccessDeny');
                this.confirmDialog.confirm(messageSetting).subscribe((response) => this.router.navigate(['/research/subscription/plan']));
            } else if ((error.status < 200 || 299 > error.status) && !this.hasTopAlert) {
                messageSetting.Message = this.getUiMessageByCode('Share-WARNING-SomethingWrong');
                this.confirmDialog.confirm(messageSetting);
            }

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    public handleErrorWithoutAlert<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    // 1: Male, 2: Female, 3: Couple, 4: Male+Male, 5: Female+Female
    getSavedClientUserType(peopleEntity: PeopleEntity[]): number {

        let value: number = 0;

        if (peopleEntity && peopleEntity.length > 0) {
            for (let c of peopleEntity) {
                if (c.IsChild === false) {
                    if (value === 0) {
                        // if first client
                        value = c.Gender; // set first client gender
                    } else if (value === 1) {
                        // if already has first client gender and it is male
                        if (c.Gender === 1) {
                            value = 4; // if second client still male then show 2 male
                        } else {
                            value = 3; // if second client is female then show couple
                        }
                    } else if (value === 2){
                        // if first client is female
                        if (c.Gender === 2) {
                            value = 5; // if second client is female then show 2 female
                        } else {
                            value = 3; //  if second client is male then show couple.
                        }
                    }
                }
            }
        }

        return value;
    }

    removeDuplicates(arr: any[]): any[] {
        let uniqueArray = [];
        if (arr) {
            for (let i = 0; i < arr.length; i++) {
                if (uniqueArray.indexOf(arr[i]) === -1) {
                    uniqueArray.push(arr[i]);
                }
            }
        }

        return uniqueArray;
    }


    isAmp(provider: Provider): boolean {
        let value = false;
        if (provider.ProviderId === 8 || provider.ProviderId === 12) {
            value = true;
        }

        return value;
    }


    getUser2FDeviceMessage(userDeviceModel: UserDeviceModel, quoteRequiredData: QuoteRequiredData): string {
        let message: string = '';

        if (userDeviceModel && userDeviceModel.Message) {
            for (let m of quoteRequiredData.User2FactorLoginMessage) {
                if (m.MessageId === userDeviceModel.Message.MessageId) {
                    message = m.Message;
                    break;
                }
            }

            // replace some required data.
            if (userDeviceModel.VerificationMethod !== null) {
                switch (userDeviceModel.Message.MessageId) {
                    case 3:
                        message = message.replace('{{NumberOfDevice}}', userDeviceModel.VerificationMethod.MaxDevices.toString());
                        break;
                    case 9:
                        message = message.replace('{{Value}}', userDeviceModel.VerificationMethod.Value);
                        break;
                    default:
                        message = message;
                        break;
                }
            }

        }
        return message;
    }

    getUser2FVerificationMethodMessage(verificationMethod: VerificationMethodModel, quoteRequiredData: QuoteRequiredData): string {
        let message: string = '';

        if (verificationMethod && verificationMethod.Message) {
            for (let m of quoteRequiredData.User2FactorLoginMessage) {
                if (m.MessageId === verificationMethod.Message.MessageId) {
                    message = m.Message;
                    switch (m.MessageId) {
                        case 9:
                            message = message.replace('{{Value}}', verificationMethod.Value);
                            break;
                    }
                    break;
                }
            }

        }
        return message;
    }

    getUser2FSYSErrorMessage(quoteRequiredData: QuoteRequiredData) {
        let message: string = '';

        for (let m of quoteRequiredData.User2FactorLoginMessage) {
            if (m.MessageId === 2) {
                message = m.Message;
                break;
            }
        }

        return message;
    }

    isJSON(str): boolean {
        if (typeof str == 'string') {
            try {
                var obj = JSON.parse(str);
                if (typeof obj == 'object' && obj) {
                    return true;
                } else {
                    return false;
                }

            } catch (e) {
                return false;
            }
        }
    }


    // getHelpMessageByCode function and messages in quoteRequiredData.Help are not used in web v4
    getHelpMessageByCode(code: string, quoteRequiredData: QuoteRequiredData): string {
        let value = '';

        for (let v of quoteRequiredData.Help) {
            if (v.PageCode === code) {
                value = v.message;
                break;
            }
        }

        return value;
    }


    getFrequencyName(frequency: number): string {
        switch (frequency) {
            case 1: return 'Annual';
            case 2: return 'Half Year';
            case 4: return 'Quarterly';
            case 12: return 'Monthly';
            case 24: return 'Fortnightly';
            default: return '';
        }
    }


    setRequiredData(requiredData: QuoteRequiredData) {
        this.quoteRequiredData = requiredData;
    }

    getUiMessageByCode(code: string, defaultMessage: string = ''): string {
        let message: string = defaultMessage;

        if (this.quoteRequiredData && this.quoteRequiredData.UIMessages && this.quoteRequiredData.UIMessages.length > 0) {
            for (let m of this.quoteRequiredData.UIMessages) {
                if (m.Code === code) {
                    message = m.Message;
                    break;
                }
            }
        }
        return message;
    }

    convertDateTimeToNZFormat(datetime: Date): string {
        let value: string = '';

        if (datetime) {
            value = dayjs(datetime).format('DD MMM YY, hh:mm A');
        } else {
            value = dayjs(new Date()).format('DD MMM YY, hh:mm A');
        }

        return value;
    }


    getInvoiceStatusName(status: number, requiredData: QuoteRequiredData): string {
        let name: string = 'Other';

        if (requiredData.InvoiceStatus) {
            for (let s of requiredData.InvoiceStatus) {
                if (s.Value === status) {
                    name = s.Name;
                    break;
                }
            }
        }


        return name;
    }
    
    // get Name from QuoteRequiredData list
    getDisplayName(listName: string, value: number | string | boolean): string {
        let name: string = '';
         if (this.quoteRequiredData && this.quoteRequiredData[listName]) {
            for (let item of this.quoteRequiredData[listName]) {
                if (item.Value === value) {
                    name = item.Name;
                    break;
                }
            }
        }
        return name;
    }

    openSnackBar( messageCode: string = '', action: string, duration: number = 1000, message:string ='' ){
        if(message){
            this.snackBar.open(message, action, {
                duration: duration,
            });
        } else {
            this.snackBar.open(this.getUiMessageByCode(messageCode), action, {
                duration: duration,
            });
        }
    }
  
    openCustomSnackBar(messageCode: string = '', duration: number = 120000) {
        this.snackBar.openFromComponent(CustomSnackbarComponent, {
            duration: duration, 
            data: this.getUiMessageByCode(messageCode),
        });
    }

    // Preserve original property order for keyvalue pipe
    originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
         return 0;
    }

    getQmUserGuideHelpMessageByCode(code: string): string {
        let message = '';
        if (
            this.quoteRequiredData &&
            this.quoteRequiredData.QmUserGuideHelpMessages &&
            this.quoteRequiredData.QmUserGuideHelpMessages.length > 0
        ) {
            for (let item of this.quoteRequiredData.QmUserGuideHelpMessages) {
                if (item.MessageCode === code) {
                    message = item.Help;
                    break;
                }
            }
        }
        return message;
    }
    
    mapSelectOptions(value: any, allOptions: any[], selectProperty: string): any[] {
        const inputValue = typeof value === 'string' ? value : value? value.toString() : '';
        return inputValue ? this.selectFilter(inputValue as string, allOptions,selectProperty) : allOptions;
    }
    
    private selectFilter(value: string, allOptions: any[], selectProperty:string): any[] {
        const filterValue = value.toLowerCase();
        if (selectProperty) {
            const optionsLength: number = allOptions.filter(
                (option) => option[selectProperty].toString().toLowerCase().indexOf(filterValue) > -1
            ).length;

            if (optionsLength > 0) {
                if (
                    allOptions.filter(
                        (option) => option[selectProperty].toString().toLowerCase().indexOf(filterValue) === 0
                    ).length > 0
                ) {
                    // return options that start with filter value
                    return allOptions.filter(
                        (option) => option[selectProperty].toString().toLowerCase().indexOf(filterValue) === 0
                    );
                } else {
                    // return options that include filter value
                    return allOptions.filter(
                        (option) => option[selectProperty].toString().toLowerCase().indexOf(filterValue) > -1
                    );
                }
            } else {
                // return empty array;
                return [];
            }
        } else {
            // not object option list
            return allOptions.filter((option) => option.toString().toLowerCase().indexOf(filterValue) > -1).length > 0
                ? allOptions.filter((option) => option.toString().toLowerCase().indexOf(filterValue) > -1)
                : [];
        }
    }
    
    // allOptions is a string or number array
    mapValueOptions(value: any, allOptions: any[]): any[] {
        const inputValue = typeof value === 'string' ? value : value? value.toString() : '';
        return inputValue ? this.valueFilter(inputValue as string, allOptions) : allOptions;
    }
    
    private valueFilter(value: string, allOptions: any[], ) {
        const filterValue = value.toLowerCase();
        const optionsLength: number = allOptions.filter(
                (option) => option.toString().toLowerCase().indexOf(filterValue) > -1
            ).length;

            if (optionsLength > 0) {
                if (
                    allOptions.filter(
                        (option) => option.toString().toLowerCase().indexOf(filterValue) === 0
                    ).length > 0
                ) {
                    // return options that start with filter value
                    return allOptions.filter(
                        (option) => option.toString().toLowerCase().indexOf(filterValue) === 0
                    );
                } else {
                    // return options that include filter value
                    return allOptions.filter(
                        (option) => option.toString().toLowerCase().indexOf(filterValue) > -1
                    );
                }
            } else {
                // return empty array;
                return [];
            }
    }
    
    optionDisplayFn(obj: any, displayValue: string): string {
        return obj && obj[displayValue] ? obj[displayValue] : obj;
    }
    
    parseDate(dateString: string): Date | null {
        return dateString ? new Date(dateString) : null;
    }


    private defaultHttpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    processingGoogleReCaptchaToken(token, userId){
        // call qm service to validate token.        

        if(!userId) userId = 0;        
        let data = {
            Token: token,
            UserId: userId
        };
        this.http.post<boolean>(this.apiAddressService.getVerifyGoogleReCaptchaUrl(), JSON.stringify(data), this.defaultHttpOptions)
                .pipe(
                    tap(t => {})
                ).subscribe(responseData => {  });
    }
    
    // copy from quote base component
    getBenefitGroup(): BenefitGroup[] {
        let benefitGroupList: BenefitGroup[] = [];

        let benefitGroup1 = new BenefitGroup('Health Cover', '');
        let benefitGroup2 = new BenefitGroup('Lump Sum Cover', '');
        let benefitGroup3 = new BenefitGroup('Repayment Cover', '');

        // add health
        benefitGroup1.BenefitList.push(
            new Benefit(1, 'Health Cover', 'Health')
        );
        // Life Cover
        benefitGroup2.BenefitList.push(
            new Benefit(2, 'Life Cover', 'Life')
        );
        // Family Protection
        benefitGroup2.BenefitList.push(
            new Benefit(3, 'Family Protection', 'Family')
        );
        // Trauma SA
        benefitGroup2.BenefitList.push(
            new Benefit(4, 'Trauma Standalone', 'Trauma Standalone')
        );


        // Trauma ACC
        benefitGroup2.BenefitList.push(
            new Benefit(14, 'Trauma Accelerated', 'Trauma Accelerated')
        );

        // Total and Permanent Disability
        benefitGroup2.BenefitList.push(
            new Benefit(5, 'TPD Standalone', 'TPD Standalone')
        );

        benefitGroup2.BenefitList.push(
            new Benefit(15, 'TPD Accelerated', 'TPD Accelerated')
        );


        // Income Protection (Annual)
        benefitGroup3.BenefitList.push(
            new Benefit(6, 'Income', 'IP')
        );
        // Mortgage Repayment Cover
        benefitGroup3.BenefitList.push(
            new Benefit(7, 'Mortgage Cover', 'MP')
        );

        // Redundancy Cover
        benefitGroup3.BenefitList.push(
            new Benefit(8, 'Redundancy', 'Redundancy')
        );

        // Waiver of Premium
        benefitGroup3.BenefitList.push(
            new Benefit(9, 'Waiver of Premium', 'WOP')
        );


        // Level Life
        benefitGroup2.BenefitList.push(
            new Benefit(11, 'Level Life', 'Level Life')
        );
        // Level Trauma
        benefitGroup2.BenefitList.push(
            new Benefit(12, 'Level Trauma', 'Level Trauma')
        );
        // Level TPD
        benefitGroup2.BenefitList.push(
            new Benefit(13, 'Level TPD', 'Level TPD')
        );
        
        // Specific Injury
        benefitGroup2.BenefitList.push(
            new Benefit(23, 'Specific Injury', 'Specific Injury')
        );



        benefitGroupList.push(benefitGroup1);
        benefitGroupList.push(benefitGroup2);
        benefitGroupList.push(benefitGroup3);

        return benefitGroupList;
    }
    
    createDate(year: number, month: number, day: number): Date {
        return new Date(year, month - 1, day);
    }
    
    // copy from QuoteStepClient
    calculateAgeFromBirthday(dob: Date): number {
        const today = new Date();
        let age = today.getFullYear() - dob.getFullYear();
        const monthDiff = today.getMonth() - dob.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
            age--;
        }
        return age;
    }

    // check is a valid data (day <=31, month <=12)
    validationDob(day: number, month: number, year: number): boolean {
        let isValidDate: boolean;
        const dayStr = day.toString().padStart(2, '0');
        const monthStr = month.toString().padStart(2, '0');
        let dateStr = `${ year }-${ monthStr }-${ dayStr }`;
        let date = dayjs(dateStr, 'YYYY-MM-DD');
        isValidDate = date.isValid() && date.format('YYYY-MM-DD') === dateStr;
        return isValidDate;
    }

    // check is valid age
    isValidAge(day: number, month: number, year: number, minAge: number, maxAge: number): boolean {
        let age: number = this.calculateAgeFromBirthday(new Date(year, month - 1, day));
        return age >= minAge && age <= maxAge;
    }

    ageToDate(age: number): Date {
        let today = new Date();
        let date = new Date((today.getFullYear() - age), today.getMonth(), 1);
        return date;
    }

    // generate a list of number
    generateNumberList(min: number, max: number): number[] {
        let numberList = [];
        for (let i = min; i < max + 1; i++) {
            numberList.push(i);
        }
        return numberList;
    }


    getLoadingList(isForHealth: boolean = false): SelectItemStruct[] {
        let loadingList: SelectItemStruct[] = [];

        loadingList.push(
            new SelectItemStruct('0%', '', 1, true, 0, '', '', '', '', '', '')
        );

        if (isForHealth === true) {
            loadingList.push(
                new SelectItemStruct('25%', '', 1.25, false, 0, '', '', '', '', '', '')
            );
        }

        loadingList.push(
            new SelectItemStruct('50%', '', 1.5, false, 0, '', '', '', '', '', '')
        );

        loadingList.push(
            new SelectItemStruct('75%', '', 1.75, false, 0, '', '', '', '', '', '')
        );

        loadingList.push(
            new SelectItemStruct('100%', '', 2, false, 0, '', '', '', '', '', '')
        );

        loadingList.push(
            new SelectItemStruct('125%', '', 2.25, false, 0, '', '', '', '', '', '')
        );

        loadingList.push(
            new SelectItemStruct('150%', '', 2.5, false, 0, '', '', '', '', '', '')
        );

        loadingList.push(
            new SelectItemStruct('175%', '', 2.75, false, 0, '', '', '', '', '', '')
        );

        loadingList.push(
            new SelectItemStruct('200%', '', 3, false, 0, '', '', '', '', '', '')
        );

        loadingList.push(
            new SelectItemStruct('250%', '', 3.5, false, 0, '', '', '', '', '', '')
        );

        loadingList.push(
            new SelectItemStruct('300%', '', 4, false, 0, '', '', '', '', '', '')
        );

        loadingList.push(
            new SelectItemStruct('400%', '', 5, false, 0, '', '', '', '', '', '')
        );

        loadingList.push(
            new SelectItemStruct('500%', '', 6, false, 0, '', '', '', '', '', '')
        );

        return loadingList;
    }

    getHealthExcess(): SelectItemStruct[] {
        let excessList: SelectItemStruct[] = [];

        excessList.push(
            new SelectItemStruct('Nil Excess', '', 0, false, 0, '', '', '', '', '', '')
        );

        excessList.push(
            new SelectItemStruct('$250 Excess', '', 250, false, 0, '', '', '', '', '', '')
        );

        excessList.push(
            new SelectItemStruct('$500 Excess', '', 500, false, 0, '', '', '', '', '', '')
        );

        excessList.push(
            new SelectItemStruct('$750 Excess', '', 750, false, 0, '', '', '', '', '', '')
        );

        excessList.push(
            new SelectItemStruct('$1, 000 Excess', '', 1000, false, 0, '', '', '', '', '', '')
        );

        excessList.push(
            new SelectItemStruct('$2, 000 Excess', '', 2000, false, 0, '', '', '', '', '', '')
        );

        excessList.push(
            new SelectItemStruct('$3, 000 Excess', '', 3000, false, 0, '', '', '', '', '', '')
        );

        excessList.push(
            new SelectItemStruct('$4, 000 Excess', '', 4000, false, 0, '', '', '', '', '', '')
        );

        excessList.push(
            new SelectItemStruct('$5, 000 Excess', '', 5000, false, 0, '', '', '', '', '', '')
        );

        excessList.push(
            new SelectItemStruct('$6, 000 Excess', '', 6000, false, 0, '', '', '', '', '', '')
        );

        excessList.push(
            new SelectItemStruct('$10, 000 Excess', '', 10000, false, 0, '', '', '', '', '', '')
        );


        return excessList;
    }

    getWaitPeriodList(): SelectItemStruct[] {
        let list: SelectItemStruct[] = [];

        list.push(
            new SelectItemStruct('4 Weeks', '', 4, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('8 Weeks', '', 8, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('13 Weeks', '', 13, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('26 Weeks', '', 26, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('52 Weeks', '', 52, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('104 Weeks', '', 104, false, 0, '', '', '', '', '', '')
        );

        return list;
    }

    getBenefitPeriodList(): SelectItemStruct[] {
        let list: SelectItemStruct[] = [];

        list.push(
            new SelectItemStruct('1 Year', '', 1, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('2 Years', '', 2, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('5 Years', '', 5, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('To Age 65', '', 65, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('To Age 70', '', 70, false, 0, '', '', '', '', '', '')
        );

        return list;
    }

    getBusinessBenefitPeriodList(): SelectItemStruct[] {
        let list: SelectItemStruct[] = [];


        list.push(
            new SelectItemStruct('6 Months', '', -6, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('1 Year', '', 1, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('2 Years', '', 2, false, 0, '', '', '', '', '', '')
        );
        // list.push(
        //     new SelectItemStruct('5 Years', '', 5, false, 0, '', '', '', '', '', '')
        // );
        

        return list;
    }


    getBusinessWaitPeriodList(): SelectItemStruct[] {
        let list: SelectItemStruct[] = [];

        list.push(
            new SelectItemStruct('4 Weeks', '', 4, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('8 Weeks', '', 8, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('13 Weeks', '', 13, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('26 Weeks', '', 26, false, 0, '', '', '', '', '', '')
        );
        // list.push(
        //     new SelectItemStruct('52 Weeks', '', 52, false, 0, '', '', '', '', '', '')
        // );
        // list.push(
        //     new SelectItemStruct('104 Weeks', '', 104, false, 0, '', '', '', '', '', '')
        // );

        return list;
    }


    getWopBenefitPeriodList(): SelectItemStruct[] {
        let list: SelectItemStruct[] = [];

        list.push(
            new SelectItemStruct('To Age 65', '', 65, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('To Age 70', '', 70, false, 0, '', '', '', '', '', '')
        );

        return list;
    }

    getCalcPeriodList(): SelectItemStruct[] {
        let list: SelectItemStruct[] = [];

        list.push(
            new SelectItemStruct('10 Years', '', 10, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('to age 65', '', 65, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('to age 70', '', 70, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('to age 80', '', 80, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('to age 85', '', 85, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('to age 90', '', 90, false, 0, '', '', '', '', '', '')
        );
        list.push(
            new SelectItemStruct('to age 100', '', 100, false, 0, '', '', '', '', '', '')
        );

        return list;
    }

    getQprStarRating(value: number): string {
        let star: string = '';
        star = '<div class="qpr-star-rating"><span>';
        switch (value) {
            case 5: // 5 star
                star += '<i class="bi bi-star-fill"></i>';
                star += '<i class="bi bi-star-fill"></i>';
                star += '<i class="bi bi-star-fill"></i>';
                star += '<i class="bi bi-star-fill"></i>';
                star += '<i class="bi bi-star-fill"></i>';
                break;

            case 4.5: // 4.5 star
                star += '<i class="bi bi-star-fill"></i>';
                star += '<i class="bi bi-star-fill"></i>';
                star += '<i class="bi bi-star-fill"></i>';
                star += '<i class="bi bi-star-fill"></i>';
                star += '<i class="bi bi-star-half"></i>';
                break;


            case 4: // 4 star
                star += '<i class="bi bi-star-fill"></i>';
                star += '<i class="bi bi-star-fill"></i>';
                star += '<i class="bi bi-star-fill"></i>';
                star += '<i class="bi bi-star-fill"></i>';
                star += '<i class="bi bi-star"></i>';
                break;

            case 3.5: // 3.5 star
                star += '<i class="bi bi-star-fill"></i>';
                star += '<i class="bi bi-star-fill"></i>';
                star += '<i class="bi bi-star-fill"></i>';
                star += '<i class="bi bi-star-half"></i>';
                star += '<i class="bi bi-star"></i>';
                break;

            case 3: // 3 star
                star += '<i class="bi bi-star-fill"></i>';
                star += '<i class="bi bi-star-fill"></i>';
                star += '<i class="bi bi-star-fill"></i>';
                star += '<i class="bi bi-star"></i>';
                star += '<i class="bi bi-star"></i>';
                break;

            case 2.5: // 2.5 star
                star += '<i class="bi bi-star-fill"></i>';
                star += '<i class="bi bi-star-fill"></i>';
                star += '<i class="bi bi-star-half"></i>';
                star += '<i class="bi bi-star"></i>';
                star += '<i class="bi bi-star"></i>';
                break;

            case 2: // 2 star
                star += '<i class="bi bi-star-fill"></i>';
                star += '<i class="bi bi-star-fill"></i>';
                star += '<i class="bi bi-star"></i>';
                star += '<i class="bi bi-star"></i>';
                star += '<i class="bi bi-star"></i>';
                break;

            case 1.5: // 1.5 star
                star += '<i class="bi bi-star-fill"></i>';
                star += '<i class="bi bi-star-half"></i>';
                star += '<i class="bi bi-star"></i>';
                star += '<i class="bi bi-star"></i>';
                star += '<i class="bi bi-star"></i>';
                break;

            case 1: // 1 star
                star += '<i class="bi bi-star-fill"></i>';
                star += '<i class="bi bi-star"></i>';
                star += '<i class="bi bi-star"></i>';
                star += '<i class="bi bi-star"></i>';
                star += '<i class="bi bi-star"></i>';
                break;

            case 0.5: // 0.5 star
                star += '<i class="bi bi-star-half"></i>';
                star += '<i class="bi bi-star"></i>';
                star += '<i class="bi bi-star"></i>';
                star += '<i class="bi bi-star"></i>';
                star += '<i class="bi bi-star"></i>';
                break;

            case 0: // 0 star
                star += '<i class="bi bi-star"></i>';
                star += '<i class="bi bi-star"></i>';
                star += '<i class="bi bi-star"></i>';
                star += '<i class="bi bi-star"></i>';
                star += '<i class="bi bi-star"></i>';
                break;

            default: // 0 star
                star += '<i class="bi bi-star"></i>';
                star += '<i class="bi bi-star"></i>';
                star += '<i class="bi bi-star"></i>';
                star += '<i class="bi bi-star"></i>';
                star += '<i class="bi bi-star"></i>';
                break;
        }

        star += '</span></div>';
        return star;
    }
    
    compareWithId(obj1: any, obj2: any): boolean {
        if (obj1.ID) {
            return !!obj1?.ID && !!obj2?.ID && obj1.ID === obj2.ID;
        }
        if (obj1.Id) {
            return !!obj1?.Id && !!obj2?.Id && obj1.Id === obj2.Id;
        }
    }
    
    compareWithValue(obj1: any, obj2: any): boolean {
        return !!obj1?.Value && !!obj2?.Value && obj1.Value === obj2.Value;
    }
  
    getMaxValue(data: number[]): number {
        return data.reduce((max, current) => (current > max ? current : max), data[0]);
    }

    scrollToSection(sectionId: string): void {
        this.viewportScroller.scrollToAnchor(sectionId);
    }
  
    isKiwimonsterDomain(): boolean {
        return window.location.hostname.includes('kiwimonster.co.nz');
    }
}
