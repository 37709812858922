<div mat-dialog-title class="qm-bg-catskillWhite px-4 py-3 mx-n4 mt-n4">
  <h4 class="my-2">Exclusion Items</h4>
</div>
<div mat-dialog-content>
  <div class='table-responsive'>
    <table class="table table-bordered table-info">
      <tr>
        <th>
          Item Name
        </th>
        <th>
          Excludes?
        </th>
      </tr>
      <tbody class='bg-white'>
        <ng-container *ngFor="let question of questionList">
          <tr *ngIf="question.Value < 1001">
            <td class='align-middle'>{{question.Name}}</td>
            <td *ngIf="question.Value < 17">
              <mat-button-toggle-group [(ngModel)]="question.Value2" class='exclude-toggle-group'>
                <mat-button-toggle [value]="1">Yes</mat-button-toggle>
                <mat-button-toggle [value]="0">NO</mat-button-toggle>
              </mat-button-toggle-group>
            </td>

            <td *ngIf="question.Value === 17">
              <mat-button-toggle-group [(ngModel)]="question.Value2" class='exclude-toggle-group' #group="matButtonToggleGroup" (change)="onChange()" (click)="onClick(group, question)">
                <ng-container *ngFor="let risk of riskIndicatorList">
                  <mat-button-toggle [value]="risk">{{risk}}</mat-button-toggle>
                </ng-container>
              </mat-button-toggle-group>
            </td>

            <td *ngIf="question.Value === 18">
              <mat-button-toggle-group [(ngModel)]="question.Value2" class='exclude-toggle-group' #group="matButtonToggleGroup" (change)="onChange()" (click)="onClick(group, question)">
                <ng-container *ngFor="let risk of riskIndicatorList">
                  <mat-button-toggle [value]="risk" [disabled]='questionList[16].Value2 >= risk'>{{risk}}</mat-button-toggle>
                </ng-container>
              </mat-button-toggle-group>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
<!-- action buttons -->
<mat-dialog-actions align='end'>
  <button mat-flat-button color="qm-blue" type="button" (click)='closeSave()'>
    Save & Close
  </button>
</mat-dialog-actions>