import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SavedBusinessRecord } from 'src/app/models/business/saved.business.record';
import { BusinessDetailService } from 'src/app/service/business/business.detail.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'business-detail-component',
    templateUrl: './business-detail.component.html',
    styleUrls: ['./business-detail.component.scss']
})
export class BusinessDetailComponent implements OnInit, OnDestroy {
    @ViewChild('tradingNameInput') tradingNameInput: ElementRef;
    @ViewChild('phoneNumberInput') phoneNumberInput: ElementRef;
    @ViewChild('emailInput') emailInput: ElementRef;
    @ViewChild('nameInput') nameInput: ElementRef;

    isGoNext: boolean = false;
    
    currentSavedBusinessRecord: SavedBusinessRecord;
    isSameAsRegisteredOfficeAddress: boolean = false;
    errorMessage: string = '';

    constructor (
        public businessService: BusinessDetailService,
        public router: Router,
        public sharedFunction: SharedFunctionService,
        public loginService: LoginService,
        private dialog: MatDialog
    ) {

    }

    ngOnInit() {
        // get savedBusinessRecord from local storage or create a new object
        this.currentSavedBusinessRecord = this.businessService.getCurrentBusinessRecord();
        // set to current step
        this.currentSavedBusinessRecord.CurrentStep = this.businessService.StepDetail;
        // save to local storage
        this.businessService.saveCurrentBusinessRecord(this.currentSavedBusinessRecord);
    }


    ngOnDestroy() {

    }


    save(type: number): void {
        // type: 0: save and close; 1: go next;
        this.clearMessage();

        // if there is value in the input field, then add to list
        this.checkChipInput();

        // check is valid or not
        if (!this.currentSavedBusinessRecord.Name || !this.currentSavedBusinessRecord.Name.trim()) {
            this.errorMessage = this.sharedFunction.getUiMessageByCode('BusinessDetails-ERROR-EnterBusinessName');
        } else {
            // process data and go next
            if (type === 1) {
                this.isGoNext = true;
                this.onGoNext(type);
            } else if (type === 0) {
                this.onGoNext(type);
            }
        }
    }

    onGoNext(type: number) {
        // do go next or saveAndClose
        this.businessService.onSave(this.currentSavedBusinessRecord, (isSuccess, message) => {
            if (isSuccess) {
                if (type === 1) {
                    this.loginService.doGoogleTracking('business-detail', 'goToBPeople', '');
                    this.router.navigate(['/business/people']);
                } else if (type === 0) {
                    this.router.navigate(['/business/dashboard']);
                }
            } else {
                this.errorMessage = message;
            }
        });
    }

    saveAndClose(): void {


    }

    removeItem(fieldName: string, itemName: string): void {
        if (fieldName === 'tradingName') {
            if (this.currentSavedBusinessRecord.TradingNames.indexOf(itemName) >= 0) {
                this.currentSavedBusinessRecord.TradingNames.splice(this.currentSavedBusinessRecord.TradingNames.indexOf(itemName), 1);
            }
        } else if (fieldName === 'phoneNumber') {
            if (this.currentSavedBusinessRecord.PhoneNumbers.indexOf(itemName) >= 0) {
                this.currentSavedBusinessRecord.PhoneNumbers.splice(this.currentSavedBusinessRecord.PhoneNumbers.indexOf(itemName), 1);
            }
        } else if (fieldName === 'email') {
            if (this.currentSavedBusinessRecord.EmailAddresses.indexOf(itemName) >= 0) {
                this.currentSavedBusinessRecord.EmailAddresses.splice(this.currentSavedBusinessRecord.EmailAddresses.indexOf(itemName), 1);
            }
        }

        this.onValueChanges();
    }

    addItem(fieldName: string, inputValue: string): void {
        if (inputValue.trim()) {
            if (fieldName === 'tradingName') {
                this.currentSavedBusinessRecord.TradingNames.push(inputValue);
                this.tradingNameInput.nativeElement.value = '';
            } else if (fieldName === 'phoneNumber') {
                this.currentSavedBusinessRecord.PhoneNumbers.push(inputValue);
                this.phoneNumberInput.nativeElement.value = '';
            } else if (fieldName === 'email') {
                this.currentSavedBusinessRecord.EmailAddresses.push(inputValue);
                this.emailInput.nativeElement.value = '';
            }
        }
        this.onValueChanges();
    }

    clearMessage(): void {
        this.errorMessage = '';
    }

    onValueChanges(): void {
        this.clearMessage();
        this.businessService.saveCurrentBusinessRecord(this.currentSavedBusinessRecord);
    }

    setAddressForService(): void {
        if (this.isSameAsRegisteredOfficeAddress) {
            this.currentSavedBusinessRecord.AddressForService = this.currentSavedBusinessRecord.RegisteredOfficeAddress;
        }
    }

    setIsSameAsRegisteredOfficeAddress(): void {
        if (this.isSameAsRegisteredOfficeAddress && this.currentSavedBusinessRecord.AddressForService !== this.currentSavedBusinessRecord.RegisteredOfficeAddress) {
            this.isSameAsRegisteredOfficeAddress = false;
        }
    }

    checkChipInput(): void {
        if (this.tradingNameInput.nativeElement.value.trim()) {
            this.addItem('tradingName', this.tradingNameInput.nativeElement.value);
        }

        if (this.phoneNumberInput.nativeElement.value.trim()) {
            this.addItem('phoneNumber', this.phoneNumberInput.nativeElement.value);
        }

        if (this.emailInput.nativeElement.value.trim()) {
            this.addItem('email', this.emailInput.nativeElement.value);
        }
    }

    generateCompanySummary(companyName: string, website: string, currentSavedBusinessRecord: SavedBusinessRecord): void {
      this.businessService.showDinoLoading();
        this.businessService.getNZBNAiSummary(companyName, website, (response) => {
            if (response && response.MessageCode === 200) {
                currentSavedBusinessRecord.Description = response.Message;
            }
            this.businessService.closeDinoLoading();
        });
    }
}
