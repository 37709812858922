<div class="home-page">
  <!-- hero section-->
  <div class="row py-block hero-section">
    <div class="container pb-lg-5">
      <img width="1019" height="563" class="mb-4 img-artboard" src="../../../assets/images/home/artboard1x_optimized.png?v=2" alt="artboard">
      <div class="row pb-lg-5">
        <div class="col-12 col-lg-6 pb-lg-5 text-center text-sm-left content-wrapper">
          <h1 class="h-xxl mb-4 text-left">Provide quality
            life and health insurance advice<br>
            <span class="text-blue">— faster.</span>
          </h1>
          <!-- <h4 class="mb-4 text-left">Use our price comparisons, research, and advice tools to help
            quickly understand product differences and make informed recommendations to clients – all right here,
            online.
          </h4> -->
          <h4 class="mb-4 text-left">
            Quotemonster price comparisons are free, join now with a free trial and you can also check out the research and Advicemonster services available with a subscription.
          </h4>
          <a mat-flat-button class='qm-flat-btn-lg' color="qm-pumpkin" [routerLink]="['/signup']">Sign up - It's free</a>
        </div>
      </div>
    </div>
  </div>
  <!-- hero section end-->

<div class="d-lg-none row qm-bg-lightGray justify-content-center">
    <span class="material-icons text-black-50 font-weight-bolder" aria-hidden="false" aria-label="expand_more">
      expand_more</span>
</div>

  <!-- provider section -->
  <div class="row py-block qm-bg-darkGray provider-section">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 d-lg-flex justify-content-between px-0 provider-section-cards">
          <div class="col-12 col-md-8 col-lg-4 mx-auto">
            <mat-card class="mb-5 mx-auto mx-lg-0 px-4 rounded-0">
              <mat-card-title class="pb-lg-5">
                <h6 class="text-uppercase text-blue">Adviser Resources</h6>
              </mat-card-title>
              <div class="d-flex justify-content-between">
                <mat-card-content>
                  <h4>Research led resources at your fingertips</h4>
                </mat-card-content>
                <!-- TODO: visible after creating the resources page -->
                <mat-card-actions class="ml-3">
                  <a mat-icon-button href="" class="invisible">
                    <span class="material-icons text-blue" aria-hidden="false" aria-label="navigate_next">navigate_next</span>
                  </a>
                </mat-card-actions>
              </div>
            </mat-card>
          </div>
          <div class="col-12 col-md-8 col-lg-4 mx-auto">
            <mat-card class="mb-5 mx-auto mx-lg-0 px-4 rounded-0">
              <mat-card-title class="pb-lg-5">
                <h6 class="text-uppercase text-blue">Plans & Pricing</h6>
              </mat-card-title>
              <div class="d-flex justify-content-between">
                <mat-card-content>
                  <h4>Find the plan to help you give the best advice</h4>
                </mat-card-content>
                <mat-card-actions class="ml-3">
                  <a mat-icon-button [routerLink]="['/pricing']">
                    <span class="material-icons text-blue" aria-hidden="false" aria-label="navigate_next">navigate_next</span>
                  </a>
                </mat-card-actions>
              </div>
            </mat-card>
          </div>
          <div class="col-12 col-md-8 col-lg-4 mx-auto">
            <mat-card class="mb-5 mx-auto mx-lg-0 px-4 rounded-0">
              <mat-card-title class="pb-lg-5">
                <h6 class="text-uppercase text-blue">About Us</h6>
              </mat-card-title>
              <div class="d-flex justify-content-between">
                <mat-card-content>
                  <h4>Find out how Quote, Research, and Advicemonster can help you create personalised advice for your clients</h4>
                </mat-card-content>
                <mat-card-actions class="ml-3">
                  <a mat-icon-button [routerLink]="['/public/about-overview']">
                    <span class="material-icons text-blue" aria-hidden="false" aria-label="navigate_next">navigate_next</span>
                  </a>
                </mat-card-actions>
              </div>
            </mat-card>
          </div>
        </div>
        <div class="col-12 py-5 text-center text-lg-left">
          <h1 class="mb-4 text-white"><span class="text-blue">Compare</span> More Companies and Products Than Ever Before
          </h1>
          <h4 class="text-white">Gain comprehensive independent research to over 30 insurers and 1,000+ on sale, legacy and group products rated for New Zealand’s major life and health insurers, direct and bank providers.
          </h4>
        </div>
        <div class="col-12">
          <ul class="row px-0 justify-content-center">
            <div class="col-4 col-md-3 col-lg-2 py-2" *ngFor="let plogo of providerLogoList">
              <li class="list-group-item rounded-lg km-logo-holder">
                <img class="d-block position-middle" src="{{plogo.URL}}?s={{timestamp}}" alt="{{plogo.Name}}">
              </li>
            </div>
          </ul>
        </div>
        <div class='col-12 py-5'>
          <a mat-flat-button color="qm-white" class="qm-flat-btn-lg"
            href='https://quotemonster.freshdesk.com/support/tickets/new' target="_blank" title='request demo'
            role="button">Request a demo</a>
        </div>
      </div>
    </div>
  </div>
  <!-- provider section end-->

<div class="d-lg-none row qm-bg-darkGray mt-n1 justify-content-center">
  <span class="material-icons text-white font-weight-bolder" aria-hidden="false" aria-label="expand_more">
    expand_more</span>
</div>

  <!-- advice section -->
  <div class="row py-block advice-section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1 class="mb-5"><span class="text-blue">Evidence-based</span> advice to give you and your
            clients confidence
          </h1>
        </div>
        <div class="col-12 col-lg-5 order-2 order-lg-1">
          <mat-list class="mb-4">
            <mat-list-item class="advice-section-list">
              <img width="28" height="24" src="../../../assets/images/home/task-alt@2x.png?v=2" alt="task-alt">
              <h5 class="py-0 ml-3 my-0 line-height-md">Compare features included in each product</h5>
            </mat-list-item>
            <mat-list-item class="advice-section-list">
              <img width="28" height="24" src="../../../assets/images/home/task-alt@2x.png?v=2" alt="task-alt">
              <h5 class="py-0 ml-3 my-0 line-height-md">At a glance comparison using our heat-maps to make complex comparisons easy to visualise</h5>
            </mat-list-item>
            <mat-list-item class="advice-section-list">
              <img width="28" height="24" src="../../../assets/images/home/task-alt@2x.png?v=2" alt="task-alt">
              <h5 class="py-0 ml-3 my-0 line-height-md">Zoom into detailed star rating reports to understand the most valuable benefits</h5>
            </mat-list-item>
            <mat-list-item class="advice-section-list">
              <img width="28" height="24" src="../../../assets/images/home/task-alt@2x.png?v=2" alt="task-alt">
              <h5 class="py-0 ml-3 my-0 line-height-md">Use summary head to head comparisons to get a ten year premium projection and compare two products</h5>
            </mat-list-item>
            <mat-list-item class="advice-section-list">
              <img width="28" height="24" src="../../../assets/images/home/task-alt@2x.png?v=2" alt="task-alt">
              <h5 class="py-0 ml-3 my-0 line-height-md">Use detailed head to head to get a side by side comparison of policy wordings for two products feature by feature</h5>
            </mat-list-item>
          </mat-list>
        </div>
        <div class="col-12 col-lg-7 order-1 order-lg-2 pb-5 pb-lg-0">
          <video width="690" height="440" [muted]="'muted'"  controls class="shadow qm-video" preload="none"
                poster='../../../assets/images/home/home-video-poster.png'>
            <source src="https://quoetmonsterimages.blob.core.windows.net/qmimagead/qm-hpto-videos/Quotemonster-Presentation-20220413.mp4" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  </div>
  <!-- advice section end-->

<div class="d-lg-none row justify-content-center">
  <span class="material-icons text-black-50 font-weight-bolder" aria-hidden="false" aria-label="expand_more">
    expand_more</span>
</div>

  <!-- reports-section -->
  <div class="row qm-bg-blue-linear-gradient py-block pb-0 pb-lg-5 reports-section">
    <div class="container">
      <div class="row pb-lg-5">
        <div class="col-12 col-lg-4 pb-lg-5 text-center text-lg-left">
          <h1 class="mb-4"><span class="text-white">New insurer</span><br>research
            reports
          </h1>
          <h4 class="text-white mb-4">Comparisons between companies now available to research
            subscribers provide evidence for your approved provider
            selections</h4>
          <a mat-flat-button class='qm-flat-btn-lg' color="qm-black" [routerLink]="['/login']">Get Started</a>
        </div>
      </div>
      <img width="1232" height="1091" class="img-covers" src="../../../assets/images/home/covers1x_optimized.png?v=2" alt="covers">
    </div>
  </div>
  <!-- reports-section end -->

  <div class="d-lg-none row mt-n4 justify-content-center">
    <span class="material-icons text-white font-weight-bolder" aria-hidden="false" aria-label="expand_more">
      expand_more</span>
  </div>

  <!-- research -->
  <div class="row py-block research-section">
    <div class="container">
      <div class="row">
        <div class="col-12 mb-5 text-center text-lg-left">
          <h1 class="mb-5"><span class="text-blue">Value-based</span> research</h1>
          <h3 class="mb-5">We think that by using real-world data to make our research value-based it makes it a lot easier to see 
            which features and benefits really count, and which are just bells and whistles. When we launched in April 
            2012 we were alone in that view. More recently, in Australia ratings agencies have been criticised for not following a ‘value-based’ approach.
          </h3>
        </div>
        <div class="col-12">
          <div class="row justify-content-center">
            <mat-card class="col-12 col-md-8 col-lg-6 mb-5 text-center text-lg-left">
              <img class="mb-4 mx-auto" width="64" height="80" mat-card-image
                src="../../../assets/images/home/search@2x.png?v=2" alt="search">
              <mat-card-title>
                <h4 class="mb-3 mb-lg-4">Definition</h4>
              </mat-card-title>
              <mat-card-content>
                <p class="qm-text-mid text-black text-justify">
                  Even for financial advisers with decades of experience it can be useful to get a quantified view on the difference between complex policy wordings. 
                  We take the policy documents and look for differences between wordings. 
                  A starting score of 100 is commonly assigned to a benefit and scoring variations are usually deductions to the score for restrictions on the cover.
                </p>
              </mat-card-content>
            </mat-card>
            <mat-card class="col-12 col-md-8 col-lg-6 mb-5 text-center text-lg-left">
              <img class="mb-4 mx-auto" width="64" height="80" mat-card-image
                src="../../../assets/images/home/fly@2x.png?v=2" alt="search">
              <mat-card-title>
                <h4 class="mb-3 mb-lg-4">Incidence</h4>
              </mat-card-title>
              <mat-card-content>
                <p class="qm-text-mid text-black  text-justify">
                  The second stage in delivering value-based rating is to identify how likely each benefit is to be claimed on. 
                  The incidence for all the items covered under trauma insurance adds up to 100%. 
                  We allow an additional score for 'policy features' like the ability to add to your cover without medical evidence. 
                  This varies depending on who you are: so items can get a different weight depending on age, gender, occupation, employment, and the product options selected.
                </p>
              </mat-card-content>
            </mat-card>
            <mat-card class="col-12 col-md-8 col-lg-6 mb-5 text-center text-lg-left">
              <img class="mb-4 mx-auto" width="54" height="60" mat-card-image
                src="../../../assets/images/home/vertical-line@2x.png?v=2" alt="search">
              <mat-card-title>
                <h4 class="mb-3 mb-lg-4">Frequency</h4>
              </mat-card-title>
              <mat-card-content>
                <p class="qm-text-mid text-black text-justify">
                  If a claim can be made more than once for an item a frequency higher than 100% will be applied here. 
                  For example, in rating medical insurance we allow a claim frequency of two or three times for certain tests in our 'model' of how the policy will be used while the client has the cover. 
                  For benefits that may apply only to small groups of people under special circumstances we can apply a frequency of less than one.
                </p>
              </mat-card-content>
            </mat-card>
            <mat-card class="col-12 col-md-8 col-lg-6 text-center text-lg-left">
              <img class="mb-4 mx-auto" width="85" height="85" mat-card-image
                src="../../../assets/images/home/dollar@2x.png?v=2" alt="search">
              <mat-card-title>
                <h4 class="mb-3 mb-lg-4">Amount</h4>
              </mat-card-title>
              <mat-card-content>
                <p class="qm-text-mid text-black text-justify">
                  We think it is obvious to weight the score by the amount actually paid. 
                  In trauma insurance, for example, some companies pay the full benefit for an item, others only make a payment of 10% or 20% 
                  of the sum insured because the condition was not severe enough to warrant a full payment. 
                  Our score is varied according to how much would actually be paid.
                </p>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- research end -->

  <div class="d-lg-none row justify-content-center">
    <span class="material-icons text-black-50 font-weight-bolder" aria-hidden="false" aria-label="expand_more">
      expand_more</span>
  </div>

  <!-- connected section -->
  <div class="row py-block connected-section">
    <div class="container">
      <div class="row text-center">
        <div class="col-12">
          <h1 class="mb-3">Connected and Customised</h1>
          <h4 class="mb-5 mx-auto">From personalisation of each research report and
            statement
            of
            advice to connection to your systems</h4>
          <a mat-flat-button color="qm-pumpkin" class='qm-flat-btn-lg' [routerLink]="['/login']">Start Now</a>
        </div>
      </div>
    </div>
  </div>
  <!-- connected section end -->

  <div class="row py-block">
    <div class="container">
      <div class="row justify-content-between pb-5">
        <div class="col-12 col-lg-5">
          <h2 class="mb-5">Our vision is to make it easier to compare insurance
            products, so that you, the adviser, can develop great advice for
            your clients.</h2>
            <a mat-flat-button color="qm-darkGray" class='qm-flat-btn-lg' [routerLink]="['/public/about-overview']">About Us</a>
        </div>
        <div class="col-12 col-lg-6 mt-5 mt-lg-0">
          <h5 class="text-blue">We can all take advice - expert help used in preparing our research.</h5>
          <p>
            We get assistance in reviewing contracts from a number of different sources:
            Insurance companies and reinsurers: - can all access the data on which we make our assessments and provide feedback.
          </p>
          <p>
            <span class="material-icons text-black font-weight-bolder align-text-bottom mr-1" aria-hidden="false" aria-label="how_to_reg">how_to_reg</span>
            Actuaries: help to inform our view around relative risks.
          </p>
          <p>
            <span class="material-icons text-black font-weight-bolder align-text-bottom mr-1" aria-hidden="false" aria-label="how_to_reg">how_to_reg</span>
            Claims managers and underwriting managers: help us to get different perspectives on the actual use and value of different features.
          </p>
          <p>
            <span class="material-icons text-black font-weight-bolder align-text-bottom mr-1" aria-hidden="false" aria-label="how_to_reg">how_to_reg</span>
            Financial advisers: give us feedback on recent claims experience and how their clients are actually using the insurance policies we rate.
          </p>
          <p>
            <span class="material-icons text-black font-weight-bolder align-text-bottom mr-1" aria-hidden="false" aria-label="how_to_reg">how_to_reg</span>
            We also stay in touch with people at dispute resolution bodies, regulatory bodies, news
            organisations, academia, and consumer groups to get a wide range of views and input.
          </p>
        </div>
      </div>
    </div>
  </div>



  <!-- about section-->
  <div class="row py-block about-section qm-bg-lightGray">
    <div class="container">
      <div class="row justify-content-between pb-5">
        <div class="col-12 text-center">
          <h1 class="mb-5"><span class="text-blue">News</span> helps you keep current and competent to advise</h1>
        </div>
        <div class="col-12">
          <blog-page [numberOfBlog]='6'></blog-page>
        </div>
      </div>
      <div class="mt-3 text-center text-lg-right">
        <a mat-flat-button class='qm-flat-btn-lg' color="qm-darkGray" href="https://www.chatswood.co.nz/" target="_blank" rel="noopener">View All News</a>
      </div>
    </div>
  </div>
  <!-- about section end -->
</div>
