import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { KiwiMonsterClient } from 'src/app/models/kiwimonster/kiwimonster-client.model';
import { KiwiMonsterFund } from 'src/app/models/kiwimonster/kiwimonster-fund.model';
import { KiwiMonsterKiwiSaverService } from 'src/app/service/kiwimonster/kiwimonster-kiwisaver.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SysConfigService } from 'src/app/service/sys.config';

@Component({
  selector: 'kiwimonster-funds-dialog',
  templateUrl: './kiwimonster-funds-dialog.component.html',
  styleUrls: ['./kiwimonster-funds-dialog.component.scss']
})
export class KiwiMonsterFundsDialogComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['schemeName', 'PastYearReturnNet', 'AverageFiveYearReturnNet', 'RiskRewardIndicatorCode', 'QmRiskProfileCode', 'FundTotalValue', 'NumberOfInvestors', 'QmCalcFeeInPercentage','FundStartDate'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource: MatTableDataSource<KiwiMonsterFund>;

  selectedFunds: KiwiMonsterFund[]=[];
  selectedFundType: string = '';
  isLoadingData: boolean = false;
  errorMessage: string = '';
  exceedsThreeFunds: boolean = false;
  
  currentFund: KiwiMonsterFund | null;
  fundToOpen: boolean = true;
  textFilterValue: string = '';
  constructor
    (
      public sharedFunction: SharedFunctionService,
      public dialogRef: MatDialogRef<KiwiMonsterFundsDialogComponent>,
      public sysConfig: SysConfigService,
      private kiwiMonsterKiwiSaverService: KiwiMonsterKiwiSaverService,
      public loginService:LoginService,
      @Inject(MAT_DIALOG_DATA) public data: { Funds: KiwiMonsterFund[], Client: KiwiMonsterClient; },
    ) { }

  ngOnInit(): void {
    this.currentFund = this.data.Client.Setting.CurrentSchemeFund;
    this.selectedFundType = this.data.Funds[0].QmRiskProfileCode;
    this.selectedFunds = this.data.Client.Setting.QmKSProspectiveProductFunds;
    if (this.selectedFunds.length === 3) {
      this.exceedsThreeFunds = true;
    }
    this.setInitSelectedFunds(this.selectedFunds, this.data.Funds);
    this.dataSource = new MatTableDataSource(this.data.Funds);
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    setTimeout(() => {
      this.bindTableData();
      this.fundToOpenFilter();
    });
  }

  closeSave(): void {
    this.loginService.doGoogleTracking("kiwiMonsterQuote-kiwisaver-selectProspectiveFundsDialog", "closeAndSave", "");
    this.dialogRef.close(this.selectedFunds);
  }

  setPaginator(): void {
    this.paginator._intl.itemsPerPageLabel = "KiwiSaver Fund Per Page";
    this.paginator.pageSize = 10;
    this.paginator.pageSizeOptions = [10, 25, 100];
    this.paginator.showFirstLastButtons = true;
    this.paginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0) {
        return `Page 1 / 1`;
      }
      const amountPages = Math.ceil(length / pageSize);
      return `Page ${ page + 1 } / ${ amountPages }`;
    };

    this.paginator._formFieldAppearance = "outline";
  }

  bindTableData(): void {
    this.setPaginator();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // filter value
    this.dataSource.filterPredicate = (
      data: KiwiMonsterFund,
      filter: string
    ): boolean => {
      let [textFilter, fundToOpenFilter] = filter.split('::');
      let fundToOpen = fundToOpenFilter === 'true';

      let matchesTextFilter =
        data.SchemeName?.toLowerCase().trim().indexOf(textFilter) >= 0 ||
        data.FundName?.toLowerCase().trim().indexOf(textFilter) >= 0;

      let matchesFundToOpenFilter = !fundToOpen || data.FundToOpen === 1;

      return matchesTextFilter && matchesFundToOpenFilter;
    };
  }
  
  toggleSelectedFund(fund: KiwiMonsterFund): void {
    if (fund.IsSelectedAsProspective) {
      this.selectedFunds.push(fund);
      if (this.selectedFunds.length === 3) {
        this.exceedsThreeFunds = true;
      }
    } else {
      this.exceedsThreeFunds = false;
      this.selectedFunds = this.selectedFunds.filter(f => f.Id !== fund.Id);
    }
  }

  textFilter(filterValue: string) {
    filterValue = filterValue.toLowerCase().trim();
    this.textFilterValue = filterValue;
    let fundToOpen = this.fundToOpen ? 'true' : 'false';
    this.dataSource.filter = `${ filterValue }::${ fundToOpen }`;
  }
  
  fundToOpenFilter(): void {
    this.updateDisplayedColumns();
    let currentTextFilter = this.dataSource.filter.split('::')[0] || '';
    let fundToOpen = this.fundToOpen ? 'true' : 'false';
    this.dataSource.filter = `${ currentTextFilter }::${ fundToOpen }`;
  }
  
  onFundTypeChange(): void {
    this.errorMessage = '';
    this.isLoadingData = true;
    this.kiwiMonsterKiwiSaverService.getFundsByFundTypeObservable(this.selectedFundType).subscribe((response) => {
      if (response && response.length > 0) {
        let filteredFunds = this.kiwiMonsterKiwiSaverService.removeCurrentFundFromData(this.data.Client.Setting.QmKSProductFundId, response);
        this.data.Funds = filteredFunds;
        this.setInitSelectedFunds(this.selectedFunds, this.data.Funds);
        this.dataSource = new MatTableDataSource(this.data.Funds);
        setTimeout(() => {
          this.bindTableData();
          this.textFilter(this.textFilterValue);
          this.fundToOpenFilter();
        });
      } else {
        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
      }
      this.isLoadingData = false;
    });
  }

  setInitSelectedFunds(selectedFunds: KiwiMonsterFund[], searchFunds: KiwiMonsterFund[]): void {
    if (selectedFunds.length > 0) {
      searchFunds.forEach(fund => {
        fund.IsSelectedAsProspective = selectedFunds.some(selectedFund => selectedFund.Id === fund.Id);
      });
    }
  }
  
  unselectedFund(fund: KiwiMonsterFund): void {
    this.exceedsThreeFunds = false;
    fund.IsSelectedAsProspective = false;
    this.selectedFunds = this.selectedFunds.filter(f => f.Id !== fund.Id);
    let dataFund = this.data.Funds.find(f => f.Id === fund.Id);
    if (dataFund) {
      dataFund.IsSelectedAsProspective = false;
    }
    this.loginService.doGoogleTracking("kiwiMonsterQuote-kiwisaver-selectProspectiveFundsDialog", "deleteSelectedFund", "");
  }
  
  updateDisplayedColumns(): void {
    if (this.fundToOpen) {
      this.displayedColumns = [
        'schemeName',
        'PastYearReturnNet',
        'AverageFiveYearReturnNet',
        'RiskRewardIndicatorCode',
        'QmRiskProfileCode',
        'FundTotalValue',
        'NumberOfInvestors',
        'QmCalcFeeInPercentage',
        'FundStartDate'
      ];
    } else {
      this.displayedColumns = [
        'schemeName',
        'PastYearReturnNet',
        'AverageFiveYearReturnNet',
        'RiskRewardIndicatorCode',
        'QmRiskProfileCode',
        'FundTotalValue',
        'NumberOfInvestors',
        'QmCalcFeeInPercentage',
        'FundStartDate',
        'FundStatus'
      ];
    }
  }
  
  updateFundType(fund: KiwiMonsterFund): string {
    return KiwiMonsterFund.updateFundType(fund);
  }
}



