import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { KiwiMonsterFund } from 'src/app/models/kiwimonster/kiwimonster-fund.model';
import { PreferredFund } from 'src/app/models/kiwimonster/kiwimonster-preferred-fund.model';
import { KiwiMonsterKiwiSaverService } from 'src/app/service/kiwimonster/kiwimonster-kiwisaver.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SysConfigService } from 'src/app/service/sys.config';

@Component({
  selector: 'app-kiwimonster-fund-selection-dialog',
  templateUrl: './kiwimonster-fund-selection-dialog.component.html',
  styleUrls: ['./kiwimonster-fund-selection-dialog.component.scss']
})
export class KiwiMonsterFundSelectionDialogComponent implements OnInit {
  displayedColumns: string[] = ['SchemeName', 'PastYearReturnNet', 'AverageFiveYearReturnNet', 'RiskRewardIndicatorCode', 'QmRiskProfileCode', 'FundTotalValue', 'NumberOfInvestors', 'QmCalcFeeInPercentage', 'FundStartDate'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  dataSource: MatTableDataSource<KiwiMonsterFund>;
  
  selectedFund: KiwiMonsterFund = null;
  selectedFundType: string = '';
  isLoadingData: boolean = false;
  errorMessage: string = '';

  fundToOpen: boolean = true;
  textFilterValue: string = '';
  
  allFunds: KiwiMonsterFund[] = [];
  setToNull: boolean = false;
  constructor
    (
      public sharedFunction: SharedFunctionService,
      public dialogRef: MatDialogRef<KiwiMonsterFundSelectionDialogComponent>,
      public sysConfig: SysConfigService,
      private kiwiMonsterKiwiSaverService: KiwiMonsterKiwiSaverService,
      public loginService: LoginService,
      @Inject(MAT_DIALOG_DATA) public data: { CurrentPreferredFund: PreferredFund, OtherSelectedIds: number[]; },
    ) { }

  ngOnInit(): void {
    this.selectedFundType = this.data.CurrentPreferredFund.FundBaseTypeCode;
    this.loadData();
  }

  loadData(): void {
    this.errorMessage = '';
    this.isLoadingData = true;
    this.kiwiMonsterKiwiSaverService.getFundsByFundTypeObservable(this.selectedFundType).subscribe((response) => {
      if (response && response.length > 0) {
        this.allFunds = response;
        this.dataSource = new MatTableDataSource(response);
        setTimeout(() => {
          this.kiwiMonsterKiwiSaverService.bindTableData(this.dataSource, this.paginator,this.sort,this.fundToOpen);
          this.textFilter(this.textFilterValue);
          this.fundToOpenFilter();
          this.selectedFund = this.initSelectedFund(response);
        });
      } else {
        this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-NoResults');
      }
      this.isLoadingData = false;
    });
  }

  textFilter(filterValue: string) {
    this.textFilterValue = filterValue;
    this.dataSource.filter = this.kiwiMonsterKiwiSaverService.getFilter(filterValue, this.fundToOpen);
  }

  fundToOpenFilter(): void {
    this.updateDisplayedColumns();
    this.dataSource.filter = this.kiwiMonsterKiwiSaverService.getFilter(this.textFilterValue, this.fundToOpen);
  }

  updateDisplayedColumns(): void {
    this.displayedColumns = this.kiwiMonsterKiwiSaverService.getDisplayedColumns(this.fundToOpen);
  }

  updateFundType(fund: KiwiMonsterFund): string {
    return KiwiMonsterFund.updateFundType(fund);
  }

  checkState(event, el) {
    if (this.isOtherSelectedFunds(el.value)) {
      return; // Do nothing if it's disabled
    }

    event.preventDefault();
    if (this.selectedFund && this.selectedFund === el.value) {
      el.checked = false;
      this.selectedFund = null;
      this.setToNull = true;
    } else {
      this.selectedFund = el.value;
      el.checked = true;
      this.setToNull = false;
    }
  }

  saveAndClose(): void {
    this.dialogRef.close(this.selectedFund);
  }

  initSelectedFund(responseFunds: KiwiMonsterFund[]): KiwiMonsterFund {
    if (this.selectedFund?.Id) {
      return this.selectedFund;
    }

    if (this.setToNull) {
      return null;
    }

    let fund: KiwiMonsterFund = new KiwiMonsterFund();
    if (this.data.CurrentPreferredFund.QmOfferSchemeFundId > 0) {
      if (responseFunds.find(f => f.Id === this.data.CurrentPreferredFund.QmOfferSchemeFundId)) {
        fund = responseFunds.find(f => f.Id === this.data.CurrentPreferredFund.QmOfferSchemeFundId);
      } else {
        fund.Id = this.data.CurrentPreferredFund.QmOfferSchemeFundId;
        fund.SchemeName = this.data.CurrentPreferredFund.SchemeName;
        fund.FundName = this.data.CurrentPreferredFund.FundName;
      }
    }
    return fund;
  }

  isOtherSelectedFunds(fund: KiwiMonsterFund): boolean {
    return this.data.OtherSelectedIds.includes(fund.Id) ? true : false;
  }

  onFundTypeChange(): void {
    this.loadData();
  }
  
  unselectedFund(): void {
    this.selectedFund = null;
    this.setToNull = true;
  }

}
