import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PublicPageComponent } from './public.page.component';
import { PrivatePageComponent } from './private.page.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/login/signup.component';
import { VerifyDeviceComponent } from './components/login/verify-device.component';
import { ResetPasswordComponent } from './components/login/reset-password.component';
import { UpdatePaPasswordComponent } from './components/login/update-pa-password.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardArchivesComponent } from './components/dashboard/dashboard-archives.component';
import { AboutOverviewComponent } from './components/about/about-overview.component';
import { AboutUsingQuotemonsterComponent } from './components/about/about-using-quotemonster.component';
import { AboutFaqsComponent } from './components/about/about-faqs.component';
import { ContactComponent } from './components/contact/contact.component';
import { NoticeComponent } from './components/notice/notice.component';
import { QuoteClientComponent } from './components/quote/quote-client.component';
import { QuoteCheckmonsterComponent } from './components/quote/quote-checkmonster.component';
import { QuotePersonalBenefitComponent } from './components/quote/quote-p-benefit.component';
import { QuoteCompareComponent } from './components/quote/quote-compare.component';
import { QuoteReportComponent } from './components/quote/quote-report.component';
import { NeedAnalysisNatureComponent } from './components/need-analysis/need-analysis-nature.component';
import { NeedAnalysisScopeComponent } from './components/need-analysis/need-analysis-scope.component';
import { NeedAnalysisObjectivesComponent } from './components/need-analysis/need-analysis-objectives.component';
import { NeedAnalysisExistingComponent } from './components/need-analysis/need-analysis-existing.component';
import { NeedAnalysisFinancialComponent } from './components/need-analysis/need-analysis-financial.component';
import { NeedAnalysisNeedCalComponent } from './components/need-analysis/need-analysis-need-cal.component';
import { NeedAnalysisRecommendationComponent } from './components/need-analysis/need-analysis-recommendation.component';
import { QuoteSettingComponent } from './components/setting/quote-setting.component';
import { UserSettingComponent } from './components/setting/user-setting.component';
import { CommissionSettingComponent } from './components/setting/commission-setting.component';
import { OccupationSettingComponent } from './components/setting/occupation-setting.component';
import { NeedSettingComponent } from './components/setting/need-setting.component';
import { BuyNowSettingComponent } from './components/setting/buy-now-setting.component';
import { CrmSettingComponent } from './components/setting/crm-setting.component';
import { SoaSettingComponent } from './components/setting/soa-setting.component';
import { SecuritySettingComponent } from './components/setting/security-setting.component';
import { MainSettingComponent } from './components/setting/main-setting.component';
import { ResearchHomeComponent } from './components/research/home/research-home.component';
import { ResearchHomeOurApproachComponent } from './components/research/home/research-home.ourApproach.component';
import { ResearchHomeFeaturesComponent } from './components/research/home/research-home.features.component';
import { ResearchHomeMethodologyComponent } from './components/research/home/research-home.methodology.component';
import { ResearchHomeFaqsComponent } from './components/research/home/research-home.faqs.component';
import { ResearchHomeAdvertisingComponent } from './components/research/home/research-home.advertising.component';
import { ResearchHomeInfographicsComponent } from './components/research/home/research-home.infographics.component';
import { ResearchToolsIndependentResearchComponent } from './components/research/tools/research-tools.independentResearch.component';
import { ResearchToolsFuneralPlansComponent } from './components/research/tools/research-tools.funeralPlans.component';
import { ResearchToolsBusinessOverheadsCoverComponent } from './components/research/tools/research-tools.businessOverheadsCover.component';
import { ResearchToolsDisabilityClaimsModelComponent } from './components/research/tools/research-tools.disabilityClaimsModel.component';
import { ResearchToolsKeyPersonRatingComponent } from './components/research/tools/research-tools.keyPersonRating.component';
import { ResearchToolsLegacyResearchComponent } from './components/research/tools/research-tools.legacyResearch.component';
import { ResearchToolsInsurerSelectionScreenComponent } from './components/research/tools/research-tools.insurerSelectionScreen.component';
import { ResearchPolicyWordingComponent } from './components/research/policy/research-policy-wording.component';
import { ResearchPolicyDocumentComponent } from './components/research/policy/research-policy-document.component';
import { ResearchSubscriptionComponent } from './components/research/subscription/research-subscription.component';
import { ResearchSubscriptionPaymentsComponent } from './components/research/subscription/research-subscription-payments.component';
import { ResearchSubscriptionBillingAddressComponent } from './components/research/subscription/research-subscription-billing-address';
import { ResearchSubscriptionInvoicesComponent } from './components/research/subscription/research-subscription-invoices.component';
import { ResearchSubscriptionTermsComponent } from './components/research/subscription/research-subscription-terms.component';
import { ResearchSubscriptionExistingLicensesComponent } from './components/research/subscription/research-subscription-existing-licenses.component';
import { ResearchSubscriptionCompletePaymentComponent } from './components/research/subscription/research-subscription-completePayment.component';
import { UserProfileComponent } from './components/setting/user-profile.component';
import { ResearchLicenseOrderComponent } from './components/research/subscription/research-license-order.component';
import { ResearchLicenseOrderPaymentSelectComponent } from './components/research/subscription/research-license-orderPaymentSelect.component';
import { ResearchSettingComponent } from './components/setting/research-setting.component';
import { ACEListComponent } from './components/research/tools/adviser-claims-experience/ace-list/ace-list.component';
import { ACEDetailsComponent } from './components/research/tools/adviser-claims-experience/ace-details/ace-details.component';
import { ACECommentsComponent } from './components/research/tools/adviser-claims-experience/ace-comments/ace-comments.component';
import { CpdTrainingRequestComponent } from './components/research/tools/cpd-training-request-component/cpd-training-request-component.component';
import { StandaloneHeadToHeadComponent } from './components/research/tools/standalone-head-to-head/standalone-head-to-head.component';
import { MfaMethodSelectComponent } from './components/login/mfa-method-select/mfa-method-select.component';
import { QuoteWizardClientOverviewComponent } from './components/quote/quote-wizard/quote-wizard-client-overview.component';
import { CanDeactivateGuard } from './components/shared/can-deactivate-guard.service';
import { QuoteWizardSuggestionComponent } from './components/quote/quote-wizard/quote-wizard-suggestion/quote-wizard-suggestion.component';
import { UploadPolicyComponent } from './components/quote/policy-based-quote/upload-policy.component';
import { PolicySummaryComponent } from './components/quote/policy-based-quote/policy-summary.component';
import { FactFindDashboardComponent } from './components/fact-find/fact-find-dashboard/fact-find-dashboard.component';
import { FactFindPublicComponent } from './components/fact-find/fact-find-public/fact-find-public.component';

import { FactFindDetailsComponent } from './components/fact-find/fact-find-details/fact-find-details.component';

import { BusinessDashboardComponent } from './components/business/dashboard/business-dashboard.component';
import { BusinessDetailComponent } from './components/business/detail/business-detail.component';
import { BusinessPeopleComponent } from './components/business/people/people.component';
import { BusinessFinancialPositionComponent } from './components/business/financial-position/financial-position.component';
import { BusinessFinancialPerformanceComponent } from './components/business/financial-performance/financial-performance.component';
import { BusinessExistingCoverComponent } from './components/business/existing-covers/bus-existing-covers.component';
import { BusinessQuoteBenefitDetailComponent } from './components/business/quote/benefit/bus-quote-benefit.component';
import { BusinessNcNatureComponent } from './components/business/nature/bus-nc-nature.component';
import { BusinessNcObjectivesComponent } from './components/business/objectives/bus-nc-objectives.component';
import { BusinessNcScopePrioritiesComponent } from './components/business/scope-and-priorities/bus-nc-scope-priorities.component';
import { BusinessNcCalculationsComponent } from './components/business/nc-calculations/bus-nc-calculations.component';
import { BusinessQuoteSettingComponent } from './components/setting/business/business-quote-setting/business-quote-setting.component';
import { BusinessQuoteResearchIndexComponent } from './components/business/quote/research/business-quote-research-index.component';
import { BusinessQuoteReportComponent } from './components/business/quote/report/business-quote-report.component';
import { BusinessSoaSettingComponent } from './components/setting/business/business-soa-setting/business-soa-setting.component';
import { AmAuthGuard } from './tools/am-auth.guard';

import {BookTrainingClaimTrialComponent} from './components/about/book-training-claim-trial.component';
import { OauthLoginComponent } from './components/third-party-company/oauth-login/oauth-login.component';
import { OneClickLoginComponent } from './components/third-party-company/one-click-login/one-click-login.component';
import { QmAuthGuard } from './tools/qm-auth.guard';
import { KiwiMonsterDashboardComponent } from './components/kiwimonster/kiwimonster-dashboard.component';
import { KiwiMonsterClientComponent } from './components/kiwimonster/kiwimonster-client/kiwimonster-client.component';
import { KiwiMonsterKiwiSaverComponent } from './components/kiwimonster/kiwimonster-kiwisaver/kiwimonster-kiwisaver.component';
import { KiwiMonsterCompareComponent } from './components/kiwimonster/kiwimonster-compare/kiwimonster-compare.component';
import { PlatformSettingComponent } from './components/setting/platform-setting/platform-setting.component';
import { KiwiMonsterHomeComponent } from './components/home/kiwimonster-home/kiwimonster-home.component';
import { HomeRedirectGuard } from './tools/home-redirect.guard';
import { KiwiMonsterReportComponent } from './components/kiwimonster/kiwimonster-report/kiwimonster-report.component';
import { PricingComponent } from './components/shared/pricing/pricing.component';


const appRoutes: Routes = [
    {
        path: '', redirectTo: 'home', pathMatch: 'full'
    },
    // for all public page
    {
        path: '',
        component: PublicPageComponent,
        children: [
            {
                path: 'home', component: HomeComponent, canActivate: [HomeRedirectGuard]
            },
            {
                path: 'km/home', component: KiwiMonsterHomeComponent,
            },
            {
                path: 'pricing', component: PricingComponent,
            },
            {
                path: 'login', component: LoginComponent,
            },
            {
                path: 'palogin', component: LoginComponent,
            },
            {
                path: 'signup', component: SignupComponent,
            },
            {
                path: 'signup/student', component: SignupComponent,
            },
            {
                path: 'mfa-methods-select', component: MfaMethodSelectComponent
            },
            {
                path: 'verify-device', component: VerifyDeviceComponent
            },
            {
                path: 'resetpassword', component: ResetPasswordComponent
            },
            {
                path: 'pa/resetpassword', component: ResetPasswordComponent
            },
            {
                path: 'updatePaDetail', component: UpdatePaPasswordComponent
            },
            {
                path: 'public/about-overview', component: AboutOverviewComponent,
            },
            {
                path: 'public/about-using-quotemonster', component: AboutUsingQuotemonsterComponent,
            },
            {
                path: 'public/about-faqs', component: AboutFaqsComponent,
            },
            {
                path: 'public/contact', component: ContactComponent,
            },
            {
                path: 'research/subscription/complete-payment', component: ResearchSubscriptionCompletePaymentComponent,
            },
            {
                path: 'public/cpd', component: CpdTrainingRequestComponent,
            },
            {
                path: 'public/clientfactfind', component: FactFindPublicComponent
            },
            {
                path: 'public/fact-find-details', component: FactFindDetailsComponent
            },
            {
                path: 'oauth', component: OauthLoginComponent
            },
            {
                path: 'tup/oneclicklogin', component: OneClickLoginComponent
            }
        ]
    },

    // for all private page
    {
        path: '',
        component: PrivatePageComponent,
        children: [
            {
                path: 'dashboard', component: DashboardComponent,
            },
            {
                path: 'dashboard-archives', component: DashboardArchivesComponent,
            },
            {
                path: 'public/notice', component: NoticeComponent,
            },
            // quote
            {
                path: 'quote/client', component: QuoteClientComponent
            }, {
                path: 'quote/compare', component: QuoteCompareComponent
            }, {
                path: 'quote/checkmonster', component: QuoteCheckmonsterComponent
            }, {
                path: 'quote/report', component: QuoteReportComponent
            }, {
                path: 'quote/personal/benefit', component: QuotePersonalBenefitComponent
            },
            // quote wizard
            {
                path: 'quote-wizard/client-overview', component: QuoteWizardClientOverviewComponent, canActivate:[QmAuthGuard]
            },
            {
                path: 'quote-wizard/client-details', component: QuoteClientComponent, canActivate:[QmAuthGuard]
            },
            {
                path: 'quote-wizard/suggestion', component: QuoteWizardSuggestionComponent, canActivate:[QmAuthGuard]
            },
            {
                path: 'quote-wizard/personal/benefit', component: QuotePersonalBenefitComponent, canActivate:[QmAuthGuard]
            },
            {
                path: 'quote-wizard/checkmonster', component: QuoteCheckmonsterComponent, canActivate:[QmAuthGuard]
            },
            {
                path: 'quote-wizard/compare', component: QuoteCompareComponent, canActivate:[QmAuthGuard]
            },
            {
                path: 'quote-wizard/report', component: QuoteReportComponent, canActivate:[QmAuthGuard]
            },
            // policy-based quote
            {
                path: 'policy-based/upload-file', component: UploadPolicyComponent
            },
            {
                path: 'policy-based/summary', component: PolicySummaryComponent
            },
            {
                path: 'policy-based/client', component: QuoteClientComponent
            },
            {
                path: 'policy-based/personal/benefit', component: QuotePersonalBenefitComponent
            },
            {
                path: 'policy-based/checkmonster', component: QuoteCheckmonsterComponent
            },
            {
                path: 'policy-based/compare', component: QuoteCompareComponent
            },
            {
                path: 'policy-based/report', component: QuoteReportComponent
            },
            // need analysis
            {
                path: 'needanalysis/nature', component: NeedAnalysisNatureComponent
            },
            {
                path: 'needanalysis/scope', component: NeedAnalysisScopeComponent
            },
            {
                path: 'needanalysis/objectives', component: NeedAnalysisObjectivesComponent
            },
            {
                path: 'needanalysis/existing', component: NeedAnalysisExistingComponent
            },
            {
                path: 'needanalysis/financial', component: NeedAnalysisFinancialComponent
            },
            {
                path: 'needanalysis/calculations', component: NeedAnalysisNeedCalComponent
            },
            {
                path: 'needanalysis/recommendationnotepad', component: NeedAnalysisRecommendationComponent
            },

            // setting
            {
                path: 'setting/quote', component: QuoteSettingComponent
            },
            {
                path: 'setting/user', component: UserSettingComponent, canDeactivate: [CanDeactivateGuard]
            },
            {
                path: 'setting/commission', component: CommissionSettingComponent
            },
            {
                path: 'setting/occupation', component: OccupationSettingComponent
            },
            {
                path: 'setting/need', component: NeedSettingComponent
            },
            {
                path: 'setting/buy-now', component: BuyNowSettingComponent
            },
            {
                path: 'setting/crm', component: CrmSettingComponent
            },
            {
                path: 'setting/soa', component: SoaSettingComponent
            },
            {
                path: 'setting/security', component: SecuritySettingComponent
            },
            {
                path: 'setting/pa-security', component: SecuritySettingComponent
            },
            {
                path: 'setting/main', component: MainSettingComponent
            },
            {
                path: 'setting/user-profile', component: UserProfileComponent
            },

            {
                path: 'setting/research', component: ResearchSettingComponent
            },
            {
                path: 'business/setting/quote', component: BusinessQuoteSettingComponent
            },
            {
                path: 'business/setting/soa', component: BusinessSoaSettingComponent
            },
            {
                path: 'setting/platform', component: PlatformSettingComponent
            },
            // research
            {
                path: 'research/home/overview', component: ResearchHomeComponent
            },
            {
                path: 'research/home/our-approach', component: ResearchHomeOurApproachComponent
            },
            {
                path: 'research/home/features', component: ResearchHomeFeaturesComponent
            },
            {
                path: 'research/home/methodology', component: ResearchHomeMethodologyComponent
            },
            {
                path: 'research/home/faqs', component: ResearchHomeFaqsComponent
            },
            {
                path: 'research/home/advertising', component: ResearchHomeAdvertisingComponent
            },
            {
                path: 'research/home/infographics', component: ResearchHomeInfographicsComponent
            },
            {
                path: 'research/tools/independent-research', component: ResearchToolsIndependentResearchComponent
            },
            {
                path: 'research/tools/funeral-plans', component: ResearchToolsFuneralPlansComponent
            },
            {
                path: 'research/tools/business-overheads-cover', component: ResearchToolsBusinessOverheadsCoverComponent
            },
            {
                path: 'research/tools/disability-claims-model', component: ResearchToolsDisabilityClaimsModelComponent
            },
            {
                path: 'research/tools/key-person-rating', component: ResearchToolsKeyPersonRatingComponent
            },
            {
                path: 'research/tools/legacy-research', component: ResearchToolsLegacyResearchComponent
            },
            {
                path: 'research/tools/insurer-selection-screen', component: ResearchToolsInsurerSelectionScreenComponent
            },
            {
                path: 'research/tools/adviser-claims-experience', component: ACEListComponent
            },
            {
                path: 'research/tools/adviser-claims-experience-details/create', component: ACEDetailsComponent
            },
            {
                path: 'research/tools/adviser-claims-experience-details/:aceId', component: ACEDetailsComponent
            },
            {
                path: 'research/tools/adviser-claims-experience-comments/:aceId', component: ACECommentsComponent
            },
            {
                path: 'research/tools/standalone-head-to-head', component: StandaloneHeadToHeadComponent
            },
            {
                path: 'research/policy/wording', component: ResearchPolicyWordingComponent
            },
            {
                path: 'research/policy/document', component: ResearchPolicyDocumentComponent
            },

            // subscription
            {
                path: 'research/subscription/plan', component: ResearchSubscriptionComponent
            },
            {
                path: 'research/subscription/payments', component: ResearchSubscriptionPaymentsComponent
            },
            {
                path: 'research/subscription/billing-address', component: ResearchSubscriptionBillingAddressComponent
            },
            {
                path: 'research/subscription/invoices', component: ResearchSubscriptionInvoicesComponent
            },
            {
                path: 'research/subscription/terms', component: ResearchSubscriptionTermsComponent
            },
            {
                path: 'research/subscription/existing-licenses', component: ResearchSubscriptionExistingLicensesComponent
            },
            {
                path: 'research/subscription/order/:licenseId', component: ResearchLicenseOrderComponent
            },
            {
                path: 'research/subscription/payment-select/:stamp', component: ResearchLicenseOrderPaymentSelectComponent
            },
            // fact finds
            {
                path: 'fact-find/dashboard', component: FactFindDashboardComponent,
            },
            {
                path: 'fact-find/details', component: FactFindDetailsComponent, canDeactivate: [CanDeactivateGuard]
            },
            // business insurance
            {
                path:'business/dashboard', component: BusinessDashboardComponent
            },
            {
                path:'business/detail', component:BusinessDetailComponent, canActivate:[AmAuthGuard]
            },
            {
                path:'business/people', component: BusinessPeopleComponent, canActivate:[AmAuthGuard]
            },
            {
                path:'business/nc/financial-position', component:BusinessFinancialPositionComponent, canActivate:[AmAuthGuard]
            },
            {
                path: 'business/nc/financial-performance', component:BusinessFinancialPerformanceComponent, canActivate:[AmAuthGuard]
            },
            {
                path:'business/nc/existing-covers', component: BusinessExistingCoverComponent, canActivate:[AmAuthGuard]
            },
            {
                path:'business/nc/nature', component: BusinessNcNatureComponent, canActivate:[AmAuthGuard]
            },
            {
                path:'business/nc/objectives', component: BusinessNcObjectivesComponent, canActivate:[AmAuthGuard]
            },
            {
                path:'business/nc/scope-priorities', component: BusinessNcScopePrioritiesComponent, canActivate:[AmAuthGuard]
            },
            {
                path:'business/nc/calculations', component: BusinessNcCalculationsComponent, canActivate:[AmAuthGuard]
            },
            {
                path:'business/quote/people-benefit-detail', component:BusinessQuoteBenefitDetailComponent, canActivate:[AmAuthGuard]
            },
            {
                path:'business/quote/research', component:BusinessQuoteResearchIndexComponent, canActivate:[AmAuthGuard]
            },
            {
                path:'business/quote/report', component:BusinessQuoteReportComponent, canActivate:[AmAuthGuard]
            },
            {
                path: 'public/book-training-claim-free-trial', component : BookTrainingClaimTrialComponent
            },
            // kiwiMonster
            {
                path:'kiwimonster/dashboard', component: KiwiMonsterDashboardComponent
            },
            {
                path:'kiwimonster/quote/client', component: KiwiMonsterClientComponent
            },
            {
                path:'kiwimonster/quote/kiwisaver', component: KiwiMonsterKiwiSaverComponent
            },
            {
                path:'kiwimonster/quote/compare', component: KiwiMonsterCompareComponent
            },
            {
                path:'kiwimonster/quote/report', component: KiwiMonsterReportComponent
            },
        ]
    },

    // all other
    {
        path: '**', redirectTo: 'home', pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(
        appRoutes
        , {
            // Router options
            useHash: false,
            preloadingStrategy: PreloadAllModules,
            enableTracing: false,
            initialNavigation: 'enabled',
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'top'
        }
    )],
    exports: [RouterModule]
})
export class AppRoutingModule { }
