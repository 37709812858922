import { Component, OnInit } from '@angular/core';
import { AvailableBenefit, PriceHistories } from 'src/app/models/price-history.model';
import { LoginService } from 'src/app/service/login.service';
import { QuoteStepComparePrice } from 'src/app/service/quote/quote.step.compare.price';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
  selector: 'quote-compare-price-change-history',
  templateUrl: './quote-compare-price-change-history.component.html',
  styleUrls: ['./quote-compare-price-change-history.component.scss']
})
export class QuoteComparePriceChangeHistoryComponent implements OnInit {
  availableBenefits: AvailableBenefit[] = [];
  selectedBenefit: AvailableBenefit;

  errorMessage: string = '';
  yearBefore10Years: number = 0;
  showTable: boolean = false;
  tableData: { provider: string, year: number[], data: number[]; color: string }[] = [];

  constructor (
    public quoteStepComparePrice: QuoteStepComparePrice,
    public sharedFunction: SharedFunctionService,
    public loginService: LoginService
  ) { }

  ngOnInit(): void {
    this.quoteStepComparePrice.init();

    this.availableBenefits = this.quoteStepComparePrice.availableBenefits;

    this.selectedBenefit = this.availableBenefits[0];

    // call api
    this.getHistoryData();

    this.yearBefore10Years = new Date().getFullYear() - 10;
  }

  getHistoryData(): void {
    this.errorMessage = '';
    this.quoteStepComparePrice.showDinoLoading();

    let requestData = this.quoteStepComparePrice.createPriceHistoriesRequestData(this.selectedBenefit);

    this.quoteStepComparePrice.getPriceHistoriesData(requestData, (response: PriceHistories[]) => {
      if (response && response.length > 0) {
        this.quoteStepComparePrice.addProviderName(this.selectedBenefit, response);
     
        if (this.quoteStepComparePrice.isAllProviderDataZero(response)) {
          this.errorMessage = this.quoteStepComparePrice.sharedFunction.getUiMessageByCode('PriceChangeHistory-ERROR-NoData');
          this.quoteStepComparePrice.closeDinoLoading();
        } else {
          this.tableData = this.quoteStepComparePrice.generateTableData(response);
          this.quoteStepComparePrice.resetNullValue(response);
          let lineChartData = this.quoteStepComparePrice.generateLineChartData(response);
          let title: string = this.quoteStepComparePrice.generateChartTitle(this.selectedBenefit);
          this.quoteStepComparePrice.showCharts(title, lineChartData, 'priceChangeHistoryChartContainer', () => {
            setTimeout(() => { this.quoteStepComparePrice.closeDinoLoading(); }, 1000)
              ;
          });
        }
      } else {
        this.errorMessage = this.quoteStepComparePrice.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong');
        this.quoteStepComparePrice.closeDinoLoading();
      }
    });
  }

}
