<div class="row">
  <div class="col-md-12">
    <h3>Kiwimonster - Quote</h3>
    <hr />
  </div>
</div>

<ul class="nav nav-wizard quote-step-menu flex-nowrap">
  <li [ngClass]="{'active': currentKMQuoteStep > 0}">
    <a class='w-100 d-block text-center pt-2 font-weight-bold'
       (click)='loginService.doGoogleTracking( "kiwiMonster-quote-step-menu", "goUploadPolicy", "");'>Client</a>
  </li>
  <li [ngClass]="{'active': currentKMQuoteStep > 1}">
    <a class='w-100 d-block text-center pt-2 font-weight-bold'
       (click)='loginService.doGoogleTracking( "kiwiMonster-quote-step-menu", "goPolicySummary", "");'>KiwiSaver</a>
  </li>
  <li [ngClass]="{'active': currentKMQuoteStep > 2}">
    <a class='w-100 d-block text-center pt-2 font-weight-bold'
       (click)='loginService.doGoogleTracking( "kiwiMonster-quote-step-menu", "goClientDetail", "");'>Compare</a>
  </li>
  <li [ngClass]="{'active': currentKMQuoteStep > 3}">
    <a class='w-100 d-block text-center pt-2 font-weight-bold'
       (click)='loginService.doGoogleTracking( "kiwiMonster-quote-step-menu", "goBenefits", "");'>Report</a>
  </li>
</ul>