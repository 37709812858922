<!-- loading data layout start -->
<div class="loading-screen text-center vh-100 d-flex align-items-center justify-content-center" *ngIf="loadingData">
  <div>
      <img class="dino-loading-img" src="/assets/images/quote_monster_crunching_animation.gif?v=2">
      <h3>Loading data.</h3>
  </div>
</div>
<!-- loading data layout end -->


<!-- after loading data layout start -->
<ng-container *ngIf='loadingData === false'>
  <div class='row m-0 alert alert-danger rounded-0' *ngIf="!hideRedirectMessage && isDevMode">
    <ng-container *ngTemplateOutlet="redirectMessage"></ng-container>
  </div>

  <div class="container-fluid">

    <!-- home page take over start-->
    <div class="bg-white row justify-content-center">
      <app-ad-public-show [pathName]='routerName' [adBlocker]='adBlocker'></app-ad-public-show>
    </div>
    <!-- home page take over end -->
    <div class='d-flex flex-column justify-content-between min-vh-100'>

      <div id='qmMainTopHeader' class="qm-header">
        <nav-menu [pathName]='routerName' [isOauth]='isOauth'></nav-menu>
      </div>

      <!-- here is main container start -->
      <div class='flex-fill'>
        <router-outlet></router-outlet>
      </div>
      <!-- here is main container end -->

      <footer id='qmMainBottomFooter' class="row text-white qm-bg-darkGray py-block footer">
        <!-- before login -->
        <div class='container' *ngIf='!loginService.isLogin() && !isOauth'>
          <!-- nav links & social network & logo-->
          <div class="row justify-content-between">
            <div class="col-6 col-lg-2 pb-5">
              <h6 class="text-blue mb-4">Overview</h6>
              <mat-nav-list [disableRipple]="true" role="navigation" aria-label="overview links">
                <a mat-list-item class='links-group' [routerLink]="['/home']"
                  (click)="$event.target.offsetParent.blur()">Home</a>
                <!-- TODO: uncomment after creating the product page -->
                <!-- <a class="text-white" mat-list-item href="">Product</a> -->
                <a mat-list-item class='links-group' [routerLink]="['/pricing']"
                  (click)="$event.target.offsetParent.blur()">Pricing</a>
              </mat-nav-list>
            </div>
            <div class="col-6 col-lg-2 pb-5">
              <h6 class="text-blue mb-4">Company</h6>
              <mat-nav-list [disableRipple]="true" role="navigation" aria-label="company links">
                <!-- TODO: uncomment after creating the resources page -->
                <!-- <a class="text-white" mat-list-item href="">Resources</a> -->
                <a mat-list-item class='links-group' [routerLink]="['/public/about-overview']"
                  (click)="$event.target.offsetParent.blur()">About Us</a>
                <a mat-list-item class='links-group' href="https://www.chatswood.co.nz/" target="_blank" rel="noopener"
                  (click)="$event.target.offsetParent.blur()">Blog</a>
              </mat-nav-list>
            </div>
            <div class="col-6 col-lg-2 pb-5">
              <h6 class="text-blue mb-4">Account</h6>
              <mat-nav-list [disableRipple]="true" role="navigation" aria-label="account links">
                <a mat-list-item class='links-group' [routerLink]="['/login']"
                  (click)="$event.target.offsetParent.blur()">Login</a>
                <a mat-list-item class='links-group' href="https://quotemonster.freshdesk.com/support/home" target="_blank"
                  rel="noopener" (click)="$event.target.offsetParent.blur()">Help</a>
                <a mat-list-item class='links-group' [routerLink]="['/public/contact']"
                  (click)="$event.target.offsetParent.blur()">Contact</a>
              </mat-nav-list>
            </div>

            <div class="col-6 col-lg-6 pb-5 d-flex flex-column justify-content-around text-lg-right">
              <!-- social network -->
              <div>
                <a mat-fab color="qm-black" class="mr-1 mr-sm-3 mb-3 social-links"
                  href="https://www.linkedin.com/company/quality-product-research-ltd" target="_blank" rel="noopener"
                  referrerpolicy="no-referrer">
                  <img width="24" height="24" src="../assets/images/icon/linkedin-white@2x.png?v=2" title='LinkedIn' />
                </a>
                <a mat-fab color="qm-black" class="mr-1 mr-sm-3 mb-3 social-links" href="https://x.com/QPRQuotemonster"
                  target="_blank"
                  rel="noopener" referrerpolicy="no-referrer">
                  <img width="24" height="24" src="../assets/images/icon/x-logo-white.png" title='x.com' />
                </a>
                <a mat-fab color="qm-black" class="mb-3 social-links"
                  href="https://www.youtube.com/channel/UC9gx8xqu7plB7RylVBm-BNg" target="_blank"
                  rel="noopener" referrerpolicy="no-referrer">
                  <img width="30" height="30" src="../assets/images/icon/youtube-white.png?v=2" title='Youtube' />
                </a>
              </div>
              <!-- social network -->
              <!-- logo -->
              <div>
                <img width="257" height="84" src="../assets/images/qpr-footer-logo/qpr-footer-logo@2x.png" alt="qpr logo">
              </div>
              <!-- logo -->
            </div>
          </div>
          <!-- nav links & social network & logo end-->

        </div>
        <!-- before login end -->

        <!-- after login and OAuth page (public authorization) -->
        <div class='container' *ngIf='loginService.isLogin() || isOauth'>
          <div class="row mb-5">
            <!-- social network -->
            <div class="col-lg-6 mb-4 mb-lg-0 text-center text-lg-left">
              <a mat-fab color="qm-black" class="mr-3 mb-3"
                href="https://www.linkedin.com/company/quality-product-research-ltd" target="_blank" rel="noopener"
                referrerpolicy="no-referrer">
                <img width="24" height="24" src="../assets/images/icon/linkedin-white@2x.png?v=2" title='LinkedIn' />
              </a>
              <a mat-fab color="qm-black" class="mr-3 mb-3" href="https://x.com/QPRQuotemonster" target="_blank"
                rel="noopener" referrerpolicy="no-referrer">
                <img width="24" height="24" src="../assets/images/icon/x-logo-white.png" title='x.com' />
              </a>
              <a mat-fab color="qm-black" class="mr-0 mr-sm-5 mb-3"
                href="https://www.youtube.com/channel/UC9gx8xqu7plB7RylVBm-BNg" target="_blank"
                rel="noopener" referrerpolicy="no-referrer">
                <img width="30" height="30" src="../assets/images/icon/youtube-white.png?v=2" title='Youtube' />
              </a>
              <div class="d-block d-sm-none"></div>
              <a [routerLink]="['/public/about-overview']" class="mr-4 links-group" (click)="$event.target.blur()">About
                Us</a>
              <a [routerLink]="['/public/contact']" class="links-group" (click)="$event.target.blur()">Contact</a>
            </div>
            <!-- social network -->
            <!-- logo -->
            <div class="col-lg-6 text-center text-lg-right">
              <img width="257" height="84" src="../assets/images/qpr-footer-logo/qpr-footer-logo@2x.png?v=2" alt="qpr logo">
            </div>
            <!-- logo -->
          </div>
        </div>
        <!-- after login end -->

        <!-- copyright & site version & terms-->
        <div class="w-100 border-top border-dark">
          <div class="container">
            <div class="row">
              <!-- copyright & site version-->
              <div class="pt-5 col-lg-7 text-center text-lg-left">
                <h6 class="text-white text-uppercase">
                  Copyright &copy; {{copyrightYear}} All Rights Reserved by Quality Product Research Limited
                </h6>
                <h6 class="text-white text-uppercase">
                  Quote Engine v.{{quoteEngineVersion}}
                  &nbsp;&nbsp;| &nbsp;&nbsp;
                  QPR v.{{qprDbVersion}}
                  &nbsp;&nbsp;| &nbsp;&nbsp;
                  Web v.4.6.3
                  <span class="d-none d-lg-inline">&nbsp;&nbsp;|
                    &nbsp;&nbsp;</span>
                  <div class="w-100 d-block d-lg-none pt-2"></div>
                  <a class="text-blue" href='https://status.quotemonster.co.nz/' target="_blank"
                    referrerpolicy="no-referrer">Service
                    Status</a>
                </h6>
              </div>
              <!-- copyright & site version end-->
              <!-- terms -->
              <div class="col-lg-5 pt-5 ml-lg-auto text-center text-lg-right">
                <div class="d-flex flex-column justify-content-between h-100">
                  <h6 class="text-uppercase">
                    <a class="text-blue" href='/TermsOfUse.html' target="_blank" rel="noopener">
                      Terms of Use & Privacy Policy
                    </a>
                  </h6>
                  <h6 class="text-uppercase">
                    <a class="text-blue" href={{outsourcePASUrl}} target="_blank" rel="noopener">
                      Outsource Provider Assurance Statement
                    </a>
                  </h6>
                </div>
              </div>
              <!-- terms end-->

            </div>
          </div>
        </div>
        <!-- copyright & site version & terms end-->
      </footer>
    </div>
  </div>
</ng-container>
<!-- after loading data layout end -->


<!-- dev mode only start-->
<!-- <div
  class='row m-0 alert alert-danger text-center d-block bottom-fixed'
  *ngIf='isDevMode'>
  --- TEST SITE UI V2 for new API---
</div> -->
<!-- dev mode only end-->

<!-- SystemNotices start -->
<div class='row m-0 alert alert-danger text-center bottom-fixed rounded-0'
  *ngIf='systemNoticesList.length > 0' [ngClass]="hideSystemNotices?'d-none':'d-block'">
  <button mat-icon-button class="float-left qm-icon-btn-sm" (click)="hideSystemNotices = !hideSystemNotices">
    <span class="material-icons" aria-label="close">clear</span>
  </button>
  <ng-container *ngFor="let notice of systemNoticesList">
    <p class="mb-0"><strong>{{notice.Subject}}</strong>: {{notice.Contents}}</p>
  </ng-container>
</div>
<!-- SystemNotices end -->

<div class='row m-0 alert alert-danger bottom-fixed rounded-0' *ngIf="!hideRedirectMessage && isDevMode">
  <ng-container *ngTemplateOutlet="redirectMessage"></ng-container>
</div>

<ng-template #redirectMessage>
  <div class='col'>
    <p class="mb-0 text-center">Sorry, you have reached our test site. Please click <a
         href="https://quotemonster.co.nz/home">here</a> to go to Quotemonster.co.nz</p>
  </div>
  <div class='col-auto'>
    <button mat-icon-button class="qm-icon-btn-sm" (click)="hideRedirectMessage = !hideRedirectMessage">
      <span class="material-icons" aria-label="close">clear</span>
    </button>
  </div>
</ng-template>

