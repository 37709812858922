import { Component, OnInit } from '@angular/core';
import { HomeComponent } from '../home.component';
import { DataLoader } from 'src/app/service/data.loader';
import { QuoteRequiredData } from 'src/app/models/quote.required.data';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
  selector: 'kiwimonster-home',
  templateUrl: './kiwimonster-home.component.html',
  styleUrls: ['./kiwimonster-home.component.scss']
})
export class KiwiMonsterHomeComponent extends HomeComponent implements OnInit {
  kiwiMonsterLogoList: any[] = [];
  constructor (
    public dataLoader: DataLoader,
    public sharedFunction: SharedFunctionService
  ) {
    super(dataLoader, sharedFunction);
  }

  ngOnInit(): void {
    this.setSpecialHomePageTakeover();
    this.timestamp = new Date().getTime();
    let v: QuoteRequiredData = this.dataLoader.getRequiredData();
    this.kiwiMonsterLogoList = v.KiwiMonsterLogoList;
  }

}
