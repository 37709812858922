<div class="row qm-bg-catskillWhite py-block signup-page">
  <div class="container">
    <h2 class="mb-5 text-center">Sign up <span class="d-sm-none"><br></span>to Quotemonster {{isStudentSignup ? "for Student" : ""}}</h2>
    <div class="signup-card mx-auto py-5 px-3 px-sm-5 bg-white col-lg-8">
        <mat-vertical-stepper [linear]='true' #stepper>
          <ng-container *ngIf="!isStudentSignup">
            <!-- 1. check has fsp or not -->
            <mat-step [stepControl]="s1FormGroup">
                <ng-template matStepLabel>
                <h5 class="mb-0">Your Adviser Type</h5>
                <span class="qm-text-sm font-weight-lighter">{{sharedFunction.getUiMessageByCode("SignUp-INFO-FSP")}}</span>
                </ng-template>

                <form [formGroup]="s1FormGroup">
                <div>
                    <mat-form-field floatLabel="never">
                    <mat-select placeholder="Adviser Type" formControlName="AdviserType" required>
                        <ng-container *ngFor="let adviserType of quoteRequiredData.UserAdviserTypeV2List">
                        <mat-option [value]="adviserType">
                            {{ adviserType.Name }}
                        </mat-option>
                        </ng-container>
                    </mat-select>
                    </mat-form-field>
                </div>

                <div>
                    <div class="alert alert-info " role="alert">
                    <h5 class="qm-text-lg alert-heading">
                        {{sharedFunction.getUiMessageByCode("Share-INFO-WhyNeeded")}}</h5>
                    <p class="qm-text-sm">
                        <span [innerHTML]='sharedFunction.getUiMessageByCode("SignUp-INFO-WhyIsThisNeededMessage")'></span>
                    </p>
                    </div>
                </div>

                <div>
                    <button matStepperNext [disabled]="s1FormGroup.invalid" mat-flat-button color="qm-blue" class='float-right qm-flat-btn-lg w-sm-100 mb-2 mb-sm-0'>Next</button>
                </div>
                </form>
            </mat-step>

            <!-- 2. if has fsp then let client to enter it: FSP number -->
            <mat-step [stepControl]="s2FormGroup">
                <form [formGroup]="s2FormGroup">
                <ng-template matStepLabel>
                    <h5 class="mb-0" *ngIf='s1FormGroup.value.AdviserType.Selected'>
                    Financial Service Provider (FSP)
                    </h5>
                    <h5 class="mb-0" *ngIf='!s1FormGroup.value.AdviserType.Selected'>
                    Industry Name
                    </h5>
                </ng-template>

                <div>
                    <mat-form-field>
                    <mat-label *ngIf='s1FormGroup.value.AdviserType.Selected'>
                        Financial Service Provider (FSP)
                    </mat-label>

                    <mat-label *ngIf='!s1FormGroup.value.AdviserType.Selected'>
                        Industry Name
                    </mat-label>

                    <input matInput formControlName="FSPNumberV2" placeholder="123456" required>
                    </mat-form-field>
                </div>

                <div>
                    <div class="alert alert-info" role="alert">
                    <h5 class="alert-heading">
                        {{sharedFunction.getUiMessageByCode("Share-INFO-WhyNeeded")}}</h5>
                    <p class="qm-text-sm">
                        <span [innerHTML]='sharedFunction.getUiMessageByCode("SignUp-INFO-WhyIsThisNeededMessage")'></span>
                    </p>
                    </div>
                    <div class="alert alert-danger" role="alert" *ngIf='errorMessage'>
                    <h5 class="alert-heading">Error!!!</h5>
                    <p class="qm-text-sm">
                        <span [innerHTML]='errorMessage'></span>
                    </p>
                    </div>
                </div>

                <div class='d-flex flex-column flex-sm-row justify-content-between'>
                    <button matStepperPrevious (click)='clearError(s2FormGroup)' mat-flat-button color="qm-blue" class='qm-flat-btn-lg w-sm-100 mb-2 mb-sm-0'>Back</button>
                    <button mat-flat-button [disabled]="s2FormGroup.invalid" color="qm-blue" class='qm-flat-btn-lg w-sm-100 mb-2 mb-sm-0'
                    (click)='checkFspNumberBeforeNext(s2FormGroup, s2FormGroup.value.FSPNumberV2, s1FormGroup.value.AdviserType.Selected)'>
                    Next
                    </button>
                </div>
                </form>
            </mat-step>

            <!-- 2.1. FAP detail -->
            <mat-step [stepControl]="s2d1FormGroup">
                <form [formGroup]="s2d1FormGroup">
                <ng-template matStepLabel>
                    <h5 class="mb-0">
                    Financial Advice Provider Detail
                    </h5>
                </ng-template>

                <div>
                    <mat-form-field>
                    <mat-label>
                        Financial Advice Provider Name
                    </mat-label>
                    <input matInput formControlName="FAPName" placeholder="Name" required>
                    </mat-form-field>

                    <mat-form-field class="fap-fsp-field">
                    <mat-label>
                        FAP FSP Number
                    </mat-label>
                    <input matInput formControlName="FSPNumberForFAP" placeholder="123456" required>
                    <span matPrefix>FSP&nbsp;&nbsp;</span>
                    </mat-form-field>
                </div>

                <div>
                    <div class="alert alert-info" role="alert">
                    <h5 class="alert-heading">
                        {{sharedFunction.getUiMessageByCode("Share-INFO-WhyNeeded")}}</h5>
                    <p class="qm-text-sm">
                        <span [innerHTML]='sharedFunction.getUiMessageByCode("SignUp-INFO-WhyIsThisNeededMessage")'></span>
                    </p>
                    </div>
                    <div class="alert alert-danger" role="alert" *ngIf='errorMessage'>
                    <h5 class="alert-heading">Error!!!</h5>
                    <p class="qm-text-sm">
                        <span [innerHTML]='errorMessage'></span>
                    </p>
                    </div>
                </div>

                <div class='d-flex flex-column flex-sm-row justify-content-between'>
                    <button matStepperPrevious (click)='clearError(s2d1FormGroup)' mat-flat-button color="qm-blue" class='qm-flat-btn-lg w-sm-100 mb-2 mb-sm-0'>Back</button>
                    <button mat-flat-button [disabled]="s2d1FormGroup.invalid" color="qm-blue" class='qm-flat-btn-lg w-sm-100 mb-2 mb-sm-0'
                    (click)='checkFspNumberBeforeNext(s2d1FormGroup, s2d1FormGroup.value.FSPNumberForFAP, false)'>
                    Next
                    </button>
                </div>
                </form>
            </mat-step>
          </ng-container>
          <!-- 3. login detail: email address, confirm email, password, confirm pass -->
          <mat-step [stepControl]="s3FormGroup">
            <form [formGroup]="s3FormGroup">
              <ng-template matStepLabel>
                <h5 class="mb-0">Your UserName and Password</h5>
              </ng-template>

              <div>
                <mat-form-field>
                  <input matInput placeholder="Login Email" formControlName="LoginEmail" required>
                </mat-form-field>

                <mat-form-field>
                  <input matInput placeholder="Confirm Email" formControlName="ConfirmEmail" required>
                  <mat-error *ngIf="s3FormGroup.errors && s3FormGroup.errors.EmailMatch">
                    {{sharedFunction.getUiMessageByCode("Share-ERROR-UsernameNotMatch")}}
                  </mat-error>
                </mat-form-field>

                <mat-form-field [ngClass]="{'mt-3':s3FormGroup.errors && s3FormGroup.errors.EmailMatch}">
                  <input matInput [type]="hidePassword ? 'password' : 'text'" formControlName="Password" placeholder="Password (Min 8 Characters)"
                    required (keyup)="onPasswordValueChanges($event.target.value)">
                  <button mat-icon-button type="button" matSuffix (click)="hidePassword = !hidePassword" [disableRipple]="true">
                    <span class="material-icons">{{hidePassword ? 'visibility_off' : 'visibility'}}</span>
                  </button>
                </mat-form-field>

                <mat-form-field>
                  <input matInput [type]="hideConfirmPassword ? 'password' : 'text'" formControlName="ConfirmPassword" placeholder="Confirm Password"
                    required>
                  <button mat-icon-button type="button" matSuffix (click)="hideConfirmPassword = !hideConfirmPassword" [disableRipple]="true">
                    <span class="material-icons">{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</span>
                  </button>
                  <mat-error *ngIf="s3FormGroup.errors && s3FormGroup.errors.MatchPassword" class="mb-3">
                    {{sharedFunction.getUiMessageByCode("Share-ERROR-PasswordNotMatch")}}
                  </mat-error>
                </mat-form-field>
              </div>
              <div [ngClass]="{'mt-3':s3FormGroup.errors && s3FormGroup.errors.MatchPassword}">
                <div class="alert alert-info" role="alert">
                  <h5 class="alert-heading">{{sharedFunction.getUiMessageByCode("Share-INFO-PasswordSecurityHeader")}}</h5>
                  <p class="qm-text-sm">
                    {{sharedFunction.getUiMessageByCode("Share-INFO-PasswordSecurity")}}
                  </p>
                  <hr>
                  <ng-container *ngFor="let item of passwordSecurity | keyvalue: sharedFunction.originalOrder">
                    <p class="mb-1" [ngClass]="isInitialState ? '': (item.value ? 'text-success' : 'text-danger')">
                      <span class="material-icons align-top mr-2">
                        {{isInitialState ? 'rule': (item.value ? 'check' : 'close')}}
                      </span>
                      {{sharedFunction.getUiMessageByCode("Share-INFO-" + item.key)}}
                    </p>
                  </ng-container>
                </div>

                <div class="alert alert-danger" role="alert" *ngIf='errorMessage'>
                  <h5 class="alert-heading">Error!!!</h5>
                  <p class="qm-text-sm">
                    <span [innerHTML]='errorMessage'></span>
                  </p>
                </div>
              </div>

              <div class='d-flex flex-column flex-sm-row justify-content-between'>
                <button matStepperPrevious (click)='clearError(s3FormGroup)' mat-flat-button color="qm-blue" class='qm-flat-btn-lg w-sm-100 mb-2 mb-sm-0'>Back</button>
                <button (click)='checkUserEmailBeforeNext(s3FormGroup)' [disabled]="s3FormGroup.invalid" type="button" mat-flat-button color="qm-blue"
                  class='qm-flat-btn-lg w-sm-100 mb-2 mb-sm-0'>Next</button>
              </div>
            </form>
          </mat-step>
          <!-- 4. personal detail: first, last name, job title, DOM, DOY -->
          <mat-step [stepControl]="s4FormGroup">
            <form [formGroup]="s4FormGroup">
              <ng-template matStepLabel>
                <h5 class="mb-0">Personal Detail</h5>
              </ng-template>

              <div>
                <mat-form-field>
                  <input matInput placeholder="First Name" formControlName="FirstName" required>
                </mat-form-field>

                <mat-form-field>
                  <input matInput placeholder="Last Name" formControlName="LastName" required>
                </mat-form-field>
              </div>
              <!-- <div class="row">
                <div class="col">
                  <mat-form-field>
                    <mat-select placeholder="Birth Date Month" formControlName="DobMonth" required>
                      <mat-option *ngFor="let month of dobMonths" [value]="month">{{
                        month.name }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field>
                    <mat-select placeholder="Birth Date Year" formControlName="DobYear" required>
                      <mat-option *ngFor="let year of dobYears" [value]="year">{{
                        year.name }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div> -->

              <!-- <div class='row'>
                <div class="alert alert-success col-md-12" role="alert">
                  <h5 class="alert-heading">
                    {{sharedFunction.getUiMessageByCode("Share-INFO-WhyNeeded")}}</h5>
                  <p>
                    <span [innerHTML]='sharedFunction.getUiMessageByCode("Share-INFO-WhyNeedDOB")'></span>
                  </p>
                </div>
              </div> -->

              <div class='d-flex flex-column flex-sm-row justify-content-between'>
                <button matStepperPrevious (click)='clearError(s4FormGroup)' mat-flat-button color="qm-blue" class='qm-flat-btn-lg w-sm-100 mb-2 mb-sm-0'>Back</button>
                <button matStepperNext [disabled]="s4FormGroup.invalid" mat-flat-button color="qm-blue" class='qm-flat-btn-lg w-sm-100 mb-2 mb-sm-0'>Next</button>
              </div>
            </form>
          </mat-step>
          <ng-container *ngIf="!isStudentSignup">
            <!-- 5. adviser detail: Group, Professional assoc  -->
            <mat-step [stepControl]="s5FormGroup">
                <form [formGroup]="s5FormGroup">
                <ng-template matStepLabel>
                    <h5 class="mb-0">Your Adviser Info</h5>
                </ng-template>

                <div>
                    <!-- <mat-form-field>
                        <mat-select placeholder="Adviser group" formControlName="Group" required disableOptionCentering
                                    panelClass="custom-select-panel"
                                    (closed)="s5FormGroup.get('GroupSearchInput').setValue('')">
                            <mat-form-field class='px-3 pt-2'>
                                <input type="text"
                                    placeholder="Search"
                                    aria-label="Search"
                                    matInput
                                    formControlName="GroupSearchInput"
                                    appAutoSelect
                                    appAutofocus>
                            </mat-form-field>
                            <mat-option *ngFor="let group of userGroupListOptions | async; first as isFirst"
                                        [value]="group.Value" [ngClass]="isFirst?'mat-active':'remove-mat-active'">
                                {{ group.Name }}
                            </mat-option>
                            <mat-error class='px-3 pb-3'
                                    *ngIf="(userGroupListOptions | async)?.length === 0">
                                {{sharedFunction.getUiMessageByCode('Share-ERROR-NoResults')}}
                            </mat-error>
                        </mat-select>
                    </mat-form-field> -->

                    <mat-form-field>
                    <mat-select placeholder="Dispute Resolution Schemes" formControlName="DisputeResolutionService"
                        required>
                        <mat-option *ngFor="let drs of quoteRequiredData.DisputesResolutionServices" [value]="drs.Value">
                        {{ drs.StringValue2 }}</mat-option>
                    </mat-select>
                    </mat-form-field>
                </div>
                <!-- <mat-form-field>
                    <mat-select placeholder="Professional Assoc" formControlName="ProfessionalAssoc" required>
                    <mat-option *ngFor="let professionalAssoc of quoteRequiredData.ProfessionalAssList"
                        [value]="professionalAssoc.Value">
                        {{ professionalAssoc.Name }}
                    </mat-option>
                    </mat-select>                                                                                                                                                                                                                           </mat-form-field> -->

                <div class='d-flex flex-column flex-sm-row justify-content-between'>
                    <button matStepperPrevious (click)='clearError(s5FormGroup)' mat-flat-button color="qm-blue" class='qm-flat-btn-lg w-sm-100 mb-2 mb-sm-0'>Back</button>
                    <button matStepperNext [disabled]="s5FormGroup.invalid" mat-flat-button color="qm-blue" class='qm-flat-btn-lg w-sm-100 mb-2 mb-sm-0'>Next</button>
                </div>
                </form>
            </mat-step>
          </ng-container>

          <ng-container *ngIf="isStudentSignup">
            <!-- 5. only for student signup: school info  -->
            <mat-step [stepControl]="schoolInfoFormGroup">
                <form [formGroup]="schoolInfoFormGroup">
                    <ng-template matStepLabel>
                        <h5 class="mb-0">Your School Info</h5>
                    </ng-template>

                    <div>
                        <mat-form-field>
                        <mat-select placeholder="School" formControlName="School"
                            required>
                            <mat-option [value]="1">AUT</mat-option>
                                <mat-option [value]="2">Open Polytechnic</mat-option>
                                <mat-option [value]="3">Massey</mat-option>
                        </mat-select>
                        </mat-form-field>
                    </div>

                    <div class='d-flex flex-column flex-sm-row justify-content-between'>
                        <button matStepperPrevious (click)='clearError(schoolInfoFormGroup)' mat-flat-button color="qm-blue" class='qm-flat-btn-lg w-sm-100 mb-2 mb-sm-0'>Back</button>
                        <button matStepperNext [disabled]="schoolInfoFormGroup.invalid" mat-flat-button color="qm-blue" class='qm-flat-btn-lg w-sm-100 mb-2 mb-sm-0'>Next</button>
                    </div>
                </form>
            </mat-step>
          </ng-container>

          <!-- 6. Personalise your printed quotes: Business name, phone, address 1, address 2, city, postcode, region -->
          <mat-step [stepControl]="s6FormGroup">
            <form [formGroup]="s6FormGroup">
              <ng-template matStepLabel>
                <h5 class="mb-0">{{sharedFunction.getUiMessageByCode("Share-INFO-PersonaliseQuoteReportsTitle")}}</h5>
                <span>
                  {{sharedFunction.getUiMessageByCode("Share-INFO-PersonaliseQuoteReportsContent")}}
                </span>
              </ng-template>

              <div>
                <mat-form-field>
                  <input matInput placeholder="Job Title" formControlName="Title" />
                </mat-form-field>

                <mat-form-field>
                  <input matInput formControlName="BusinessName" placeholder="Business Name">
                </mat-form-field>

                <mat-form-field>
                  <input matInput formControlName="MobilePhone" placeholder="Phone Number" required>
                </mat-form-field>

                <mat-form-field>
                  <input matInput formControlName="AddressLine1" placeholder="Street Address" required>
                </mat-form-field>

                <mat-form-field>
                  <input matInput formControlName="AddressLine2" placeholder="Address Line 2 (optional)">
                </mat-form-field>

                <mat-form-field>
                  <input matInput formControlName="AddressCity" placeholder="City" required>
                </mat-form-field>

                <mat-form-field>
                  <input matInput formControlName="AddressPostCode" placeholder="Postcode" required>
                </mat-form-field>

                <mat-form-field>
                    <mat-select placeholder="Select a Region" formControlName="Region" required disableOptionCentering
                                panelClass="custom-select-panel"
                                (closed)="s6FormGroup.get('RegionSearchInput').setValue('')">
                        <mat-form-field class='px-3 pt-2'>
                            <input type="text"
                                   placeholder="Search"
                                   aria-label="Search"
                                   matInput
                                   formControlName="RegionSearchInput"
                                   appAutoSelect
                                   appAutofocus>
                        </mat-form-field>
                        <mat-option *ngFor="let region of regionListOptions | async; first as isFirst"
                                    [value]="region.Value"
                                    [ngClass]="isFirst?'mat-active':'remove-mat-active'">
                            {{ region.Name }}
                        </mat-option>
                        <mat-error class='px-3 pb-3'
                                   *ngIf="(regionListOptions | async)?.length === 0">
                            {{sharedFunction.getUiMessageByCode('Share-ERROR-NoResults')}}
                        </mat-error>
                    </mat-select>
                </mat-form-field>
              </div>

              <!-- <div>
                <div class="alert alert-info" role="alert">
                  <h5 class="alert-heading">
                    {{sharedFunction.getUiMessageByCode("Share-INFO-WhyNeeded")}}</h5>
                  <p class="qm-text-sm">
                    <span [innerHTML]='sharedFunction.getUiMessageByCode("SignUp-INFO-Phone")'></span>
                  </p>
                </div>
              </div> -->

              <div class='d-flex flex-column flex-sm-row justify-content-between'>
                <button matStepperPrevious (click)='clearError(s6FormGroup)' mat-flat-button color="qm-blue" class='qm-flat-btn-lg w-sm-100 mb-2 mb-sm-0'>Back</button>
                <button matStepperNext [disabled]="s6FormGroup.invalid" mat-flat-button color="qm-blue" class='qm-flat-btn-lg w-sm-100 mb-2 mb-sm-0'>Next</button>
              </div>
            </form>
          </mat-step>
          <!-- 7. Terms of user and sign up button -->
          <mat-step [stepControl]="s7FormGroup">
            <form [formGroup]="s7FormGroup">
              <ng-template matStepLabel>
                <h5 class="mb-0">Submit</h5>
              </ng-template>

              <div class='mt-3 mb-4'>
                
                <div>
                  <mat-form-field>
                    <input matInput formControlName="ReferenceCode" placeholder="Reference Code if you have">
                  </mat-form-field>                  
                  <mat-checkbox formControlName="AgreeTerms" required>
                    I agree to the &nbsp;
                  </mat-checkbox>
                  <a href="/TermsOfUse.html" target="_blank" rel="noopener" class="terms-link text-blue"
                    (click)='loginService.doGoogleTracking("login", "go to Terms of Use & Privacy Policy", "");'>
                    <strong>Terms of Use & Privacy Policy</strong>
                  </a>
                </div>
              </div>

              <div class='d-flex flex-column flex-sm-row justify-content-between'>
                <button matStepperPrevious (click)='clearError(s7FormGroup)' mat-flat-button color="qm-blue"
                        class='qm-flat-btn-lg w-sm-100 mb-2 mb-sm-0'>Back</button>
                <ng-container *ngIf="!isDevMode">
                  <button mat-flat-button color="qm-blue" class='qm-flat-btn-lg w-sm-100 mb-2 mb-sm-0'
                          (click)='doSignUp();loginService.doGoogleTracking("login", "do sign up", "");'
                          [disabled]="!s7FormGroup.valid">
                    Sign Up
                  </button>
                </ng-container>
              </div>

              <div class='mt-3'>
                <div class="alert alert-danger" role="alert" *ngIf='messageSignup'>
                  <h5 class="alert-heading">Error!!!</h5>
                  <p class="qm-text-sm">
                    <span [innerHTML]='messageSignup'></span>
                  </p>
                </div>
              </div>
            </form>
          </mat-step>
        </mat-vertical-stepper>
    </div>
  </div>
</div>
