import { Injectable } from '@angular/core';
import { QuoteService } from '../quote.service';
import { CurrentQuote } from 'src/app/models/current.quote';
import { Benefit } from 'src/app/models/benefit';
import { AvailableBenefit, AvailableProvider, HistoryData, PriceHistories } from 'src/app/models/price-history.model';
import { PeopleEntity } from 'src/app/models/people';
import * as Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import OfflineExporting from 'highcharts/modules/offline-exporting';
Exporting(Highcharts);
OfflineExporting(Highcharts);

@Injectable({
  providedIn: 'root',
})
export class QuoteStepComparePrice extends QuoteService {

  availableBenefits: AvailableBenefit[] = [];
  allProviders: AvailableProvider[] = [];

  priceHistoryDataList: HistoryData[] = [];
  years: number[] = [];
  averages: number[] = [];
  resetNullToNumber: number = -1e-26;

  init() {
    if (this.currentQuote.IsQuoteWizard) {
      this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteWizardStep5PriceChangeHistory);
    } else if (this.currentQuote.IsPolicyBasedQuote) {
      this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.PolicyBasedQuoteStep5PriceChangeHistory);
    } else {
      this.currentQuote = this.getCurrentQuote(this.sharedFunction.progressStep.QuoteStep3PriceChangeHistory);
    }

    this.setData();
  }

  setData(): void {
    this.availableBenefits = this.createAvailableData(this.currentQuote).AvailableBenefits;
    this.allProviders = this.createAvailableData(this.currentQuote).availableProviders;
  };

  createAvailableData(currentQuote: CurrentQuote): { AvailableBenefits: AvailableBenefit[], availableProviders: AvailableProvider[]; } {
    let availableBenefitList: AvailableBenefit[] = [];
    let benefitCounter: number = 0;

    let availableProviderList: AvailableProvider[] = [];

    for (let result of currentQuote.QuoteResult.QuoteResultList) {
      if (result.Provider.TotalPremium > 0 && result.Provider.TotalPremium < 9999999999) {

        let newAvailableProvider = new AvailableProvider(result.Provider, null);
        availableProviderList.push(newAvailableProvider);

        for (let client of result.Result) {
          if (client.IsChild !== true) {
            for (let benefit of client.BenefitList) {
              // reset benefit result ui id.
              benefit.UIBenefitId = this.resetBenefitUIId(benefit).UIBenefitId;

              // remove WOP && Redundancy
              if (benefit.UIBenefitId !== 9 && benefit.UIBenefitId !== 8) {

                benefit.BenefitName = this.getBenefitName(benefit);

                if (!this.isExistingAvailableBenefit(client, benefit, availableBenefitList)) {
                  benefitCounter += 1;
                  let name = `${ benefit.BenefitName } (${ client.FirstName } ${ client.LastName })`;
                  let newAvailableBenefit = new AvailableBenefit(benefitCounter, name, client, benefit);
                  // add benefit-client
                  availableBenefitList.push(newAvailableBenefit);
                }
                
                // not including linked provider
                if (!benefit.LinkedToProviderId) {
                  let benefitProvider: AvailableProvider = new AvailableProvider(result.Provider, benefit.ProvideBy);
                  this.addAvailableProviderToBenefit(benefitProvider, client, benefit, availableBenefitList);
                }
                
              }
            }
          }
        }
      }
    }

    return { AvailableBenefits: availableBenefitList, availableProviders: availableProviderList };
  }

  isExistingAvailableBenefit(client: PeopleEntity, benefit: Benefit, availableBenefitList: AvailableBenefit[]): boolean {
    return availableBenefitList.filter(item => item.Client.ClientId === client.ClientId && item.Benefit.UIBenefitId === benefit.UIBenefitId).length > 0;
  }

  isAvailableProviderForBenefit(availableBenefit: AvailableBenefit, providerId: number): boolean {
    return availableBenefit.ProviderList.filter(item => item.ID === providerId).length > 0;
  }

  addAvailableProviderToBenefit(availableProvider: AvailableProvider, client: PeopleEntity, benefit: Benefit, availableBenefitList: AvailableBenefit[]): void {
    availableBenefitList.find(item => item.Client.ClientId === client.ClientId && item.Benefit.UIBenefitId === benefit.UIBenefitId).ProviderList.push(availableProvider);
  }

  createPriceHistoriesRequestData(selectedBenefit: AvailableBenefit): PriceHistories {
    let age = selectedBenefit.Client.Age;
    let gender = selectedBenefit.Client.Gender;
    let isSmoker = selectedBenefit.Client.Smoker;
    let providerIds = AvailableBenefit.getProviderIds(selectedBenefit.ProviderList);
    let benefitId = selectedBenefit.Benefit.BenefitId;
    let priceHistoryRequestData = new PriceHistories(age, gender, isSmoker, benefitId, providerIds);
    return priceHistoryRequestData;
  }

  isHistoryDataAllZero(priceHistories: PriceHistories): boolean {
    return priceHistories.HistoryDatas.filter(item => (item.AverageChangeFactor !== 0 && item.AverageChangeFactor !== null && item.AverageChangeFactor !== this.resetNullToNumber)).length === 0;
  }
  
  isAllProviderDataZero(responseData: PriceHistories[]): boolean {
    return responseData.filter(ph => !this.isHistoryDataAllZero(ph)).length === 0;
  }
  
  addProviderName(selectedBenefit: AvailableBenefit, responseData: PriceHistories[]): void {
    responseData.forEach(ph => {
      let matchProvider = selectedBenefit.ProviderList.find(item => item.ID === ph.ProviderId);
      ph.ProviderName = matchProvider.DisplayName;
    })
  }
  
  resetNullValue(responseData: PriceHistories[]): void {
    responseData.forEach(ph => {
      ph.HistoryDatas.forEach(hd => {
        if (hd.AverageChangeFactor === null) {
          hd.AverageChangeFactor = this.resetNullToNumber;
        }
      });
    });
  }
  
  
  generateTableData(priceHistoriesList: PriceHistories[]): { provider: string, year: number[], data: number[]; color:string }[] {
    let filledData = priceHistoriesList.filter(ph => !this.isHistoryDataAllZero(ph));
    let colors = this.dataLoader.getRequiredData().HighchartColors;
    
    let tableData = filledData.map((ph, index) => {
      return {
        provider: ph.ProviderName,
        year: ph.HistoryDatas.map(data => data.Year),
        data: ph.HistoryDatas.map(data => data.AverageChangeFactor),
        color: colors[index % colors.length]
      };
    });

    return tableData;
  }
  
  generateLineChartData(priceHistoriesList: PriceHistories[]): { name: string, data: number[][]; color:string }[] {
    let filledData = priceHistoriesList.filter(ph => !this.isHistoryDataAllZero(ph));
    let colors = this.dataLoader.getRequiredData().HighchartColors;
    
    let chartData = filledData.map((ph, index) => {
      return {
        name: ph.ProviderName,
        data: ph.HistoryDatas.map(data => [data.Year, data.AverageChangeFactor]),
        color: colors[index % colors.length]
      };
    });

    return chartData;
  }

  generateChartTitle(selectedBenefit: AvailableBenefit): string {
    let benefitName: string = selectedBenefit.Benefit.BenefitName;

    let clientName: string = selectedBenefit.Client.FirstName + " " + selectedBenefit.Client.LastName;

    return `<h4 class="text-blue mt-2">${ benefitName } for ${ clientName }</h4>`;
  }

  showCharts(title: string, data: any, containerId: string, callback: () => void): void {
    let resetNullToNumber = this.resetNullToNumber;
    Highcharts.chart(containerId, {
      exporting: {
        enabled: false
      },
      credits: {
        enabled: false
      },
      chart: {
        type: 'column',
        style: {
          fontFamily: 'Verdana, Geneva, sans-serif',
          fontSize: '13px'
        },
      },
      title: {
        text:'',
        useHTML: true,
        align: 'left',
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        title: {
          text: 'Year'
        },
        labels: {
          style: {
            fontFamily: 'Verdana, Geneva, sans-serif',
            fontSize: '11px'
          },
        }

      },
      yAxis: {
        title: {
          text: 'Average Changes',
        },
        labels: {
          style: {
            fontFamily: 'Verdana, Geneva, sans-serif',
            fontSize: '11px'
          },
          formatter: function () {
            return `${ (this.value * 100).toFixed(1) }%`;  // Display yAxis labels as percentages
          }
        }
      },
      tooltip: {
        enabled: true,
        useHTML: true,
        hideDelay: 300,
        shared: false,
        formatter: function () {
          let colorDot = `<span style="display:inline-block; width:10px; height:10px; border-radius:50%; background-color:${ this.color }; margin-right: 5px;"></span>`;
          let colorClass = this.point.y < 0 ? 'text-danger' : 'text-dark';
          if (this.point.y === resetNullToNumber) {
          return `<p>${ colorDot } ${ this.series.name }: <span>N/A</span> </p>`;
          } else {
          return `<p>${ colorDot } ${ this.series.name }: <span class="${ colorClass }">${ (this.point.y * 100).toFixed(1) }%</span> </p>`;
          
          }
        },
        positioner: function () {
          return { x: this.chart.plotLeft, y: 0 };
        },
        shadow: false,
        borderWidth: 0,
        backgroundColor: 'rgba(255,255,255,0.8)'
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            useHTML: true,
            style: {
              fontSize: '13px'
            },
            formatter: function () {
              if (this.y === resetNullToNumber) {
                return `<span class='qm-text-xsm' style="color: ${ this.color }; position: relative; top: 26px;">N/A</span>`
              } else {
              
              let value = this.y * 100;
              let colorClass = value < 0 ? 'text-danger' : 'text-dark';
              let yOffset = this.y < 0 ? 26 : 0;
              return `<span class="${ colorClass }" style="position: relative; top: ${ yOffset }px;">${ value.toFixed(1) }%</span>`;
              }
            }, 
            verticalAlign: 'bottom'
          },
          stickyTracking: false,
          pointPadding: 0, 
        }
      },
      series: data,
      legend: {
        itemStyle: {
          fontFamily: 'Verdana, Geneva, sans-serif',
          fontSize: '13px'
        },
      }
    });

    if (callback) {
      callback();
    }
  }
}




