import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessRole } from 'src/app/models/access.token.models/access.role';
import { SavedBusinessRecord } from 'src/app/models/business/saved.business.record';
import { ComponentBaseClass } from 'src/app/service/base';
import { BusinessService } from 'src/app/service/business/business.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { ConfirmMessageDialogService } from '../../shared/confirm-message-dialog.service';
import { GeneralResponseMessage } from 'src/app/models/messages/general.response.message';
import { BusinessQuoteNcsRecord } from 'src/app/models/business/business-quote-ncs-record.model';
import { BusinessQuoteService } from 'src/app/service/business/quote/business.quote.service';
import { NZBNSearchDialogComponent } from '../nzbn-search-dialog/nzbn-search-dialog.component';
import { QuoteService } from 'src/app/service/quote.service';
import { KiwiMonsterService } from 'src/app/service/kiwimonster/kiwimonster.service';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'business-dashboard',
    templateUrl: './business-dashboard.component.html',
    styleUrls: ['./business-dashboard.component.scss']
})
export class BusinessDashboardComponent extends ComponentBaseClass implements OnInit, AfterViewInit {
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    expandedRow: SavedBusinessRecord;

    accessRole: AccessRole = new AccessRole();

    displayedColumns = ['Name', 'When', 'Action'];
    dataSource: MatTableDataSource<SavedBusinessRecord>;
    savedBusinessRecords: SavedBusinessRecord[] = [];

    quoteAndNeedAnalysisRecords: BusinessQuoteNcsRecord[] = [];
    noDetailData: boolean = false;

    // Use "constructor"s only for dependency injection
    constructor (
        public loginService: LoginService,
        public route: ActivatedRoute,
        public router: Router,
        public dialog: MatDialog,
        public sharedFunction: SharedFunctionService,
        public businessService: BusinessService,
        public businessQuoteService: BusinessQuoteService,
        public confirmDialog: ConfirmMessageDialogService,
        private personalQuoteService: QuoteService,
        private kiwiMonsterService: KiwiMonsterService
    ) {
        super();
        this.dataSource = new MatTableDataSource(this.savedBusinessRecords);
    }

    // Here you want to handle anything with @Input()'s @Output()'s
    // Data retrieval / etc - this is when the Component is "ready" and wired up
  ngOnInit() {
        // make sure recent quote is business quote
        this.personalQuoteService.removeCurrentQuote();
        this.kiwiMonsterService.removeCurrentKMQuote();
    
        this.businessService.removeCurrentBusinessQuote();
        this.businessService.removeCurrentBusinessRecord();
        // change pagination label
        this.paginator._intl.itemsPerPageLabel = "Businesses per page";
        this.paginator._intl.getRangeLabel = (
            page: number,
            pageSize: number,
            length: number
        ) => {
            if (length === 0) {
                return `Page 1 / 1`;
            }
            const amountPages = Math.ceil(length / pageSize);
            return `Page ${ page + 1 } / ${ amountPages }`;
        };

        this.paginator._formFieldAppearance = "outline";
    }

    /**
     * Set the paginator and sort after the view init since this component will
     * be able to query its view for the initialized paginator and sort.
     */
    ngAfterViewInit() {
        this.loadSavedBusinessRecords();
    }

    loadSavedBusinessRecords() {
        this.showDinoLoading();

        this.businessService.getSavedBusinessRecords((response: SavedBusinessRecord[]) => {
            if (response) {
                this.savedBusinessRecords = response.filter(item => item.Name);
                this.bindDataTable();
            }

            this.closeDinoLoading();
        });
    }

    bindDataTable() {
        this.dataSource = new MatTableDataSource(this.savedBusinessRecords);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        // add filter function
        this.dataSource.filterPredicate = (
            data: SavedBusinessRecord,
            filter: string
        ): boolean => {
            return data.Name?.toLowerCase().trim().indexOf(filter) >= 0;
        };
    }

    applyFilter(filterValue: string) {
        filterValue = filterValue.toLowerCase().trim(); // Data source defaults to lowercase matches
        this.dataSource.filter = filterValue;
    }

    openRecord(selectedRow: SavedBusinessRecord): void {
        this.showDinoLoading();
        this.businessService.getSavedBusinessDetail(selectedRow.Id, (response: SavedBusinessRecord) => {
            if (response && response.Message.MessageCode === 200) {
                this.router.navigate(['/business/detail']);
                this.businessService.saveCurrentBusinessRecord(response);
            }
            this.closeDinoLoading();
        });
    }

    showQuoteAndNeedAnalysisRecords(selectedRow: SavedBusinessRecord) {
      this.quoteAndNeedAnalysisRecords = [];
      this.noDetailData = false;
        if (this.expandedRow !== selectedRow) {
            this.expandedRow = selectedRow;
            // no expanded row or the expanded row is not selected row, then call api
            this.showDinoLoading();
            this.businessService.getBusinessQuotesAndNcs(selectedRow.Id, (response: BusinessQuoteNcsRecord[]) => {
                //   TODO: check name or id? always create a needanalysis id when creating a business?
                if (response && response.length > 0 && response.filter(item => item.QuoteName?.trim() || item.NeedAnalysisName?.trim()).length > 0) {
                    this.quoteAndNeedAnalysisRecords = response.filter(item => item.QuoteName?.trim() || item.NeedAnalysisName?.trim());
                    this.sortQuoteAndNeedAnalysisListByDate(this.quoteAndNeedAnalysisRecords);
                } else {
                  this.noDetailData = true;
                }
                this.closeDinoLoading();
            });
        } else {
            this.expandedRow = null;
        }
    }

    openQuote(businessRecordId: number, record: BusinessQuoteNcsRecord): void {
        this.showDinoLoading();
        // call api get quote details
        this.businessQuoteService.openExistingQuote(businessRecordId, record).subscribe((businessCurrentQuote) => {
            // save quote details to storage
            this.businessService.saveCurrentBusinessQuote(businessCurrentQuote);
            // call api get business details
            this.businessService.getSavedBusinessDetail(businessRecordId, (response: SavedBusinessRecord) => {
                if (response && response.Message.MessageCode === 200) {
                    // will return a BusinessNeedAnalysisData object with all null value, need to set it to null or will create a BusinessNeedAnalysisData object with Id when saving BusinessRecord next tiem
                    if (!response.BusinessNeedAnalysisData?.Id) {
                        response.BusinessNeedAnalysisData = null;
                    }
                    
                    // save business details to storage
                    this.businessService.saveCurrentBusinessRecord(response);
                }
                this.closeDinoLoading();
                this.router.navigate(['/business/quote/people-benefit-detail']);
            });
        });
    }

    deleteRecord(selectedRow: SavedBusinessRecord) {
        this.showDinoLoading();
        this.businessService.deleteSavedBusinessRecord(selectedRow.Id, (response: GeneralResponseMessage) => {
            if (response && response.MessageCode === 200) {
                this.loadSavedBusinessRecords();
            } else {
                this.closeDinoLoading();
            }
        });
    }
    
    sortQuoteAndNeedAnalysisListByDate(quoteAndNeedAnalysisRecords: BusinessQuoteNcsRecord[]) {
        return quoteAndNeedAnalysisRecords.sort((item1, item2) => {
            if (item1.QuoteModifyDate && item2.QuoteModifyDate) {
                // If both QuoteModifyDate values exist, sort by QuoteModifyDate in descending order
                return new Date(item2.QuoteModifyDate).getTime() - new Date(item1.QuoteModifyDate).getTime();
            } else if (item1.QuoteModifyDate || item2.QuoteModifyDate) {
                // If either QuoteModifyDate value exists (but not both), prioritize the one with a value
                return item2.QuoteModifyDate ? 1 : -1;
            } else {
                // If neither QuoteModifyDate value exists, sort by NeedAnalysisModifyDate in descending order
                return new Date(item2.NeedAnalysisModifyDate).getTime() - new Date(item1.NeedAnalysisModifyDate).getTime();
            }
        });
    }
    
    openNZBNSearchDialog(): void {
       this.dialog.open(NZBNSearchDialogComponent, {
            width: '80%',
            maxWidth: '1200px',
            disableClose: true,
            restoreFocus: false,
            autoFocus: false
        });
    }

}
