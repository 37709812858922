<div class="mb-5">
  <kiwimonster-step-menu [currentKMQuoteStep]=3 class='quote-step-menu'></kiwimonster-step-menu>
</div>

<!-- quote name -->
<div class="form-group row">
  <label for="quoteName" class="col-auto col-form-label">Quote Name:</label>
  <div class='col-auto'>
    <input type="text" class='form-control' id='quoteName' disabled
           [(ngModel)]="currentKMQuote.QuoteName" />
  </div>
</div>

<ul class="list-group list-group-flush">
  <!-- header -->
  <li class="py-4 list-group-item qm-bg-catskillWhite">
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center">
      <h4 class="mb-4 mb-md-0">KiwiSaver Fund Comparison</h4>
      <div>
        <!-- go back button -->
        <button mat-raised-button color="qm-blue" class='mr-3 px-2 px-sm-3 min-w-auto'
                (click)='goBack();'>
          <span class="material-icons" aria-hidden="false" aria-label="chevron left icon">chevron_left</span>
          <span class="d-none d-sm-inline-block">Back</span>
        </button>

        <!-- save&close button -->
        <kiwimonster-save-close-btn (saveAndCloseClicked)="onSaveAndClose()"> </kiwimonster-save-close-btn>

        <!-- next button -->
        <button mat-raised-button color="qm-blue" class='ml-3 px-2 px-sm-3 min-w-auto'
                (click)='goNext();'>
          <span class="d-none d-sm-inline-block">Next</span>
          <span class="material-icons" aria-hidden="false"
                aria-label="chevron right icon">chevron_right</span>
        </button>
      </div>
    </div>
  </li>

  <!-- client part -->
  <li class="list-group-item px-0 border-top-0">
    <div class="row">
      <div class='col-12'>
        <mat-tab-group animationDuration="0ms" #tabGroup class='main-client-group' [selectedIndex]='currentClientIndex'
                       (selectedTabChange)="onTabChange($event)"
                       appRealignInkBar>
          <!-- adult tab -->
          <ng-container *ngFor="let client of currentKMQuote.Clients">
            <mat-tab *ngIf="client.Results?.length > 0 && client.Setting.AreYouNZTaxResident">
              <!-- adult tab label -->
              <ng-template mat-tab-label>
                <ng-container *ngIf='client.Gender === 1'>
                  <img class='sm-img' src="/assets/images/client-type/1.png?v=2" />
                </ng-container>
                <ng-container *ngIf='client.Gender !== 1'>
                  <img class='sm-img' src="/assets/images/client-type/2.png?v=2" />
                </ng-container>
                {{getClientTabName(client)}}
              </ng-template>
              <!-- adult tab label end -->

              <!-- client details -->
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    {{client.FirstName}} {{client.LastName}}
                  </li>
                  <li class="breadcrumb-item">
                    {{client.Age}} year old
                  </li>
                </ol>
              </nav>
              <div class='row'>&nbsp;</div>

              <!-- compare table -->
              <div class="table-responsive kiwimonster-compare-table">
                <table class="table table-bordered table-info">
                  <thead>
                    <tr>
                      <th colspan="1" rowspan='2' class='min-width-240px'></th>
                      <th colspan="1" class="text-center" *ngIf="client.Setting.DoYouHaveKiwiSaver">Existing</th>
                      <th [attr.colspan]="client.NumberOfProspectiveFunds" class="text-center">Alternative Options</th>
                    </tr>
                    <tr>
                      <th class='align-top' [style.width.%]="100 / client.Results.length" [ngClass]="{'text-center': client.OnlyOneProspectiveFund}"
                          *ngFor="let result of client.Results">
                        {{result.SchemeName}} - {{result.FundName}}</th>
                    </tr>
                  </thead>
                  <tbody class='bg-white'>
                    <tr *ngIf="client.HasEstimateReturnTotalBalance">
                      <th></th>
                      <ng-container *ngFor="let result of client.Results; let i = index">
                        <td class='pb-0 fixed-height align-middle text-center'>
                          <ng-container
                                        *ngIf="result.HaveValueEstimateReturnToRetirementTotalBalance || result.HaveValueEstimateReturnToEndTotalBalance; else noData">
                            <div [attr.id]="'totalBalanceChartContainer-' + client.Id + '-' + result.Id"
                                 class='overflow-visible'>
                            </div>
                          </ng-container>
                          <ng-template #noData> No Data</ng-template>
                        </td>
                      </ng-container>
                    </tr>
                    <tr *ngIf="client.HasEstimateReturnTotalBalance && !client.OnlyOneProspectiveFund">
                      <th></th>
                      <td class='pb-0'
                          [attr.colspan]="client.Setting.DoYouHaveKiwiSaver?client.NumberOfProspectiveFunds + 1: client.NumberOfProspectiveFunds">
                        <div [attr.id]="'totalBalanceAllResultsChartContainer-' + client.Id" class='overflow-visible'>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class='align-middle'>
                        <p class='mb-0'>Estimated KiwiSaver Balance At Retirement</p>
                        <button mat-icon-button class="text-info qm-icon-btn-xsm scale-80"
                                (click)='openInfoDialog("EstimatedKSBalance")'
                                title='Info' aria-label="info" type="button">
                          <span class="material-icons">info</span>
                        </button>
                      </th>
                      <ng-container *ngFor="let result of client.Results; let i = index">
                        <td class='pb-0 fixed-height'>
                          <div [attr.id]="'estimatedKSBalanceBarChartContainer-' + client.Id + '-' + i"
                               class='overflow-visible'></div>
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <th class='align-middle'>
                        <p class="mb-0">Estimated Weekly Retirement Income</p>
                        <button mat-icon-button class="text-info qm-icon-btn-xsm scale-80"
                                (click)='openInfoDialog("EstimatedWeeklyRetirementIncome")'
                                title='Info' aria-label="info" type="button">
                          <span class="material-icons">info</span>
                        </button>
                      </th>
                      <ng-container *ngFor="let result of client.Results; let i = index">
                        <td class='pb-0 fixed-height'>
                          <div [attr.id]="'estimatedWeeklyRetirementIncomeBarChartContainer-' + client.Id + '-' + i"
                               class='overflow-visible'></div>
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <th class='align-middle'>
                        <p class="mb-0">Fund Type</p>
                        <button mat-icon-button class="text-info qm-icon-btn-xsm scale-80"
                                (click)='openInfoDialog("QmRiskProfileCode")'
                                title='Info' aria-label="info" type="button">
                          <span class="material-icons">info</span>
                        </button>
                      </th>
                      <ng-container *ngFor="let result of client.Results; let i = index">
                        <td class='text-center'>
                          {{updateFundType(result)?updateFundType(result):sharedFunction.getDisplayName("KiwiMonsterFundStatusInRetirementList",result.QmRiskProfileCode)}}
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <th class='align-middle'>
                        <p class="mb-0">Risk Indicator</p>
                        <button mat-icon-button class="text-info qm-icon-btn-xsm scale-80"
                                (click)='openInfoDialog("RiskRewardIndicatorCode")'
                                title='Info' aria-label="info" type="button">
                          <span class="material-icons">info</span>
                        </button>
                      </th>
                      <ng-container *ngFor="let result of client.Results; let i = index">
                        <td class='pb-0 fixed-height'>
                          <div [attr.id]="'riskRewardIndicatorCodeBarChartContainer-' + client.Id + '-' + i"
                               class='overflow-visible'></div>
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <th class='align-middle'>
                        <p class="mb-0">Net Return: Past One Year</p>
                        <button mat-icon-button class="text-info qm-icon-btn-xsm scale-80"
                                (click)='openInfoDialog("PastYearReturnNet")'
                                title='Info' aria-label="info" type="button">
                          <span class="material-icons">info</span>
                        </button>
                      </th>
                      <ng-container *ngFor="let result of client.Results; let i = index">
                        <td class='pb-0 fixed-height'>
                          <div [attr.id]="'pastYearReturnNetBarChartContainer-' + client.Id + '-' + i"
                               class='overflow-visible'>
                          </div>
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <th class='align-middle'>
                        <p class="mb-0">Net Return: Past Five Years (Average)</p>
                        <button mat-icon-button class="text-info qm-icon-btn-xsm scale-80"
                                (click)='openInfoDialog("AverageFiveYearReturnNet")'
                                title='Info' aria-label="info" type="button">
                          <span class="material-icons">info</span>
                        </button>
                      </th>
                      <ng-container *ngFor="let result of client.Results; let i = index">
                        <td class='pb-0 fixed-height'>
                          <div [attr.id]="'averageFiveYearReturnNetBarChartContainer-' + client.Id + '-' + i"
                               class='overflow-visible'>
                          </div>
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <th class='align-middle'>
                        <p class="mb-0">Net Return: Since Inception</p>
                        <button mat-icon-button class="text-info qm-icon-btn-xsm scale-80"
                                (click)='openInfoDialog("PerformanceSinceInceptionReturnNet")'
                                title='Info' aria-label="info" type="button">
                          <span class="material-icons">info</span>
                        </button>
                      </th>
                      <ng-container *ngFor="let result of client.Results; let i = index">
                        <td class='pb-0 fixed-height'>
                          <div [attr.id]="'performanceSinceInceptionReturnNetBarChartContainer-' + client.Id + '-' + i"
                               class='overflow-visible'></div>
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <th class='align-middle'>
                        <p class="mb-0">Total Combined Fees</p>
                        <button mat-icon-button class="text-info qm-icon-btn-xsm scale-80"
                                (click)='openInfoDialog("TotalAnnualFundCharge")'
                                title='Info' aria-label="info" type="button">
                          <span class="material-icons">info</span>
                        </button>
                      </th>
                      <ng-container *ngFor="let result of client.Results; let i = index">
                        <td class='pb-0 fixed-height'>
                          <div [attr.id]="'totalAnnualFundChargeBarChartContainer-' + client.Id + '-' + i"
                               class='overflow-visible'>
                          </div>
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <th class='align-middle'>
                        <p class="mb-0">Total Fund Value</p>
                        <button mat-icon-button class="text-info qm-icon-btn-xsm scale-80"
                                (click)='openInfoDialog("FundTotalValue")'
                                title='Info' aria-label="info" type="button">
                          <span class="material-icons">info</span>
                        </button>
                      </th>
                      <ng-container *ngFor="let result of client.Results; let i = index">
                        <td class='pb-0 fixed-height'>
                          <div [attr.id]="'fundTotalValueBarChartContainer-' + client.Id + '-' + i"
                               class='overflow-visible'></div>
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <th class='align-middle'>
                        <p class="mb-0">No. Of Investors</p>
                        <button mat-icon-button class="text-info qm-icon-btn-xsm scale-80"
                                (click)='openInfoDialog("NumberOfInvestors")'
                                title='Info' aria-label="info" type="button">
                          <span class="material-icons">info</span>
                        </button>
                      </th>
                      <ng-container *ngFor="let result of client.Results; let i = index">
                        <td class='pb-0 fixed-height'>
                          <div [attr.id]="'numberOfInvestorsBarChartContainer-' + client.Id + '-' + i"
                               class='overflow-visible'>
                          </div>
                        </td>
                      </ng-container>
                    </tr>
                    <tr>
                      <th class='align-middle'>
                        <p class="mb-0">Fund Classification</p>
                        <button mat-icon-button class="text-info qm-icon-btn-xsm scale-80"
                                (click)='openInfoDialog("FundClassificationCode")'
                                title='Info' aria-label="info" type="button">
                          <span class="material-icons">info</span>
                        </button>
                      </th>
                      <ng-container *ngFor="let result of client.Results; let i = index">
                        <td class='text-center'> {{result.FundClassificationCode}}</td>
                      </ng-container>
                    </tr>
                    <tr>
                      <th class='align-middle'>
                        <p class="mb-0">Fund Start Date</p>
                        <button mat-icon-button class="text-info qm-icon-btn-xsm scale-80"
                                (click)='openInfoDialog("FundStartDate")'
                                title='Info' aria-label="info" type="button">
                          <span class="material-icons">info</span>
                        </button>
                      </th>
                      <ng-container *ngFor="let result of client.Results; let i = index">
                        <td class='text-center'> {{result.FundStartDate | date:"dd/MM/y"}}</td>
                      </ng-container>
                    </tr>
                    <tr>
                      <th>
                        <p class="mb-0">Fund Description</p>
                        <button mat-icon-button class="text-info qm-icon-btn-xsm scale-80"
                                (click)='openInfoDialog("FundDescription")'
                                title='Info' aria-label="info" type="button">
                          <span class="material-icons">info</span>
                        </button>
                      </th>
                      <ng-container *ngFor="let result of client.Results; let i = index">
                        <td> {{result.FundDescription}}</td>
                      </ng-container>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <th colspan='1' rowspan='2' class='min-width-240px'></th>
                      <th class='align-top' [style.width.%]="100 / client.Results.length" [ngClass]="{'text-center': client.OnlyOneProspectiveFund}"
                          *ngFor="let result of client.Results">
                        {{result.SchemeName}} - {{result.FundName}}</th>
                    </tr>
                    <tr>
                      <th colspan='1' class="text-center" *ngIf="client.Setting.DoYouHaveKiwiSaver">Existing</th>
                      <th [attr.colspan]="client.NumberOfProspectiveFunds" class="text-center">Alternative Options</th>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <!-- assumptions -->
              <p class='font-italic qm-text-sm'>
                *Data sourced from the <a class='text-blue' target='_blank'
                   href="https://disclose-register.companiesoffice.govt.nz/"
                   (click)='loginService.doGoogleTracking("kiwiMonsterQuote-compare", "go to Companies Office Disclose Register", "");'>Companies
                  Office Disclose Register</a> was sourced {{sourcedDateTime | date:'dd/MM/y hh:mm a' }}.
              </p>
            </mat-tab>
          </ng-container>
        </mat-tab-group>
      </div>
    </div>
  </li>


  <!-- nav footer start -->
  <li class="list-group-item px-0">
    <button mat-raised-button color="qm-blue" class='float-left'
            (click)='goBack();'>
      <span class="material-icons" aria-hidden="false" aria-label="chevron left icon">chevron_left</span>
      Back
    </button>

    <button mat-raised-button color="qm-blue" class='float-right'
            (click)='goNext();'>
      Next
      <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_right</span>
    </button>
  </li>
  <!-- nav footer end -->
</ul>