<!-- step menu -->
<div class="mb-5">
    <business-step-menu [currentStep]='currentSavedBusinessRecord.CurrentStep'></business-step-menu>
</div>

<ul class="list-group list-group-flush business-detail">
    <!-- title -->
    <li class="py-4 list-group-item qm-bg-catskillWhite">
        <div class="row align-items-md-center">
            <div class='col-12 col-md'>
                <h4 class="mb-4 mb-md-0">Business Details</h4>
            </div>

            <div class='col-12 col-md-auto'>
                <save-close-business-record (callSaveAndClose)='save(0)'></save-close-business-record>
                <!-- classic quote save&close button -->
                <button mat-raised-button color="qm-blue" class='ml-3 px-2 px-sm-3 min-w-auto'
                        (click)='save(1);'>
                    <span class="d-none d-sm-inline-block">Next</span>
                    <span class="material-icons" aria-hidden="false"
                          aria-label="chevron right icon">chevron_right</span>
                </button>
            </div>
        </div>
    </li>

    <!-- error message -->
    <li class='list-group-item' *ngIf="errorMessage">
        <div class="alert alert-danger mb-0 text-center" role="alert">
            {{ errorMessage }}
        </div>
    </li>

    <!-- name -->
    <li class='list-group-item'>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Business Name</mat-label>
                    <input matInput placeholder="" #nameInput [(ngModel)]="currentSavedBusinessRecord.Name"
                           (ngModelChange)="onValueChanges()" required autocomplete='off'
                           appAutofocus appAutoSelect />
                    <mat-error>{{sharedFunction.getUiMessageByCode('BusinessDetails-ERROR-EnterBusinessName')}}</mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-12"></div>
            <!-- Phone Numbers -->
            <div class="col align-self-end">
                <mat-form-field>
                    <mat-label>Phone Numbers</mat-label>
                    <mat-chip-list #phoneNumbersChip aria-label="Enter Phone Number">
                        <mat-chip *ngFor="let phoneNumber of currentSavedBusinessRecord.PhoneNumbers"
                                  (removed)="removeItem('phoneNumber', phoneNumber)">
                            {{phoneNumber}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                    <input placeholder="" #phoneNumberInput [matChipInputFor]="phoneNumbersChip"
                           (matChipInputTokenEnd)="addItem('phoneNumber', $event.value)" />
                    <mat-hint>You can add more than one phone number by typing each one and then pressing
                        Enter.</mat-hint>
                </mat-form-field>
            </div>
            <!-- Email Addresses -->
            <div class="col align-self-end">
                <mat-form-field>
                    <mat-label>Email Addresses</mat-label>
                    <mat-chip-list #emailAddressesChip aria-label="Enter Email Address">
                        <mat-chip *ngFor="let email of currentSavedBusinessRecord.EmailAddresses"
                                  (removed)="removeItem('email', email)">
                            {{email}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                    <input placeholder="" #emailInput [matChipInputFor]="emailAddressesChip"
                           (matChipInputTokenEnd)="addItem('email', $event.value)" />
                    <mat-hint>You can add more than one email address by typing each one and then pressing
                        Enter.</mat-hint>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 mt-4">
                <mat-form-field>
                    <mat-label>Website</mat-label>
                    <input matInput
                           [(ngModel)]="currentSavedBusinessRecord.Website"
                           (ngModelChange)="onValueChanges()" appAutoSelect />
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>Trading Names</mat-label>
                    <mat-chip-list #tradingNamesChip aria-label="Enter Trading Name">
                        <mat-chip *ngFor="let tradingName of currentSavedBusinessRecord.TradingNames"
                                  (removed)="removeItem('tradingName', tradingName)">
                            {{tradingName}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                    <input placeholder="" #tradingNameInput [matChipInputFor]="tradingNamesChip"
                           (matChipInputTokenEnd)="addItem('tradingName', $event.value)" />
                    <mat-hint>You can add more than one trading name by typing each one and then pressing
                        Enter.</mat-hint>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 mt-4">
                <mat-form-field>
                    <mat-label>Registered Office Address</mat-label>
                    <input matInput placeholder="" [(ngModel)]="currentSavedBusinessRecord.RegisteredOfficeAddress"
                           (ngModelChange)="onValueChanges()" appAutoSelect />
                </mat-form-field>
            </div>
        </div>
        <div class='row'>
            <div class='col'>
                <mat-form-field>
                    <mat-label>Address For Service</mat-label>
                    <input matInput placeholder="" [(ngModel)]="currentSavedBusinessRecord.AddressForService"
                           (ngModelChange)="onValueChanges()" (blur)='setIsSameAsRegisteredOfficeAddress()'
                           appAutoSelect />
                </mat-form-field>
            </div>
            <div class='col-auto align-self-center'>
                <mat-checkbox [(ngModel)]="isSameAsRegisteredOfficeAddress"
                              (ngModelChange)="onValueChanges(); setAddressForService()">
                    Same as registered office address
                </mat-checkbox>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field>
                    <mat-label>Other Addresses</mat-label>
                    <input matInput placeholder="" [(ngModel)]="currentSavedBusinessRecord.OtherAddresses"
                           (ngModelChange)="onValueChanges()" appAutoSelect />
                </mat-form-field>
            </div>
        </div>
        <!-- Description -->
        <div class='row'>
            <div class='col'>
                <mat-form-field appearance="fill">
                    <mat-label>Description</mat-label>
                    <textarea matInput rows='20' [(ngModel)]="currentSavedBusinessRecord.Description"
                              (ngModelChange)="onValueChanges()"
                              class='qm-bg-gray'></textarea>
                    <ng-container *ngIf="currentSavedBusinessRecord.Name">
                        <button mat-flat-button class='float-right text-blue'
                                (click)="generateCompanySummary(currentSavedBusinessRecord.Name, currentSavedBusinessRecord.Website, currentSavedBusinessRecord); loginService.doGoogleTracking('business-detail', 'generateAiSummary', '');">Generate
                            Description</button>
                    </ng-container>
                </mat-form-field>
            </div>
        </div>
    </li>

    <!-- error message -->
    <li class='list-group-item' *ngIf="errorMessage">
        <div class="alert alert-danger mb-0 text-center" role="alert">
            {{ errorMessage }}
        </div>
    </li>

    <li class='list-group-item'>
        <button mat-raised-button color="qm-blue" class='px-2 px-sm-3 min-w-auto float-right'
                (click)='save(1)'>
            <span class="d-none d-sm-inline-block">Next</span>
            <span class="material-icons" aria-hidden="false" aria-label="chevron right icon">chevron_right</span>
        </button>
    </li>
</ul>